import React from 'react';
import classNames from 'classnames';
import aqLogo from "assets/img/aq-logo.svg";
import { MENUS, PAGE_NAV } from 'configs/page';
import useLeftMenu from 'hooks/useLeftMenu';
// import useLogout from 'hooks/useLogout';

const LeftNav = (props) => {
    const { on, history } = props;
    const [leftMenu, setLeftMenu] = useLeftMenu();
    // const logoutProvider = useLogout();
    
    const onMenuClicked = (e, menu)=>{
        e.preventDefault();

        // if(menu.value === "LOGOUT"){
        //     logoutProvider().then(()=>{history.push(PAGE_NAV.LOGIN)});
        //     return;
        // }
        
        setLeftMenu(menu);
        history.push(PAGE_NAV[menu.value]);

        
    }
    return (
        <div className={classNames("nav", {"on": on})}>
            <p className="nav-logo"><a href="/" title="affiliate-quarter.frootdigital.com" ><img src={aqLogo} alt="Affiliate quarter" /></a></p>
            <ul>{
                    MENUS.filter(menu=>menu.positions.includes("main")).map((menu, i)=><li key={`menu_${i}`} className={classNames({"on": menu===leftMenu})}><a href={PAGE_NAV[menu.value]} onClick={(e)=>{onMenuClicked(e, menu)}} title={menu.text}><img alt={menu.text} src={leftMenu===menu ? menu.selectedIcon : menu.icon} title={menu.text} /> <span>{menu.text}</span></a></li>)
                }
                {/* <li className={classNames({"on": page==="dashboard"})}><a href="/" title="Dashboard"><img alt={"Dashboard"} src={page==="dashboard" ? DashboardWhiteIco : DashboardDarkIco} title="Dashboard" /> <span>Dashboard</span></a></li>
                <li className={classNames({"on": page==="campaign"})}><a href="/" title="Campaigns"><img alt={"Campaigns"} src={page==="campaign" ? CampaignWhiteIco : CampaignDarkIco} title="Campaigns" /> <span>Campaigns</span></a></li>
                <li className={classNames({"on": page==="stats"})}><a href="/" title="Stats"><img alt={"Stats"} src={page==="stats" ? StatsWhiteIco : StatsDarkIco} title="Stats" /> <span>Stats</span></a></li>
                <li className={classNames({"on": page==="resources"})}><a href="/" title="Resources"><img alt={"Resources"} src={page==="resources" ? ResourcesWhiteIco : ResourcesDarkIco} title="Resources" /> <span>Resources</span></a></li>
                <li className={classNames({"on": page==="support"})}><a href="/" title="Support"><img alt={"Support"} src={page==="support" ? SupportWhiteIco : SupportDarktIco} title="Support" /> <span>Support</span></a></li>
                <li className={classNames({"on": page==="logout"})}><a href="/" title="Log out"><img alt={"Log out"} src={LogoutDarkIco} title="Log out" /> <span>Log out</span></a></li> */}
            </ul>
        </div>
    );
}

export default LeftNav;
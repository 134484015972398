import {atom} from "recoil";
import keys from "configs/atoms";

const initStateList = {
   List : [],
   Total: 0,
   NotCheckedCount: 0
}

const ticketListAtom = atom(({
    key: keys.Support.TicketList,
    default: initStateList
}));

export default ticketListAtom;
import authAtom from "recoil/account/auth";
import { useRecoilValue } from "recoil";

const useAuth =()=>{
    const authState = useRecoilValue(authAtom); 
    const {isAuthenticated, accountLoaded, redirToLogout} = authState;
    return {
        isAuthenticated,
        isLoggedIn: isAuthenticated,
        accountLoaded,
        redirToLogout
    };
}

export default useAuth;
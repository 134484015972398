import React, { useState, useEffect } from 'react';
import classNames from "classnames";
import LoginHeader from "components/LoginHeader";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import valUtils from "utils/validations";
import * as Yup from 'yup';
import service from "services/account";
import { PAGE_NAV } from "configs/page";
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import useToast from 'hooks/useToast';

const ResetPassword = (props) => {
    const accService = service();
    const toast = useToast();
    const { match, history } = props;
    const { token } = match.params;
    const [showExpired, setShowExpired] = useState(false);
    const [showForm, setShowForm] = useState(false);

    const validationSchema = Yup.object().shape({
        password: valUtils.password(),
        confirmPassword: valUtils.confirmPassword("password"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = async (frmData) => {
        if (isSubmitting) return
        setIsSubmitting(true);

        accService.submitPasswordReset({ Guid: token, NewPassword: frmData.password })
            .then(() => {
                //show success?? redir to login??
                setIsSubmitting(false);
                history.push(`${PAGE_NAV.LOGIN}`);
            })
            .catch((err) => {
                //show error;
                setIsSubmitting(false);
                toast.error("Failed resetting password", err);
                throw err;
            })
    }

    const displayForm = () => {
        setShowForm(true);
        setShowExpired(false);
    }
    const displayExpiredTokenMessage = () => {
        setShowForm(false);
        setShowExpired(true);
    }

    useEffect(() => {
        if (!token) {
            displayExpiredTokenMessage();
            return;
        }
        accService.validatePasswordResetToken({ Guid: token })
            .then((resp) => {
                const { Result } = resp.data;
                if (Result) displayForm();
                else displayExpiredTokenMessage();
            })
            .catch(() => {
                displayExpiredTokenMessage();
            });

    }, [token]);


    return (
        <>
            <div className="section login forgot-password">
                <LoginHeader />
                <div className="content">
                    {
                        showExpired &&
                        <div>
                            <p>Ooops.....For your security, a password must be reset within 30 minutes of making a password reset request. Please request another password reset.</p>
                        </div>
                    }
                    {
                        showForm &&
                        <>
                            <h1>Reset Password</h1>
                            <form className="form" onSubmit={handleSubmit(onSubmitHandler)}>
                                <fieldset className="input">
                                    <p className={classNames("create-pass", { error: errors?.password?.message })}>
                                        <input id="create-pass" type="password" placeholder="New password"  {...register("password")} />
                                        <span className="error-msg">{errors?.password?.message}</span>
                                    </p>
                                    <p className={classNames("confirm-pass", { error: errors?.confirmPassword?.message })}>
                                        <input id="confirm-pass" type="password" placeholder="Confirm password"  {...register("confirmPassword")} />
                                        <span className="error-msg">{errors?.confirmPassword?.message}</span>
                                    </p>
                                </fieldset>
                                
                                <fieldset className="submit">
                                    <p className="btn green link">
                                        <ButtonWithSpinner text="Next" isLoading={isSubmitting} />
                                    </p>
                                </fieldset>

                            </form>
                        </>
                    }
                </div>
                <div className="footer">
                    <div className="content"><p>Powered by Digital Quarter</p></div>
                </div>
            </div>
        </>
    )
}

export default ResetPassword;
import useFetchProvider from 'hooks/useFetchProvider';

const  StatsService = ()=> {
    const fetch = useFetchProvider();
    const getCampaigns = async() => await fetch.get(`/Statistics/Campaign/`);
    const getCampaign = async(DateRangeItemID)=> await fetch.post(`/Statistics/Campaign/`, {DateRangeItemID});
    const getTotalEarnings = async()=> await fetch.get(`/Statistics/Earnings/Total`);
    const getEarnings = async(data)=> await fetch.get(`/Statistics/Earnings`, {params: data});
    const getCampaignDetails = async(data) => await fetch.get(`/Statistics/Campaign/${data.CampaignID}`, {params: data});
    return {
        getCampaigns, getCampaign, getEarnings, getCampaignDetails, getTotalEarnings
    };
}

export default StatsService;
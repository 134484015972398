import React from 'react';
import { Modal } from 'react-responsive-modal';

import TicketMessage from 'components/TicketReplyForm';

const TicketMessagePopup = (props) => {
    const {open, close } = props;

    return (
        <Modal classNames={{ overlay: 'ticket-message-overlay', modal: 'ticket-message-modal',}} 
            open={open} 
            onClose={close}
            showCloseIcon={false}
            center>
            <TicketMessage isOpenInPopup={true} {...props} />
        </Modal>
    );
}

export default TicketMessagePopup;
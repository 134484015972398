import * as Yup from 'yup';
import isValidABN from "is-valid-abn";

const validationSchema = Yup.object().shape({
    billingName: Yup.string().trim().required("Required"),
    address: Yup.string().trim().required("Required"),
    address2: Yup.string().notRequired().nullable(),
    city: Yup.string().trim().required("Required"),
    stateID: Yup.number()
                .test("isStateValid", "Please select state", function(val){
                    return val && parseInt(val) > 0;
                }),
    countryID: Yup.number()
                .test("isCountryValid", "Please select country", function(val){
                    return val && parseInt(val) > 0;
                }),
    abn: Yup.string()
        .test("abnRequired", "Required", function(val){
            const {countryID} = this.parent;
            if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) > 1) return true;
            return val.trim();
        })
        .test("isValidABN", "Invalid ABN", function(val){
            const {countryID} = this.parent;
            if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) > 1 || !val) return true;
            return isValidABN(val.trim());
        }),
    gst: Yup.bool(),
});

export default validationSchema;

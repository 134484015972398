const Keys = {
    Lookup :  {
        Country: "countryLookupSelector",
        StatDates: "statDatesLookupSelector"
    },
    Statistic : {
        Campaigns: "statisticCampaignsSelector",
        EarningsSummary: "statisticEarningsSummarySelector",
    }
    
}

export default Keys;
import React from 'react';
import classNames from 'classnames';
import handIco from "assets/img/hand-ico-white.svg";
import usrIco from "assets/img/user-ico-white.svg";
import numberIco from "assets/img/number-ico-white.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown  } from "@fortawesome/free-solid-svg-icons";
import { numberFormatter as formatter } from 'utils/formatter';

const Stats = (props) => {
    const {
            statDates, 
            selectedDate,
            dateSelDropdownOn,
            setDateSelDropdownOn,
            updateSelDate, 

            campaignNames,
            selectedCampaign,
            campaignSelDropdownOn,
            setCampaignSelDropdownOn,
            updateSelCampaign,

            traffic, 
            joins, 
            upgrades 
        } = props;

    return (
        <>
        <div className={classNames("section", "stats", "card-group")}>
                <div className="section-header">
                    
                     
                    {
                        !campaignNames 
                        ?
                         <h3>Stats</h3>
                        :
                        <div className="campaign-select">
                            <p className="selected-campaign" onClick={(e) => { setCampaignSelDropdownOn(!campaignSelDropdownOn); e.stopPropagation() }}>{selectedCampaign} <FontAwesomeIcon icon={faChevronDown} /></p>
                            <div className={classNames("campaign-pick", { "on": campaignSelDropdownOn })}>
                                <ul>
                                    {
                                        campaignNames.map((campaign, i) => <li key={`cpn_${campaign.CampaignDetail.CampaignID}`}><a href="/" title={campaign.CampaignDetail.Title} onClick={(e) => {updateSelCampaign(campaign, e)}}>{campaign.CampaignDetail.Title}</a></li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    }
                    

                    <div className="date-select">
                        <p className="selected-date" onClick={(e) => { setDateSelDropdownOn(!dateSelDropdownOn); e.stopPropagation() }}>{selectedDate} <FontAwesomeIcon icon={faChevronDown} /></p>
                        <div className={classNames("date-pick", { "on": dateSelDropdownOn })}>
                            <ul>
                                {
                                    statDates.map((date) => <li key={`dt_${date.ItemID}`}><a href="/" title="Today" onClick={(e) => {updateSelDate(date, e)}}>{date.ItemName}</a></li>)
                                }
                            </ul>
                        </div>
                    </div>

                </div>
                <div className={classNames("card", "total-click")}>
                    <div className="card-content">
                        <p className="value">
                            <span className="number">{formatter.format(traffic)}</span><small>Total clicks</small>
                        </p>
                        <p><img src={handIco} alt="Hand icon" /></p>
                    </div>
                </div>
                <div className={classNames("card", "new-user")}>
                    <div className="card-content">
                        <p className="value">
                            <span className="number">{formatter.format(joins)}</span><small>New users</small>
                        </p>
                        <p><img src={usrIco} alt="Hand icon" /></p>
                    </div>
                </div>
                <div className={classNames("card", "sale")}>
                    <div className="card-content">
                        <p className="value">
                            <span className="number">{formatter.format(upgrades)}</span><small>Sales</small>
                        </p>
                        <p><img src={numberIco} alt="Hand icon" /></p>
                    </div>
                </div>
            </div> 
        </>
    )
}

export default Stats;
import React from 'react';
import { Modal } from 'react-responsive-modal';

const ImageFullViewPopup = (props) => {
    const { open, close, imgURL } = props;

    return (
        <Modal classNames={{ overlay: 'img-full-view-overlay', modal: 'img-full-view-modal',}} 
            open={open} 
            onClose={close}
            //showCloseIcon={false}
            center>
               <p className='attachment-image'><img src={imgURL} alt="Attachment full view" /></p> 
        </Modal>
    );
}

export default ImageFullViewPopup;
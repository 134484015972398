import React, { useEffect, useState, useRef} from 'react';
import {useHistory} from "react-router-dom";
// import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faUser  } from "@fortawesome/free-solid-svg-icons";
import cameraIco from "assets/img/camera-ico.svg";
import sendIco from "assets/img/send-ico.svg";
import TicketMessagePopup from 'components/modals/TicketMessagePopup';
import useToast from 'hooks/useToast';
import useSupport from 'hooks/useSupport';
import moment from 'moment';
import {PAGE_NAV} from "configs/page";
import ImageFullView from 'components/modals/ImageFullViewPopup';

const TicketDetail = (props) => {

    const el = useRef(null);

    const toast = useToast();
    const {isOpenInPopup = false, onBackClicked= ()=>{history.goBack()}, ticketRef = '', onClose} = props;
    const supportProvider = useSupport();
    const {ticketDetailLoadable, fetchTicketDetail, ticketList} = supportProvider;
    const { state: ticketDetailState, contents: ticketDetailContent } = ticketDetailLoadable;
    const [ticketDetail, setTicketDetail] = useState(
        {TicketStarter : null,
        Responses: [],
        Attachments: []});
    
    const {TicketStarter,
        Responses,
        Attachments} = ticketDetail;
    
    let dtloop = TicketStarter && TicketStarter.Date ? moment(TicketStarter.Date).format("DDMMYYY") : "";
    
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        switch (ticketDetailState) {
            case "hasValue":
                if(ticketDetailContent && ticketDetailContent.TicketStarter &&  ticketDetailContent.TicketStarter.TicketRef === ticketRef){
                    setTicketDetail(ticketDetailContent);
                }
                else if(!isFetching){
                    setIsFetching(true);
                    fetchTicketDetail(ticketRef, ticketList)
                    .then(()=>{
                        setIsFetching(false);
                    },()=>{
                        if(isOpenInPopup) {
                    
                        } else {
                            history.replace(PAGE_NAV.TICKETLIST);
                        }
                    });
                    //.finally(()=>{setIsFetching(false)});
                }
                    
                break;
            case "hasError":
                if(isOpenInPopup) {
                    
                } else {
                    history.replace(PAGE_NAV.TICKETLIST);
                }
                break;
            default:
                break;
        }
    }, [ticketDetailState, ticketDetailContent, ticketRef, setTicketDetail]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const history = useHistory();

    const [showTicketMessagePopup, setShowTicketMessagePopup] = useState(false);

    const [showImageFullViewPopup, setImageFullViewPopup] = useState(false);
    const [attachment, setAttachment] = useState("");

    const handleMsgInputClick = () => {
        
        if(isOpenInPopup) {
            setShowTicketMessagePopup(true)
        } else {
            history.push(`${PAGE_NAV.TICKETLIST}/${ticketRef}/reply`);
        }
    }

    const postCreateNewReplyPopup = ()=>{
        setShowTicketMessagePopup(false);
        toast.success("Succesfully create reply");
    }
    const handleCloseBtn = (event) => {
        event.preventDefault();
        if(!isOpenInPopup) {
            onBackClicked();
        }else {
            onClose();
        }
    }

    useEffect(() => {
        el.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    });

    return (
        <div className="ticket-detail">
            <div className="header">
                {/* {showBackBtn && <p className="back-btn"><a href="/" onClick={(event) =>{event.preventDefault();onBackClicked();}} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>} */}
                <p className="back-btn"><a href="/" onClick={handleCloseBtn} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>
                <h1 className="page-header">
                    <span className="title">Ticket detail</span>
                    <span className="ticket-id">Ticket ID: #{ticketRef}</span>
                </h1>
            </div>
            <div className="ticket-msg-container">
                <div className="ticket-msg-list" >
                    {
                        TicketStarter && TicketStarter.TicketRef 
                        && <>
                        <p className="msg-date">{moment(TicketStarter.Date).format("MMM DD, YYYY")}</p>
                            <div className="msg my-msg">
                                <div className="msg-content">
                                    <div className="time">{moment(TicketStarter.Date).format("hh:mm a")}</div>
                                    <div className="text">
                                        <p>{TicketStarter.Message}</p>
                                    </div>
                                    {
                                        Attachments && Attachments.length > 0 &&
                                        Attachments.map((att, i)=>(
                                            <div key={`att_ts_${i}`} className="attachment" onClick={() => {setAttachment(att);setImageFullViewPopup(true);}}>
                                                <img className="image" src={att} alt="message" />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </>
                    }
                    
                    {
                        Responses && Responses.length > 0
                        && [...Responses]
                            .sort((a, b) => a.DateCreated > b.DateCreated ? 1 : -1)
                            .map((resp,j)=>{
                            const dt = moment(resp.DateCreated).format("DDMMYYY");
                            const isDisplayDate = dt !== dtloop;
                            if(isDisplayDate){
                                dtloop = dt;
                            }
                            const adminReply = resp.AdminID > 0;
                            
                            return (
                                <div key={`div_${j}`}>
                                {
                                   isDisplayDate && <p className="msg-date" key={`resp_p_${j}`}>{moment(resp.DateCreated).format("MMM DD, YYYY")}</p> 
                                }
                                <div className={adminReply ? "msg" : "msg my-msg"}  key={`resp_${j}`}>
                                    {
                                        adminReply && 
                                        <div className="user-avatar">
                                            <FontAwesomeIcon icon={faUser} />
                                        </div>
                                    }
                                    
                                    <div className="msg-content">
                                        <div className="time">{moment(resp.DateCreated).format("hh:mm a")}</div>
                                        <div className="text">
                                            <p>{resp.Message}</p>
                                        </div>
                                        {
                                            resp.Attachments && resp.Attachments.length > 0 &&
                                            resp.Attachments.map((att, i)=>(
                                                <div key={`att_r_${j}_${i}`} className="attachment" onClick={() => {setAttachment(att);setImageFullViewPopup(true);}}>
                                                    <img className="image" src={att} alt="message" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                </div>
                            );
                        })
                    }
                    {/* <p className="msg-date">May 11, 2016</p>
                    <div className="msg my-msg">
                        <div className="msg-content">
                            <div className="time">10:11 am</div>
                            <div className="text">
                                <p>dxfhgxdfg</p>
                            </div>
                        </div>
                    </div>
                    <div className="msg">
                        <div className="user-avatar">
                            <img src="https://digitalquarter.com/wp-content/uploads/2017/04/ProfileMark.jpg" alt="name of the member" />
                            <div className="online-status"></div>
                        </div>
                        <div className="msg-content">
                            <div className="time">10:11 am</div>
                            <div className="text">
                                <p>dxfhgxdfg</p>
                            </div>
                            <div className="attachment">
                                <img className="image" src="https://img.redhotpie.com.au/SupportTicket/att_637031136396667970.jpg" alt="message" />
                            </div>
                        </div>
                    </div> */}
                    
                </div>
                <div className="scroll-anchor" style={{ float:"left", clear: "both" }} ref={el}></div>
            </div>

            <div className="msg-input" onClick={handleMsgInputClick}>
                <img className="photo-ico" src={cameraIco} alt="camera icon"/>
                <input className="input-toolbar" placeholder="Type a message ..." readOnly={true} />
                <img className="send-ico" src={sendIco} alt="send icon" />
            </div>
            <TicketMessagePopup 
                open={showTicketMessagePopup} 
                close={()=>setShowTicketMessagePopup(false)} 
                ticketRef={ticketRef}
                postProcFunc={postCreateNewReplyPopup}
                />

            <ImageFullView open={showImageFullViewPopup} close={()=>setImageFullViewPopup(false)} imgURL={attachment} />
        </div>
    );
}

export default TicketDetail;
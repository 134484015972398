import axios from 'axios';
import  {API_HOST} from "configs/api";
import auth from 'utils/auth';

const publicFetch = axios.create({
    baseURL: API_HOST
  });

const customFetch = axios.create({
    baseURL: API_HOST
});

customFetch.defaults.headers.common["Authorization"] = `${auth.getCustomToken()}`;
  
  export { publicFetch, customFetch };
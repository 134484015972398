
import useFetchProvider from 'hooks/useFetchProvider';

const CampaignService = ()=>{
    const fetch = useFetchProvider();
    const createCampaign = async(data)=> await fetch.post(`/Campaign/`, data);
    const hideCampaign = async(id) => await fetch.patch(`/Campaign/${id}/Hide`);
    return {createCampaign, hideCampaign}
}

export default CampaignService;
import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'classnames';
//configs
import { MENUS } from 'configs/page';
//atoms
import { useRecoilValue } from 'recoil';
import dateJoinedAtom from "recoil/account/dateJoined";
//utils
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import {generateEarningsYearOptions} from "utils/optionsProvider";
import { currencyFormatter, numberFormatter } from 'utils/formatter';
//hooks
import useIsMountedRef from 'hooks/useIsMountedRef';
import useLeftMenu from 'hooks/useLeftMenu';
//services
import useStatService from "services/stats";
//layouts, views
import { BrowserView, MobileView } from 'react-device-detect';
import LoggedInLayout from 'layouts/LoggedInLayout';
//fonts
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown  } from "@fortawesome/free-solid-svg-icons";



const TotalEarning = (props) => {
  const isMountedRef = useIsMountedRef();
  const [leftMenu, setLeftMenu] = useLeftMenu();
  const services = useStatService();
  
    const curYear = moment().year();
    const yearOptions = generateEarningsYearOptions(useRecoilValue(dateJoinedAtom));


    const [earnings, setEarnings] = useState({});
    const [selectedYear, setSelectedYear] = useState(curYear);
    const [dateSelDropdownOn, setDateSelDropdownOn] = useState(false);
    const updateSelYear = (year, e) => {
        e.preventDefault();
        setSelectedYear(year);
        setDateSelDropdownOn(false);
    }

    const getEarnings = useCallback(async () => {
      try {
        if (isMountedRef.current 
          && (!earnings || !earnings[selectedYear])) {
            services.getEarnings({Year: selectedYear})
            .then((response)=>{
               const {data}=response;
               const newEarnings = cloneDeep(earnings);
               newEarnings[selectedYear]=data;
               setEarnings(newEarnings);
            });
        }
      } catch (error) {
        console.error(error);
      }
    }, [isMountedRef, selectedYear, earnings, services]);

    useEffect(() => {
      if(!earnings[selectedYear]) getEarnings();
    }, [getEarnings, earnings, selectedYear]);

    useEffect(() => {
      if (leftMenu.value !== "DASHBOARD" &&  isMountedRef.current )
          setLeftMenu(MENUS.find(menu => menu.value === "DASHBOARD"));

  }, [leftMenu, setLeftMenu, isMountedRef]);

    return (
    <LoggedInLayout {...props} pageTitle="Dashboard">
        <div className={classNames("section", "my-compaign", "card-group")} 
            onClick={()=>{setDateSelDropdownOn(false);}}>
                <div className="section-header">
                    <h3>Total Earnings</h3>

                    <div className="date-select">
                        <p className="selected-date" onClick={(e) => { setDateSelDropdownOn(!dateSelDropdownOn); e.stopPropagation() }}>{selectedYear} <FontAwesomeIcon icon={faChevronDown} /></p>
                        <div className={classNames("date-pick", { "on": dateSelDropdownOn })}>
                            <ul>
                                {
                                    yearOptions.map((year, i) => <li key={`dt_${i}`}><a href="/" title="Today" onClick={(e) => {updateSelYear(year, e)}}>{year}</a></li>)
                                }
                            </ul>
                        </div>
                    </div>
                </div>
                
                <BrowserView>
                    <table className="compaign-table total-earning">
                        <thead className={classNames("thead")}>
                            <tr>

                                <th></th>
                                <th>Click</th>
                                <th>Joins</th>
                                <th>Sales</th>
                                <th>Earnings</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                earnings && earnings[selectedYear]
                                && 
                                <>
                                  {
                                      earnings[selectedYear].length > 0
                                      ?  [...earnings[selectedYear]]
                                      .sort((a, b) => a.Date > b.Date ? 1 : -1)
                                      .map((campaign, i) => (
                                          <tr key={`campaign_${selectedYear}_browser_${i}`}>
                                              <td>{moment(campaign.Date).format("MMM YYYY")}</td>
                                              <td>{numberFormatter.format(campaign.Traffic)}</td>
                                              <td>{numberFormatter.format(campaign.Joins)} {campaign.Traffic > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Joins * 100 / campaign.Traffic)}%)` : ``}</td>
                                              <td>{numberFormatter.format(campaign.Upgrades)} {campaign.Upgrades > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Upgrades * 100 / campaign.Joins)}%)` : ``}</td>
                                              <td>{currencyFormatter.format(campaign.Revenue)}</td>
                                          </tr>
                                      ))
                                      : <tr><td colSpan={5}>No results found</td></tr>
                                  }
                                </>
                            }
                        </tbody>
                    </table>
                </BrowserView>

                <MobileView>
                    {
                          earnings && earnings[selectedYear] &&
                          <>
                          {
                            earnings[selectedYear].length > 0
                            ? [...earnings[selectedYear]]
                            .sort((a, b) => a.Date > b.Date ? 1 : -1)
                            .map((campaign, i) => (
                                <div className="table" key={`campaign_${selectedYear}_mobile_${i}`}>
                                        <div className="row-header">{moment(campaign.Date).format("MMM YYYY")}</div>
                                        <div className="th-td">
                                            <div>
                                                <div className="th">Click</div>
                                                <div className="th">Join</div>
                                                <div className="th">Sales</div>
                                                <div className="th">Earnings</div>
                                            </div>
                                            <div>
                                                <div className="td">{numberFormatter.format(campaign.Traffic)}</div>
                                                <div className="td">{numberFormatter.format(campaign.Joins)} {campaign.Traffic > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Joins * 100 / campaign.Traffic)}%)` : ``}</div>
                                                <div className="td">{numberFormatter.format(campaign.Upgrades)} {campaign.Upgrades > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Upgrades * 100 / campaign.Joins)}%)` : ``}</div>
                                                <div className="td">{currencyFormatter.format(campaign.Revenue)}</div>
                                            </div>
                                        </div>
                                </div>
                          ))

                            : <>No results found</>
                          }
                          </>
                    }
                </MobileView>

        </div>
    </LoggedInLayout>
    );
}

export default TotalEarning;
import React from 'react';
import { Modal } from 'react-responsive-modal';

import TicketDetail from 'components/TicketDetail';

const TicketDetailPopup = (props) => {
    const {open, close, ticketRef } = props;
   
    return (
        <Modal classNames={{ overlay: 'ticket-detail-overlay', modal: 'ticket-detail-modal',}} 
            open={open} 
            onClose={close}
            showCloseIcon={false}
            center>
            <TicketDetail
                isOpenInPopup = {true} 
                ticketRef={ticketRef}
                onClose={close}
                />
        </Modal>
    );
}

export default TicketDetailPopup;
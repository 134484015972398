import ClipLoader from "react-spinners/ClipLoader";

const ButtonWithSpinner = (props)=>{
    const {text, isLoading, ...rest} = props;
    return (
        <button type="submit" {...rest} disabled={isLoading}>
            <ClipLoader 
                color="#ffffff" loading={isLoading} 
                css={"display:inline-block;width:18px;height:18px;margin-right:4px;"} 
                size={150} /> {text}
        </button> 
    )
}

export default ButtonWithSpinner;
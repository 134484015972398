import {atom} from "recoil";
import keys from "configs/atoms";

const initStateCriteria = {
    Size: 2000,
    Page: 0,
    CategoryID: -1,
    IsLoaded: false
};

const ticketListCriteriaAtom = atom(({
    key: keys.Support.TicketListSearchCriteria,
    default: initStateCriteria
}));

export default ticketListCriteriaAtom;
import React, { useEffect, createRef, useCallback } from 'react';
import { Modal } from 'react-responsive-modal';
import { allCountries } from 'country-telephone-data';

const PhoneCountryCodePopup = (props) => {
    const { open, close, setCountryCode, defaultValue } = props;

    const refs = allCountries.reduce((acc, value) => {
        acc[value.iso2] = createRef();
        return acc;
    }, {});

    const handleScrollTo = useCallback(id =>{
        if(refs[id] && refs[id].current){
            refs[id].current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
        
    }, [refs]);

    useEffect(()=>{
        let selCountry;
        if(defaultValue)
            selCountry = allCountries.find(itm=>itm===defaultValue);
        
        if(!selCountry)
            setTimeout(()=>handleScrollTo("au"), 100);
        else
            setTimeout(()=>handleScrollTo(selCountry.iso2), 100);
    },[defaultValue, handleScrollTo]);

    return (
        <Modal classNames={{ overlay: 'country-code-overlay', modal: 'country-code-modal', }} open={open} onClose={close} center>
            <h2>Country code</h2>
            <div className="country-code">
                <ul>
                    {
                        allCountries.map((item) => (
                            <li key={item.iso2} ref={refs[item.iso2]} onClick={() => { setCountryCode(item); close() }}><span className="name">{item.name}</span> <span className="code">+{item.dialCode}</span></li>
                        ))
                    }
                </ul>
            </div>
        </Modal>
    );
}

export default PhoneCountryCodePopup;
import React, { useState} from 'react';
import classNames from 'classnames';
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faCheckCircle, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import plusGreenCircle from 'assets/img/plus-green-circle-ico.svg';
import crossRedCircle from "assets/img/cross-red-circle-ico.svg";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';

import ButtonWithSpinner from './buttons/ButtonSpinner';

import imageCompression from "browser-image-compression";

//import useAccount from "hooks/useAccount";
import useToast from "hooks/useToast";
import useSupport from 'hooks/useSupport';

import ClipLoader from "react-spinners/ClipLoader";

const ContactSupportLogout = (props) => {
    const history = useHistory();
    const toast = useToast();
    //const accountProvider = useAccount();
    const supportProvider = useSupport();
    //const { profiles } = accountProvider;
    //const { Name, Email } = profiles;

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSuccessSubmitted, setIsSuccessSubmitted] = useState(false);
    
    const validationSchema = Yup.object().shape({
        name: Yup.string().trim().required("Required"),
        email: Yup.string().trim().required("Required"),
        subject: Yup.string().trim().required("Required"),
        description: Yup.string().trim().required("Required"),
    });
    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        defaultValues: {
            name: "",
            email: "",
           subject: "",
           description: ""
        },
        resolver: yupResolver(validationSchema)
    });

    const {isOpenInPopup = false, onBackClicked = ()=>{history.goBack()}, postProcFunc = ()=>{}, onClose} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    // const history = useHistory();

    const [isUploading, setIsUploading] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const handleUpload = async(event) => {
        if(isUploading) return;
        setIsUploading(true);
        const file = event.target.files[0];
        
        try {
            const compressedFile = await imageCompression(file, {maxSizeMB: 4, maxWidthOrHeight: 1920});
            //note compressedFile is a blob, convert it to file for uploading
            const data = new FormData();
            data.append("file", new File([compressedFile], file.name));
            
            await supportProvider.uploadAttachment(data)
            .then((response)=>{
                setAttachments(prev=>([...response.data,...prev]));
                setIsUploading(false);
            }, (err)=>{toast.error("Failed to upload image", err);setIsUploading(false);});
            ; // write your own logic
          } catch (error) {
                setIsUploading(false);
                toast.error(error);
          }

        // if(file) {
        //     const fileURL = URL.createObjectURL(file);
        //     let fileType = null;
        //     if(file.type.match('image')) {
        //         fileType = "image";
        //     }else if (file.type.match('video')) {
        //         fileType = "video";
        //     }
        
        //     setUploadFiles([...uploadFiles, {url:fileURL, type:fileType}]);
        // }
    }

    const handleRemoveAttachment = (id)=>{
        const atts = attachments.filter(a=>a.FileID !== id);
        setAttachments(atts);
    }

    const onSubmitHandler = async (frmData, e) => {
        e.preventDefault();
        
        if(isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            EmailAddress : frmData.email,
            SuppliedUsername : frmData.name,
            Subject : frmData.subject,
            Enquiry : frmData.description,
            CategoryID : 15,
            SiteCountryID: 1,
            UserAgent : '',
            IPAddress: '',
            Attachments: attachments.map(att=>att.FileID)
        };

        await supportProvider.addNewTicket(submittedData)
                .then(()=>{
                        setIsSubmitting(false);
                        postProcFunc();
                        setIsSuccessSubmitted(true);
                        setIsSubmitted(true);
                    }, (err)=>{
                        setIsSubmitting(false);
                        //toast.error("Failed to add a new ticket", err);
                        setIsSuccessSubmitted(false);
                        setIsSubmitted(true);
                    });
    }

    const handleCloseBtn = (event) => {
        event.preventDefault();
        if(!isOpenInPopup) {
            onBackClicked();
        }else {
            onClose();
        }
    }

    
    return (
        <div className="contact-support">

            {
                !isSubmitted 
                ?
                <form className="contact-form" onSubmit={handleSubmit(onSubmitHandler)}>
                
                    <div className="header">
                        <p className="back-btn"><a href="/" onClick={handleCloseBtn} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>
                        <h1 className="page-header">Contact support</h1>
                        <p className="btn green link"> <ButtonWithSpinner text={"Send"} isLoading={isSubmitting} /></p>
                    </div>

                    <fieldset className="input">
                        <p className={classNames("name", { error: errors?.name?.message })} >
                            <label htmlFor="name">Name</label>
                            <input id="name" maxLength="200" type="text" placeholder="Enter your name" {...register("name")} />
                            {errors?.name?.message && <span className="error-msg">{errors?.name?.message}</span>}
                        </p>

                        <p className={classNames("email", { error: errors?.email?.message })} >
                            <label htmlFor="email">Email</label>
                            <input id="email" maxLength="200" type="text" placeholder="Enter your email" {...register("email")} />
                            {errors?.email?.message && <span className="error-msg">{errors?.email?.message}</span>}
                        </p>

                        <p className={classNames("subject", { error: errors?.subject?.message })} >
                            <label htmlFor="subject">Subject</label>
                            <input id="subject" maxLength="200" type="text" placeholder="Type your subject" {...register("subject")} />
                            {errors?.subject?.message && <span className="error-msg">{errors?.subject?.message}</span>}
                        </p>
                        
                        <p className={classNames("description", { error: errors?.description?.message })}>
                            <label htmlFor="description">Description</label>
                            <textarea id="description" placeholder="How can we help?" {...register("description")}></textarea>
                            {errors?.description?.message && <span className="error-msg">{errors?.description?.message}</span>}
                        
                        </p>
                        <div className={classNames("file-uploader")}>
                            <input type="file" id="image-attach" name="image-attach" onChange={handleUpload} onClick={(event)=> {event.target.value = null}} />
                            {
                                attachments.length > 0 
                                ?

                                <div className="preview">
                                    {attachments.map((file, i)=>(
                                        
                                        <p key={i} className="thumbnail" style={{
                                            backgroundImage:"url(" + file.AttachmentUrl+ ")", 
                                            }}>
                                            <img src={file.AttachmentUrl} alt={`attachment ${i+1}`} />
                                            <span className="ico" onClick={()=>handleRemoveAttachment(file.FileID)}><img src={crossRedCircle} alt="X" /></span>
                                        </p>
                                    ))}
                                    <label className="thumbnail" htmlFor="image-attach">
                                        <span>Attach image</span>
                                        <span className="ico"><img src={plusGreenCircle} alt="+" /></span>
                                    </label>
                                </div>
                                : <label htmlFor="image-attach">Attach image <span className="upload-btn"><ClipLoader 
                                color="#ffffff" loading={isUploading} 
                                css={"display:inline-block;width:18px;height:18px;margin-right:4px;"} 
                                size={150} /> Upload <span className="ico"><img src={plusGreenCircle} alt="+" /></span></span></label>
                            }
                            
                        </div> 
                        
                    </fieldset>
                </form>
                :
                <div className="confirm-msg">
                    <div className="header">
                        <p className="back-btn"><a href="/" onClick={handleCloseBtn} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>
                        <h1 className="page-header">Contact support</h1>
                    </div>
                    <div className="content">
                        {
                            isSuccessSubmitted
                            ?
                            <div className="success">
                                <FontAwesomeIcon icon={faCheckCircle} className="success-ico ico" />
                                <p className="success-msg">Ticket has been create successfully!</p>
                            </div>
                            :
                            <div className="fail">
                            <FontAwesomeIcon icon={faExclamationTriangle} className="fail-ico ico" />
                            <p className="fail-msg">Failed to add a new ticket!</p>
                           </div>
                        }
                    
                        
                        
                    </div>
                </div>
            }

        </div>
    );
}

export default ContactSupportLogout;
import useAccount from "./useAccount";
// import useAuth from './useAuth';
import { useEffect, useState,useRef } from 'react';
import {PAGE_NAV} from "configs/page";
import { Redirect } from 'react-router-dom';
import useToast from "./useToast";
import SquareLoader from "react-spinners/SquareLoader";
import useAuthProvider from "./useAuthProvider";

const useFullRegOnly = (content)=>{
    const accountProvider = useAccount();
    const toast = useToast();
    const {accountLoaded} = useAuthProvider();
    const [isFetching, setIsFetching] = useState(false);
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])

    useEffect(()=>{
        if(accountLoaded) return;
        if(isFetching) return;
        if(!mountedRef.current) return;
        setIsFetching(true);
                // console.log("here hrere?")
        accountProvider.getAccount()
            .then(()=>mountedRef.current && setIsFetching(false))
            .catch(err=>toast.error(err));
        return ()=>{}
    },[accountLoaded, accountProvider,isFetching, setIsFetching, toast]);

    
    
    if(!accountLoaded) return (
        <div style={{
            position: "absolute",
            top: "calc(50% - 4em)",
            left: "calc(50% - 4em)",
            // width: "6em",
            // height: "6em",
           
          }}><SquareLoader loading={true} color={"#000"} size={100} /></div>
    );
    
    const {paymentInfo,
        bankDetails,
        profiles,
        // phoneDetail,
        billingInfo
    } = accountProvider;
  
        const {Name, Email} = profiles;
        // const {PhoneNumber, PhoneVerified} = phoneDetail
        const {BillingName} = billingInfo;

        if(!Name || !Email){//} || !PhoneNumber){
            return  <Redirect to={PAGE_NAV.JOIN} /> 
        }
        
        // if(!PhoneVerified){
            // if(!BillingName)
            //     return  <Redirect to={PAGE_NAV.JOIN} /> 
        // }

        const {AffiliatePaymentTypeID, Paypal, PaymentID} = paymentInfo;
        const {BankName} = bankDetails;

        if(
            (AffiliatePaymentTypeID === "Paypal" && !Paypal)
            || (AffiliatePaymentTypeID === "PayID" && !PaymentID)
            || (AffiliatePaymentTypeID === "DirectDeposit" && !BankName)
        ){
                return  <Redirect to={PAGE_NAV.JOIN} />
        }
        
        
        if(!BillingName){
            return  <Redirect to={PAGE_NAV.JOIN} />
        }
            
        return content;
}

export default useFullRegOnly;
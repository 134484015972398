import ApiRequest from './base';
import {API_HOST} from "../configs/api";


class LookupService {
    static async getLookup(name){
        return await ApiRequest({
                url: `${API_HOST}/Lookup/${name}`,
                method: "GET",
                data : {}
        });
    };
    static async getLookupAvailability(name, value){
        return await ApiRequest({
            url: `${API_HOST}/LookupAvailability/${name}`,
            method: "GET",
            data : {value}
        });
    };
    static async getLookupCountry(){
        return await ApiRequest({
            url: `${API_HOST}/Location/Country/`,
            method: "GET",
            data : {}
        });
    }
    static async getLookupState(countryID){
        return await ApiRequest({
            url: `${API_HOST}/Location/Country/${countryID}`,
            method: "GET",
            data : {}
        });
    }
    static async getLookupLandingPages(){
        return await ApiRequest({
            url: `${API_HOST}/LandingPages`,
            method: "GET",
            data : {}
        });
    }
}

export default LookupService;
import React, { useEffect, useState} from 'react';
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS } from 'configs/page';
import CampaignList from 'components/CampaignList';
import { useRef } from 'react';
import useCampaigns from 'hooks/useCampaigns';
import EmptyStateGraphic from 'assets/img/dashboard-empty.svg';
import { Link } from 'react-router-dom';
import { PAGE_NAV } from 'configs/page';

const Campaigns = (props) => {
    const [leftMenu, setLeftMenu] = useLeftMenu();
    const mountedRef = useRef(true);

    const campaignProvider = useCampaigns();
    const {campaignsSummaryLoadable } = campaignProvider;
    const { state: campaignListState, contents: campaignListContent } = campaignsSummaryLoadable;
    const [campaignList, setCampaignList] = useState(null);
    useEffect(() => {
        switch (campaignListState) {
            case "hasValue":
                if(campaignListContent)
                    setCampaignList(campaignListContent);
                break;
            default:
                break;
        }
    }, [campaignListState, campaignListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])
    
    useEffect(()=>{
        if(leftMenu.value !== "CAMPAIGNS" && mountedRef.current) setLeftMenu(MENUS.find(menu=>menu.value==="CAMPAIGNS"));
    }, [leftMenu, setLeftMenu]);

    return (
        <LoggedInLayout {...props}>
            {
                campaignListState === "hasValue" 
                && campaignList 
                && campaignList.StatsSummaries
                && campaignList.StatsSummaries.length === 0 
                &&
                <div className="empty-state">
                    <p className="empty-ghc"><img src={EmptyStateGraphic} alt="Empty state graphic" /></p>
                    <p className="empty-msg">LET’S GET THE BALL ROLLING</p>
                    <p className="sub">Creating and launching a campaign is pretty easy and we’ll guide you through the process.</p>
                    <p className="green btn link"><Link to={PAGE_NAV.CAMPAIGN_ADD} title="Add campaign">CREATE MY FIRST CAMPAIGN</Link></p>
                </div>
            }
            {
              campaignListState === "hasValue" 
              && campaignList 
              && campaignList.StatsSummaries
              && campaignList.StatsSummaries.length > 0 
              &&
              <CampaignList />
            }

           
        </LoggedInLayout>
    );
}

export default Campaigns;
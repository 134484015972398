
import useService from "services/auth";
import useAuthProvider from "./useAuthProvider";

const useLogout =()=>{
    const service = useService();
    const authProvider = useAuthProvider();
    const logout = async()=>{
        return service.logout()
        .then(()=>authProvider.clearTokens());
    };
    return logout;
}

export default useLogout;
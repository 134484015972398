import React, { useEffect, useState, useRef} from 'react';
import {useHistory} from "react-router-dom";
// import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft  } from "@fortawesome/free-solid-svg-icons";
import TicketDetailPopup from 'components/modals/TicketDetailPopup';
import ContactSupportPopup from "components/modals/ContactSupportPopup";

import useToast from 'hooks/useToast';
import useSupport from 'hooks/useSupport';
import Skeleton from "react-loading-skeleton";
import moment from 'moment';

import {PAGE_NAV} from "configs/page";

const TicketList = (props) => {
    const history = useHistory();
    const toast = useToast();
    const supportProvider = useSupport();
    const {fetchTickets,
        ticketListLoadable,
        ticketListCriteria} = supportProvider;
    
    const {IsLoaded} = ticketListCriteria;
    const { state: ticketListState, contents: ticketListContent } = ticketListLoadable;
    const [ticketListDisplay, setTicketListDisplay] = useState([]);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        switch (ticketListState) {
            case "hasValue":
                if(ticketListContent){
                    setTicketListDisplay(ticketListContent.List);
                }
                    
                break;
            default:
                break;
        }
    }, [ticketListState, ticketListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])

    useEffect(()=>{
        if(IsLoaded) return;
        if(ticketListState !== "hasValue") return;
        if(isFetching) return;

        setIsFetching(true);
        const newCriteria = {
            ...ticketListCriteria,
            Page: ticketListCriteria.Page + 1
        };
        fetchTickets(newCriteria, ticketListContent)
        .then(()=>{
            if(mountedRef.current){
                setIsFetching(false);
            }
        }, (err)=>{
            if(mountedRef.current){
                setIsFetching(false);
                toast.error(err);
            }
        })
    },[IsLoaded, isFetching, fetchTickets, ticketListState, ticketListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    
    const {isOpenInPopup = false, onBackClicked = ()=>{ history.goBack() }, onClose } = props;
    const [showTicketDetailPopup, setShowTicketDetailPopup] = useState(false);
    const [displayRef, setDisplayRef] = useState("");

    const handleTicketListClick = (item) => {
        
        if(isOpenInPopup) {
            setDisplayRef(item.TicketRef);
            setShowTicketDetailPopup(true);
        } else {
            history.push(`${PAGE_NAV.TICKETLIST}/${item.TicketRef}`);
        }
    }

    const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);
    const postCreateNewTicketPopup = ()=>{
        setShowContactSupportPopup(false);
        toast.success("Succesfully create new ticket");
    }

    const handleNewButtonClick = () =>{
        if(isOpenInPopup) {
            setShowContactSupportPopup(true);
        } else {
            history.push(PAGE_NAV.CONTACTSUPPORT);
        }
    }
    const handleCloseBtn = (event) => {
        event.preventDefault();
        if(!isOpenInPopup) {
            onBackClicked();
        }else {
            onClose();
        }
    }

    return (
        <div className="ticket-list">
            <div className="header">
                {/* {!isOpenInPopup && <p className="back-btn"><a href="/" onClick={(event) =>{event.preventDefault();onBackClicked();}} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>}*/}
                <p className="back-btn"><a href="/" onClick={handleCloseBtn} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>
                <h1 className="page-header">Ticket list</h1>
                <p className="btn green">
                    <button type="submit" onClick={(e)=>{e.preventDefault(); handleNewButtonClick()}}>New</button>
                </p>
            </div>

            <ul className="ticket">
                {
                    (!isFetching || ticketListState !== "hasValue" ) && ticketListDisplay.length === 0  && <li><span className="empty">No ticket avaliable</span></li>
                }
                {
                    [...ticketListDisplay]
                    .sort((a, b) => a.Date > b.Date ? -1 : 1)
                    .map((item, i)=>
                         ( 
                            <li key={`ticket_${item.TicketRef}`} onClick={()=>{handleTicketListClick(item)}}>
                                <div className="date">
                                    <span className="day">{moment(item.Date).format("DD")}</span>
                                    <span className="month">{moment(item.Date).format("MMM")}</span>
                                </div>
                                <div className="details">
                                    <span className="title">
                                        {   item.Title ?
                                            item.Title.substring(0,30) :
                                            item.Message.substring(0,30)
                                        }
                                    </span>
                                </div>
                                <div className="status">
                                    {item.NewCount > 0 && <span className="new-msg">{item.NewCount}</span> }
                                    <span className="btn grey">{item.Status}</span>
                                </div>
                            </li> 
                        )
                    )
                }
                {
                    (ticketListState === "loading" || isFetching) && 
                    <>
                        <li>
                            <div  style={{width:"100%"}}>
                                <Skeleton height="40px"/>
                            </div>
                        </li>
                        <li>
                            <div  style={{width:"100%"}}>
                                <Skeleton height="40px"/>
                            </div>
                        </li>
                        <li>
                            <div  style={{width:"100%"}}>
                                <Skeleton height="40px"/>
                            </div>
                        </li>
                    </>
                }
                
                {/* <li onClick={handleTicketListClick}>
                    <div className="date">
                        <span className="day">19</span>
                        <span className="month">Jul</span>
                    </div>
                    <div className="details">
                        <span className="title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    </div>
                    <div className="status">
                        <span className="new-msg">10</span>
                        <span className="btn grey">Open</span>
                    </div>
                </li>
                <li onClick={handleTicketListClick}>
                    <div className="date">
                        <span className="day">18</span>
                        <span className="month">Jul</span>
                    </div>
                    <div className="details">
                        <span className="title">Lorem Ipsum is simply dummy text .</span>
                    </div>
                    <div className="status">
                        <span className="new-msg">10</span>
                        <span className="btn grey">Open</span>
                    </div>
                </li>
                <li onClick={handleTicketListClick}>
                    <div className="date">
                        <span className="day">05</span>
                        <span className="month">Jul</span>
                    </div>
                    <div className="details">
                        <span className="title">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</span>
                    </div>
                    <div className="status">
                        <span className="btn grey">Open</span>
                    </div>
                </li> */}
            </ul>
            {/* <div className="create-new">
                <p className="btn grey">
                <button type="submit" onClick={(e)=>{e.preventDefault(); handleNewButtonClick()}}>New</button>
                </p>
            </div> */}
            {
                showTicketDetailPopup && 
                <TicketDetailPopup 
                    open={showTicketDetailPopup} 
                    close={()=>setShowTicketDetailPopup(false)} 
                    ticketRef={displayRef}
                />
            }
            { 
                showContactSupportPopup &&
                <ContactSupportPopup open={showContactSupportPopup} close={()=>setShowContactSupportPopup(false)} postProcFunc={postCreateNewTicketPopup} />
            }
            
        </div>
    );
}

export default TicketList;
import { useRecoilState, useResetRecoilState } from "recoil";
import {REFRESHTOKEN_KEY, ACCESSTOKEN_KEY} from "configs/api";
import { publicFetch } from "services/fetch";

//account atoms
import authAtom from "recoil/account/auth";
import bankDetailsAtom from "recoil/account/bankDetails";
import billingInfoAtom from "recoil/account/billingInfo";
import paymentInfoAtom from "recoil/account/paymentInfo";
import phoneDetailsAtom from "recoil/account/phoneDetails";
import profilesAtom from "recoil/account/profiles";
import promoteChannelsAtom from "recoil/account/promoteChannels";
import regStepAtom from "recoil/account/regStep";

//nav atoms
import leftMenuAtom from "recoil/nav/leftMenu";

//stats atoms:
import campaignDateRangeIDAtom from "recoil/stats/campaignDateRangeID";
import campaignDetailsAtom from "recoil/stats/campaignDetail";
import campaignDetailsIDAtom from "recoil/stats/campaignDetailID";
import campaignsAtom from "recoil/stats/campaigns";
import earningSummaryAtom from "recoil/stats/earningSummary";

//lookup atoms:
import lookupLandingPagesAtom from "recoil/lookup/landingPages";

const useAuthProvider = () => {
    const [authState, setAuthState] = useRecoilState(authAtom);
    const resetAuth = useResetRecoilState(authAtom);
    const resetBankDetailsAtom = useResetRecoilState(bankDetailsAtom);
    const resetBillingInfoAtom = useResetRecoilState(billingInfoAtom);
    const resetPaymentInfoAtom = useResetRecoilState(paymentInfoAtom);
    const resetPhoneDetailsAtom = useResetRecoilState(phoneDetailsAtom);
    const resetProfilesAtom = useResetRecoilState(profilesAtom);
    const resetPromoteChannelsAtom = useResetRecoilState(promoteChannelsAtom);
    const resetRegStepAtom = useResetRecoilState(regStepAtom);
    const resetLeftMenuAtom = useResetRecoilState(leftMenuAtom);
    const resetCampaignDateRangeIDAtom = useResetRecoilState(campaignDateRangeIDAtom);
    const resetEarningSummaryAtom = useResetRecoilState(earningSummaryAtom);
    const resetCampaignsAtom = useResetRecoilState(campaignsAtom);
    const resetCampaignDetailsAtom = useResetRecoilState(campaignDetailsAtom);
    const resetCampaignDetailsIDAtom = useResetRecoilState(campaignDetailsIDAtom);
    const resetLookupLandingPagesAtom = useResetRecoilState(lookupLandingPagesAtom);

    const clearTokens = ()=>{
        localStorage.removeItem(ACCESSTOKEN_KEY);
        localStorage.removeItem(REFRESHTOKEN_KEY);

        //set auth atom to not authenticated
        //setAuthState({...authState, isAuthenticated: false});
        resetAuth();
        resetBankDetailsAtom();
        resetBillingInfoAtom();
        resetPaymentInfoAtom();
        resetPhoneDetailsAtom();
        resetProfilesAtom();
        resetPromoteChannelsAtom();
        resetRegStepAtom();
        resetLeftMenuAtom();
        resetCampaignDateRangeIDAtom();
        resetEarningSummaryAtom();
        resetCampaignsAtom();
        resetCampaignDetailsAtom();
        resetCampaignDetailsIDAtom();
        resetLookupLandingPagesAtom();
        setAuthState({...authState, isAuthenticated: false, accountLoaded:false});
    };


    const getBearerToken = () => {
        return localStorage.getItem(ACCESSTOKEN_KEY);
    };

    const getRefreshToken = () => {
        return localStorage.getItem(REFRESHTOKEN_KEY);
    };

    const setAuthTokens = ({BearerToken, RefreshToken}) => {
        //store tokens
        localStorage.setItem(ACCESSTOKEN_KEY, BearerToken);
        localStorage.setItem(REFRESHTOKEN_KEY, RefreshToken);

        //set auth atom to authenticated
        setAuthState({...authState, isAuthenticated: true});

    }

    const getNewAccessToken = async failedRequest => {
        try {
            const { data } = await publicFetch.post(
                '/access-token', 
                {RefreshToken: getRefreshToken()}
            );
    
            failedRequest.response.config.headers[
                'Authorization'
            ] = `Bearer ${data.AccessToken}`;
    
            localStorage.setItem(ACCESSTOKEN_KEY, data.AccessToken);
    
            return Promise.resolve();
        }
        catch{
            //console.log("goes here");
            clearTokens();
            //set auth atom to authenticated
            //setAuthState({...authState, isAuthenticated: false, isRefreshTokenExpired: true});
            return Promise.reject(new Error("session expired, please login again"));
        }
        
    };

    // const isAuthenticated = ()=>{
    //     return authState.isAuthenticated;
    // }

    return {
        ...authState,
        clearTokens,
        getBearerToken,
        getNewAccessToken,
        setAuthTokens,
    };
}

export default useAuthProvider;
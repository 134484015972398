import React from 'react';
import { PAGE_NAV } from 'configs/page';
import { Redirect } from 'react-router-dom';
import useFullRegOnly from "hooks/useFullRegOnly";
import useAuth from 'hooks/useAuth';

const LoggedInDisplay  = (props) => {
    const { children } = props;
    
    return (
        <>
            {
               useFullRegOnly(
                    <>
                     {children}   
                    </>
                )
            }
        </>
    );
}

const LoggedInLayout = (props) => {
    const authProvider = useAuth();
    const {redirToLogout} = authProvider;

    return (
        <>
            {
                redirToLogout
                ? <Redirect to={PAGE_NAV.LOGOUT} /> 
                : <LoggedInDisplay {...props} />
            }
        </>
    );
}

export default LoggedInLayout;
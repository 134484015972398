import React,{ useState } from 'react';
import classNames from 'classnames';
import aqLogo from "../assets/img/aq-logo.svg";
import { Link } from 'react-router-dom';
import {PAGE_NAV} from "configs/page";
import useLogout from 'hooks/useLogout';
import useAuth from 'hooks/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { BrowserView, MobileView } from 'react-device-detect';
import ContactSupportPopup from "components/modals/ContactSupportPopup";

const AffiliateHeader = (props) => {
    const {history} = props;
    const {isLoggedIn} = useAuth();
    const logoutProvider = useLogout();

    const onLogoutClicked = (e)=>{
        e.preventDefault();
        logoutProvider().then(()=>{history.push(PAGE_NAV.LOGIN)});
    }

    const [mobileNavOn, setMobileNavOn] = useState(false);

    const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);

    return (
    <div className="top-nav">
        <p className="page-logo"><Link to={PAGE_NAV.HOME} title="affiliate-quarter.frootdigital.com"><img src={aqLogo} alt="Affiliate quarter" /></Link></p>
        <p className="mobile-nav-btn">
            <a href="/">
                <FontAwesomeIcon icon={faBars} onClick={(e)=>{e.preventDefault();setMobileNavOn(!mobileNavOn);e.stopPropagation()}} />
            </a>
        </p>
        <div className={classNames("nav", {"on": mobileNavOn})}>
            <p className="close-btn"><a href="/" onClick={(e)=>{e.preventDefault();setMobileNavOn(false)}}><FontAwesomeIcon icon={faTimes} /></a></p>
            <ul>
                <li><Link to={PAGE_NAV.HOME} title="Click to go to the home page">Home</Link></li>
                <li>
                    {/* <a href="/" onClick={e=>e.preventDefault()} title="Click to get in touch">Get in touch</a> */}
                    <BrowserView>
                    <a href="/" onClick={(event)=>{event.preventDefault(); setShowContactSupportPopup(true)}} title="Click to get in touch">Get in touch</a>
                    </BrowserView>
                    <MobileView>
                    <Link to={PAGE_NAV.CONTACTSUPPORT} title="Click to get in touch">Get in touch</Link>
                    </MobileView>
                    <ContactSupportPopup open={showContactSupportPopup} close={()=>setShowContactSupportPopup(false)} postProcFunc={()=>{}} loggedin={false} />
                    </li>
                {
                    isLoggedIn 
                    ? <li className="btn link green"><a href="/" onClick={(e)=>{onLogoutClicked(e)}}>Logout</a></li>
                    : <>
                        <li><Link to={PAGE_NAV.LOGIN} title="Click to join now">Login</Link></li>
                        <li className="btn link green"><Link to={PAGE_NAV.JOIN} title="Click to join now">Join now</Link></li>
                        
                    </>
                }
            </ul>
        </div >
        
    </div>
    );
}

export default AffiliateHeader;
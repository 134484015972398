import TicketList from "components/TicketList";
import LoggedInLayout from 'layouts/LoggedInPlainLayout';
import {PAGE_NAV} from "configs/page";

const TicketListPage = (props) => {
    const {history} = props;
    const goBackToSupport = ()=>  history.replace(PAGE_NAV.SUPPORT);

    return (
        <LoggedInLayout {...props}>
            <TicketList isOpenInPopup={false} onBackClicked={goBackToSupport} />
        </LoggedInLayout>
    );
        
}

export default TicketListPage;


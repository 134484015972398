import profilesAtom from "recoil/account/profiles";
import phoneDetailsAtom  from "recoil/account/phoneDetails";
import paymentInfoAtom from "recoil/account/paymentInfo";
import bankDetailsAtom from "recoil/account/bankDetails";
import billingInfoAtom from "recoil/account/billingInfo";
import promoteChannelsAtom from "recoil/account/promoteChannels";
import authAtom from "recoil/account/auth";
import dateJoinedAtom from "recoil/account/dateJoined";
import accService from "services/account";
//eslint-disable-next-line no-unused-vars
import { useRecoilState, useRecoilCallback } from "recoil";
import useToast from "./useToast";


const useAccount = ()=>{
    const toast = useToast();
    const service = accService();
    
    const [auth, setAuth] = useRecoilState(authAtom);
    const [, setDateJoined] = useRecoilState(dateJoinedAtom);
    const [profiles, setProfiles] = useRecoilState(profilesAtom); 
    const [phones, setPhones] = useRecoilState(phoneDetailsAtom);
    const [paymentInfo, setPaymentInfo] = useRecoilState(paymentInfoAtom);
    const [bankDetails, setBankDetails] = useRecoilState(bankDetailsAtom);
    const [billingInfo, setBillingInfo] = useRecoilState(billingInfoAtom);
    //eslint-disable-next-line no-unused-vars
    const [promoteChannels, setPromoteChannels] = useRecoilState(promoteChannelsAtom);

    const createAccount = async(data)=>{
        return service.createAccount(data)
        .then(response=>{
            const {
                DateJoined,
                ProfileDetails, 
                PhoneDetails, 
                PaymentInfoDetails, 
                BillingInfoDetails, 
                PromoteChannels
            } = response.data;

            const {BankDetails, AffiliatePaymentTypeID,
                Paypal, PaymentID, ProgramID} = PaymentInfoDetails;
            
            setDateJoined(DateJoined);
            setProfiles(ProfileDetails);
            setPhones(PhoneDetails);
            setPromoteChannels(PromoteChannels);
            setBillingInfo(BillingInfoDetails);
            setBankDetails(BankDetails)
            setPaymentInfo({
                ...paymentInfo,
                AffiliatePaymentTypeID,
                Paypal,
                PaymentID,
                ProgramID
            });
            setAuth({
                ...auth,
                accountLoaded: true,
            });

            return;
        })
        .catch((error) => {
            toast.error("Failed create account", error);
            throw error;
        });
    };

    const getAccount = useRecoilCallback(({ set }) => async()=>{
        return service.getAccount()
            .then(response=>{
                
                const {
                    DateJoined,
                    ProfileDetails, 
                    PhoneDetails, 
                    PaymentInfoDetails, 
                    BillingInfoDetails, 
                    PromoteChannels
                } = response.data;

                const {BankDetails, AffiliatePaymentTypeID,
                    Paypal, PaymentID, ProgramID} = PaymentInfoDetails;

                set(profilesAtom, ProfileDetails);
                set(phoneDetailsAtom, PhoneDetails);
                set(promoteChannelsAtom, PromoteChannels);
                set(billingInfoAtom, BillingInfoDetails);
                set(paymentInfoAtom, {
                    AffiliatePaymentTypeID,
                    Paypal,
                    PaymentID,
                    ProgramID
                });
                set(bankDetailsAtom, BankDetails);
                set(authAtom, {...auth, accountLoaded: true});
                set(dateJoinedAtom, DateJoined);
            })
            .catch((error) => {
                toast.error("Failed retrieving account", error);
                throw error;
            });
        
        
        
    });
    
    const validatePhone = async(code)=>{
        return service.validatePhone({VerificationCode : code})
            .then(response=>setPhones(response.data))
            .catch((error) => {
                toast.error("Failed verifying code", error);
                throw error;
            });
    }

    const verifyPhone = async(data)=>{
        return service.verfifyPhoneValidationCode(data)
            .then(response=>response.data)
            .catch((error) => {
                toast.error("Failed verifying code", error);
                throw error;
            });
    }

    const requestPhoneCode = async(data)=>{
        return service.requestValidationCode(data)
            .catch((error) => {
                toast.error("Failed requesting sms code", error);
                throw error;
            });
    }

    const updatePaymentInfo = async(data)=>{
        return service.updatePaymentInfoDetails(data)
        .then(response=>{
            const {
                BankDetails, AffiliatePaymentTypeID,
                Paypal, PaymentID, ProgramID
            } = response.data;

            setBankDetails(BankDetails)
            setPaymentInfo({
                ...paymentInfo,
                AffiliatePaymentTypeID,
                Paypal,
                PaymentID, 
                ProgramID
            });
            
            return;
        })
        .catch((error) => {
            toast.error("Failed updating payment info", error);
            throw error;
        });
    }

    const updateBillingInfo = async(data)=>{
        return service.updateBillingInfoDetails(data)
        .then(response=>setBillingInfo(response.data))
        .catch((error) => {
            toast.error("Failed updating billing info", error);
            throw error;
        });
    }

    const updateProfileDetails = async(data)=>{
        return service.updateProfileDetails(data)
        .then(response=>{
            const {
                ProfileDetails, 
                PromoteChannels
            } = response.data;

            setProfiles(ProfileDetails);
            setPromoteChannels(PromoteChannels);
        })
        .catch((error) => {
            toast.error("Failed updating profile details", error);
            throw error;
        });
    }

    const updatePhoneDetails = async(data)=>{
        return service.updatePhone(data)
        .then(response=>{
            setPhones(response.data);
            return response.data.PhoneVerified === false;
        })
        .catch((error) => {
            toast.error("Failed updating phone number", error);
            throw error;
        });
    }

    return {
        createAccount,
        validatePhone,
        verifyPhone,
        requestPhoneCode,
        paymentInfo,
        bankDetails,
        profiles,
        phoneDetail: phones,
        billingInfo,
        promoteChannels,
        getAccount,
        updatePaymentInfo,
        updateBillingInfo,
        updateProfileDetails,
        updatePhoneDetails
    }
}

export default useAccount;
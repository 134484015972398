import {  useRecoilValueLoadable, 
        useRecoilValue,
        useSetRecoilState, 
        useRecoilState, 
        useRecoilCallback } from "recoil";
import SupportService from 'services/support';
import useToast from "./useToast";

import ticketListAtom from "recoil/support/tickets";
import ticketListCriteriaAtom from "recoil/support/ticketsCriteria";
import ticketDetailsAtom from "recoil/support/ticketDetail";

import cloneDeep from 'lodash/cloneDeep';

const useSupport =  ()=>{
    const toast = useToast();
    const [ticketList, setTicketList] = useRecoilState(ticketListAtom);
    const ticketListLoadable = useRecoilValueLoadable(ticketListAtom);
    const ticketListCriteria = useRecoilValue(ticketListCriteriaAtom);
    const ticketDetailLoadable = useRecoilValueLoadable(ticketDetailsAtom);
    const setTicketDetail = useSetRecoilState(ticketDetailsAtom);

    const fetchTickets = useRecoilCallback(({ set }) => async (criteria, current) => {
        return SupportService.getTicketList(criteria)
        .then(response=>{
            const newCriteria = {
                ...criteria,
                IsLoaded: true
            };
            set(ticketListCriteriaAtom, newCriteria);
            const {List} = current;
            const {Data, Total, NotCheckedCount} = response.data;
            const currentRefs = List.map(itm=>itm.TicketRef);
            const filtered = Data.filter(itm=> currentRefs.includes(itm.TicketRef)===false);
            const newList = [...List, ...filtered];
            set(ticketListAtom, {List: newList, Total, NotCheckedCount});
            
        })
        .catch(err=>{toast.error(err)});
    });

    const fetchTicketDetail = useRecoilCallback(({ set }) => async (ticketRef, currentList) => {
        if(!ticketRef) return;
        return SupportService.getTicketDetail({ TicketRef: ticketRef})
            .then(response => {
                set(ticketDetailsAtom, response.data);
                const {List} = currentList;
                const newList = cloneDeep(List);

                const idx = newList.findIndex(itm=>itm.TicketRef === ticketRef);
                if(idx !== -1){
                    const newItm = newList.find(itm=>itm.TicketRef === ticketRef);
                    newItm.NewCount = 0;
                    newList[idx] = newItm;
                    const newListAtomVal = {
                       ...currentList,
                       List: newList
                    };
                    set(ticketListAtom, newListAtomVal);
                }

            })
            .catch(err => {toast.error("Failed to retrieve ticket", err); throw err});
    });

    const uploadAttachment = async(data)=>{
        return SupportService.uploadAttachment(data)
               .then(response=>response, (err)=>{
                   throw new Error(err);
               });
    }

    const addNewTicket = async(data)=>{
        return SupportService.createTicket(data)
               .then((response)=>{
                    const {List} = ticketList;
                    const idx = List.findIndex(itm=>itm.TicketRef === response.data.TicketRef);
                    if(idx === -1){
                        //add to list
                        setTicketList(prev=>({
                            ...prev,
                            List : [response.data, ...prev.List],
                            Total: prev.Total + 1
                        }));
                    }
                  
                    return true;
               }, (err)=>{
                   throw new Error(err);
               });
    };

    const addTicketReply = async(data)=>{
        return SupportService.createTicketReply(data)
               .then((response)=>{
                   //add to response list
                   setTicketDetail(prev=>({
                        ...prev,
                        Responses : [response.data, ...prev.Responses]
                    }));
                    return true;
               }, (err)=>{
                   throw new Error(err);
               });
    };

    return {
        fetchTickets,
        ticketList,
        ticketListLoadable,
        ticketListCriteria,
        ticketDetailLoadable,
        fetchTicketDetail,
        addNewTicket,
        uploadAttachment,
        addTicketReply
    }
}

export default useSupport;
import {atom} from "recoil";
import keys from "configs/atoms";

const initState = {
    TicketStarter : null,
    Responses: [],
    Attachments: []
}

const ticketDetailsAtom = atom(({
    key: keys.Support.TicketDetails,
    default: initState
}));

export default ticketDetailsAtom;
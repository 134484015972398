import DashboardDarkIco from "assets/img/nav-ico/dark/dashboard.svg";
import CampaignDarkIco from "assets/img/nav-ico/dark/campaign.svg";
// import StatsDarkIco from "assets/img/nav-ico/dark/stats.svg";
import ResourcesDarkIco from "assets/img/nav-ico/dark/resources.svg";
import SupportDarktIco from "assets/img/nav-ico/dark/support.svg";
import LogoutDarkIco from "assets/img/nav-ico/dark/logout.svg";
import DashboardWhiteIco from "assets/img/nav-ico/white/dashboard.svg";
import CampaignWhiteIco from "assets/img/nav-ico/white/campaign.svg";
// import StatsWhiteIco from "assets/img/nav-ico/white/stats.svg";
import ResourcesWhiteIco from "assets/img/nav-ico/white/resources.svg";
import SupportWhiteIco from "assets/img/nav-ico/white/support.svg";
import userIco from "assets/img/user-ico.svg";

export const PAGE_NAV = {
    HOME: "/",
    JOIN : "/join",
    LOGIN : "/login",
    FORGOT_PWD: "/password/forgot",
    RESET_PWD: "/password/reset",
    ACCOUNT: "/account",
    DASHBOARD: "/dashboard",
    CAMPAIGNS: "/campaigns",
    CAMPAIGN_ADD: "/campaigns/add",
    //STATS: "/stats",
    RESOURCES: "/resources",
    SUPPORT: "/support",
    LOGOUT: "/logout",
    CONTACTSUPPORT: "/support/contact",
    TICKETLIST: "/support/ticket",
    TICKETDETAIL: "/ticketdetail",
    TICKETMESSAGE: "/ticketmessage",
    TC: "/t&C",
    TOTALEARNING: "/totalearning"
};

export const MENUS = [
    {value: "ACCOUNT", text: "My account", icon: userIco, selectedIcon: userIco, positions:["account"]},
    {value: "DASHBOARD", text: "Dashboard", icon: DashboardDarkIco, selectedIcon: DashboardWhiteIco, positions:["main"]},
    {value: "CAMPAIGNS", text: "Campaigns", icon: CampaignDarkIco, selectedIcon: CampaignWhiteIco, positions:["main"]},
    //{value: "STATS", text: "Stats", icon: StatsDarkIco, selectedIcon: StatsWhiteIco, positions:["main"]},
    {value: "RESOURCES", text: "Resources", icon: ResourcesDarkIco, selectedIcon: ResourcesWhiteIco, positions:["main"]},
    {value: "SUPPORT", text: "Support", icon: SupportDarktIco, selectedIcon: SupportWhiteIco, positions:["main", "account"]},
    {value: "LOGOUT", text: "Log out", icon: LogoutDarkIco, selectedIcon: LogoutDarkIco, positions:["main", "account"]},
];

export const JOIN_STEP = {
    CREATE_ACCOUNT: 1,
    VERIFY_PHONE_NUMBER: 2,
    PAYMENT_INFO: 3,
    BILLING_INFO: 4,
    DONE:99
}
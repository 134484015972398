import {useSetRecoilState} from "recoil"
import auth from "recoil/account/auth";
import errMsgAtom from "recoil/common/errMsg";

const useToast = ()=>{
    const setAuth = useSetRecoilState(auth);
    const setErrMsg = useSetRecoilState(errMsgAtom);

    const displayErrorMessage=(msg)=>{
        // console.log("msg", msg);
        if(!msg) return;
        msg = String(msg);
        if(
            msg.toLowerCase().indexOf("refresh token expired") !== -1
            // msg.indexOf("401")!==-1  //from axios
            // || 
            || msg.toLowerCase() === "user does not exist" //from api credentials
            || msg.toLowerCase() === "account not found" // from api
            || msg.toLowerCase() === "failed retrieving account" //from useAccount
            ){
                // console.log("redir to logout");
            setAuth(prevState=>({
                ...prevState,
                redirToLogout:true
            }));
            return;
        }
        
        if(msg.indexOf("401")===-1 && msg.toLowerCase().indexOf("token has expired") )  //from axios because bearer token is being updated
           // window.alert(`${msg}`);
           setErrMsg({msg: msg, isShow: true});
    }

    const displaySuccessMessage = (msg)=>{
        if(msg) window.alert(`${msg}`);
    }

    const showError = (defaultErrorMessage, error)=>{
        // console.log("defaultErrorMessage", defaultErrorMessage);
        // console.log("error", error)
        if(!error) return displayErrorMessage(defaultErrorMessage); 
        
        const {response} = error;
        if(!response) return displayErrorMessage(defaultErrorMessage); 
        const {data} = response;
        if(!data)return displayErrorMessage(defaultErrorMessage); 
        const {ResponseStatus} = data;
        if(!ResponseStatus) return displayErrorMessage(defaultErrorMessage); 
        const {Message} = ResponseStatus;
        return displayErrorMessage(Message ? Message : defaultErrorMessage); 
    }

    return {
        error: showError,
        success: (msg)=>displaySuccessMessage(`${msg}`)
    };

}

export default useToast;
import {atom} from "recoil";
import keys from "configs/atoms";

const initState = {
    PromoteWebsite : "",
    PromoteInstagram : "",
    PromoteTikTok : "",
    PromoteYoutube : "",
    PromoteFacebook : "",
    PromoteOnlyFans : "",
    PromoteOther : "",
}

const promoteChannelsAtom = atom(({
    key: keys.Account.PromoteChannels,
    default: initState
}));

export default promoteChannelsAtom;
import React, {useState, useEffect, useRef} from 'react';
import classNames from 'classnames';
import { Modal } from 'react-responsive-modal';
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import MobileVerify from 'components/modals/PhoneVerification';
import useAccount from "hooks/useAccount";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import PhoneCountryCodePopup from 'components/modals/PhoneCountryCodePopup';
import { allCountries } from 'country-telephone-data';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import ConfirmPopup from 'components/modals/ConfirmPopup'

const UpdateMobileNumber = (props) => {
    const {open, close, showCloseIcon=true} = props;
    const ref = useRef();
    const accountProvider = useAccount();
    const {phoneDetail} =  accountProvider;
    const { PhoneNumber, PhoneAlpha2Code} = phoneDetail;
    const validationSchema = Yup.object().shape({
        phoneCountryCode:  Yup.object()
            .notRequired().nullable()
            // .required('Phone country code is required')
            .oneOf(allCountries, "Please select a country code from the list"),
        phoneNumber: Yup.string()
                // .required("Phone number is required")
                .matches(/^\d+$/,{message:'Phone number should have digits only',excludeEmptyString:true})
                .test("isPhoneNumberValid", "Invalid phone number", function(val){
                    if(!val) return true;
                    const {phoneCountryCode} = this.parent;
                    if(!phoneCountryCode) return true;
                    const {iso2, dialCode} = phoneCountryCode;
                    return isValidPhoneNumber(`+${dialCode}${val}`, iso2.toUpperCase());
                }),
    });
    const { 
        handleSubmit, 
        formState: { errors }, 
        register,
        setValue,
        getValues
    } = useForm({ 
        mode: 'all', 
        defaultValues: {
            phoneNumber: PhoneNumber,
            phoneCountryCode: allCountries.find(itm=>itm.iso2=== (PhoneAlpha2Code ? PhoneAlpha2Code.toLowerCase() : "au")),
        },
        resolver: yupResolver(validationSchema) 
    });

    const [countryCodeOpen, setCountryCodeOpen] = useState(false);
    
    const setPhoneCountryCode = (pcc)=>{
        setValue("phoneCountryCode", pcc);
        setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }

    useEffect(()=>{
       const iso2 = PhoneAlpha2Code ? PhoneAlpha2Code.toLowerCase() : "au";
       const pcc = allCountries.find(itm=>itm.iso2===iso2);
       setValue("phoneCountryCode", pcc);
       setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }, [setValue]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phoneVerifyOpen, setPhoneVerifyOpen] = useState(false);
    const [delConfirmPopup, setDelConfirmPopup] = useState(false);
    const [phoneVerificationResult, setPhoneVerificationResult] = useState(null);

    useEffect(()=>{
        if(getValues("phoneNumber")){
            setPhoneVerificationResult(null);
        }
    }, [getValues, setPhoneVerificationResult])
    
    const onPhoneRequestResendCode = ()=>{
        const phoneNumber = getValues("phoneNumber");
        const phoneCountry = getValues("phoneCountryCode");
        const {dialCode, iso2} = phoneCountry;
        return accountProvider.requestPhoneCode({PhoneNumber:phoneNumber, PhoneCountryCode:dialCode, PhoneAlpha2Code:iso2});
    }

    const onPhoneVerificationClicked = async (code)=>{
        const phoneNumber = getValues("phoneNumber");
        const phoneCountry = getValues("phoneCountryCode");
        const {dialCode, iso2} = phoneCountry;

        const result = await accountProvider.verifyPhone({
            VerificationCode: code,
            PhoneNumber:phoneNumber, 
            PhoneCountryCode:dialCode, 
            PhoneAlpha2Code:iso2});
        
        setPhoneVerificationResult(result);
        setTimeout(()=>{
            ref.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }, 150) 
    }

    const postPhoneVerificationSuccessFunc = ()=>{
        // setPhoneVerifyOpen(false);
        // close();
    }

    const processUpdatePhoneNumber = async(submittedData)=>{
        if(isSubmitting) return;
        setIsSubmitting(true);
        accountProvider.updatePhoneDetails(submittedData)
        .then((resp)=>{
            // resp===true ? setPhoneVerifyOpen(true) : close();
        })
        .finally(()=>{
            setIsSubmitting(false)
            close();
        });
    }

    const removePhoneNumber = async()=>{
        processUpdatePhoneNumber({
            PhoneNumber: "",
            PhoneCountryCode: "",
            PhoneAlpha2Code: "",
            PhoneVerified: false
        })
    }

    const onSubmitHandler = async(frmData, e)=>{
        e.preventDefault();

        if(!frmData.phoneNumber){
            if(PhoneNumber){
                setDelConfirmPopup(true)
            }
            else {
                removePhoneNumber();
            }
            
        }
        else {
            if(phoneVerificationResult){
                processUpdatePhoneNumber(phoneVerificationResult);
            }
            else {
                onPhoneRequestResendCode().then(()=>{
                    setPhoneVerifyOpen(true);
                });
            }
           
        }
    }

    return (
        <Modal classNames={{ overlay: 'verification-overlay', modal: 'verification-modal',}} 
            open={open} 
            onClose={close} 
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={showCloseIcon}
            center>
            <h2>{PhoneNumber ? `Update` : `Add`} phone number</h2>
            <p>Enter your phone number</p>
            
            <form className="verification-form" ref={ref} onSubmit={handleSubmit(onSubmitHandler)}>
                <fieldset className="input">
                    <p className={classNames("phone", {error: errors?.phoneNumber?.message})} >
                        <label htmlFor="phone" >Mobile number</label>
                        <span className="field">
                            <input type="hidden" id="hdnPhoneCountryCode" {...register("phoneCountryCode")} />
                            <input className="area-code" id="area-code" type="tel" placeholder="+61" {...register("phoneCountryCodeDisplay")} onClick={()=>setCountryCodeOpen(true)}  readOnly />
                            <input id="phone" className="phone" type="tel" placeholder="Mobile Number" {...register("phoneNumber")} />
                        </span>
                        <span className="error-msg">{errors?.phoneNumber?.message}</span>
                    </p>
                    {/* <p className={classNames("phone", {error: true})} >
                        <span className="field">
                        <input type="hidden" id="hdnPhoneCountryCode" />
                            <input className="area-code" id="area-code" type="tel" placeholder="+61" readOnly />
                            <input id="phone" className="phone" type="tel" placeholder="Mobile Number" />
                        </span>

                        <span className="error-msg">Please enter your mobile number</span>
                    </p> */}
                </fieldset>
                <fieldset className="submit">
                    <p className="btn green">
                        <ButtonWithSpinner text="Update" isLoading={isSubmitting} />
                    </p>
                </fieldset>
            </form>
            {
                countryCodeOpen && <PhoneCountryCodePopup  open={countryCodeOpen} close={()=>setCountryCodeOpen(false)} setCountryCode={setPhoneCountryCode} defaultValue={getValues("phoneCountryCode")} />
            }
            {
                phoneVerifyOpen &&  <MobileVerify 
                                        open={phoneVerifyOpen} 
                                        close={()=>setPhoneVerifyOpen(false)} 
                                        postProcessFunc={postPhoneVerificationSuccessFunc} 
                                        onPhoneVerificationClicked={onPhoneVerificationClicked} 
                                        onRequestResendCode={onPhoneRequestResendCode} 
                                        showCloseIcon={true}
                                        />
            }
            {
                delConfirmPopup && 
                <ConfirmPopup 
                    open={delConfirmPopup} 
                    close={()=>{setDelConfirmPopup(false);}}
                    confirm={removePhoneNumber} 
                    heading="Remove phone number" 
                    btnText="Remove">
                    <p>Are you sure you want to remove your phone number?</p>
                </ConfirmPopup>
            }
            
           
        </Modal>
    );
}

export default UpdateMobileNumber;
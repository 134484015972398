import { atom} from "recoil";
import atomKeys from "configs/atoms";

const initState = {
    PhoneNumber: "",
    PhoneCountryCode: "",
    PhoneAlpha2Code: "",
    PhoneVerified: false
};

const phoneDetailsAtom = atom({
    key: atomKeys.Account.PhoneDetails,
    default: initState
});

export default phoneDetailsAtom;
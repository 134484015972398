import React, { useState} from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft  } from "@fortawesome/free-solid-svg-icons";
import plusGreenCircle from 'assets/img/plus-green-circle-ico.svg';
import crossRedCircle from "assets/img/cross-red-circle-ico.svg";

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import * as Yup from 'yup';

import ButtonWithSpinner from './buttons/ButtonSpinner';

import imageCompression from "browser-image-compression";

import useToast from "hooks/useToast";
import useSupport from 'hooks/useSupport';

import ClipLoader from "react-spinners/ClipLoader";

const Message = (props) => {
    const toast = useToast();
    const supportProvider = useSupport();

    const validationSchema = Yup.object().shape({
        message: Yup.string().trim().required("Required"),
    });
    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        defaultValues: {
           message: ""
        },
        resolver: yupResolver(validationSchema)
    });

    const { isOpenInPopup = false,  ticketRef = '', onBackClicked, postProcFunc = ()=>{}, close} = props;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [attachments, setAttachments] = useState([]);

    const handleUpload = async(event) => {
        if(isUploading) return;
        setIsUploading(true);
        const file = event.target.files[0];
        
        try {
            const compressedFile = await imageCompression(file, {maxSizeMB: 4, maxWidthOrHeight: 1920});
            //note compressedFile is a blob, convert it to file for uploading
            const data = new FormData();
            data.append("file", new File([compressedFile], file.name));
            
            await supportProvider.uploadAttachment(data)
            .then((response)=>{
                setAttachments(prev=>([...response.data,...prev]));
                setIsUploading(false);
            }, (err)=>{toast.error("Failed to upload image", err);setIsUploading(false);});
            
          } catch (error) {
                setIsUploading(false);
                toast.error(error);
          }
    }

    const handleRemoveAttachment = (id)=>{
        const atts = attachments.filter(a=>a.FileID !== id);
        setAttachments(atts);
    }

    const onSubmitHandler = async (frmData, e) => {
        e.preventDefault();

        if(isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            TicketRef : ticketRef,
            Message : frmData.message,
            Attachments: attachments.map(att=>att.FileID)
        };

        await supportProvider.addTicketReply(submittedData)
            .then(()=>{
                    setIsSubmitting(false);
                    postProcFunc();
                }, (err)=>{
                    setIsSubmitting(false);
                    toast.error("Failed to reply", err);
                });
    }

    const handleCloseBtn = (event) => {
        event.preventDefault();
        if(!isOpenInPopup) {
            onBackClicked();
        }else {
            close();
        }
    }

    return (
        <div className="ticket-msg">
            
            <form className="msg-form" onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="header">
                    {/* {showBackBtn && <p className="back-btn"><a href="/" onClick={(event) =>{event.preventDefault();onBackClicked();}} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>} */}
                    <p className="back-btn"><a href="/" onClick={handleCloseBtn} title="Click to go back"><FontAwesomeIcon icon={faChevronLeft} /></a></p>
                    <h1 className="page-header">
                        <span className="title">Ticket detail</span>
                        <span className="ticket-id">Ticket ID: #{ticketRef}</span>
                    </h1>
                    <p className="btn green"><ButtonWithSpinner text={"Send"} isLoading={isSubmitting} /></p>
                </div>
                <fieldset className="msg input">
                    <p className={classNames({ error: errors?.message?.message })}>
                        <textarea id="message" placeholder="Type a message ..." {...register("message")}></textarea>
                        {errors?.message?.message && <span className="error-msg">{errors?.message?.message}</span>}
                    </p>
                </fieldset>
                <div className={classNames("file-uploader")}>
                        <input type="file" id="image-attach" name="image-attach" onChange={handleUpload} onClick={(event)=> {event.target.value = null}} />
                        {
                            attachments.length > 0 
                            ?

                            <div className="preview">
                                {attachments.map((file, i)=>(
                                    
                                    <p key={i} className="thumbnail" style={{
                                        backgroundImage:"url(" + file.AttachmentUrl+ ")", 
                                        }}>
                                        <img src={file.AttachmentUrl} alt={`attachment ${i+1}`} />
                                        <span className="ico" onClick={()=>handleRemoveAttachment(file.FileID)}><img src={crossRedCircle} alt="X" /></span>
                                    </p>
                                ))}
                                <label className="thumbnail" htmlFor="image-attach">
                                    <span>Attach image</span>
                                    <span className="ico"><img src={plusGreenCircle} alt="+" /></span>
                                </label>
                            </div>
                            : <label htmlFor="image-attach">Attach image <span className="upload-btn"><ClipLoader 
                            color="#ffffff" loading={isUploading} 
                            css={"display:inline-block;width:18px;height:18px;margin-right:4px;"} 
                            size={150} /> Upload <span className="ico"><img src={plusGreenCircle} alt="+" /></span></span></label>
                        }
                        {/* {
                        uploadFiles.length > 0 
                        ?
                        <div className="preview">
                            {uploadFiles.map((file, i)=>(
                                file.type === "image" ?
                                <p key={i} className="thumbnail" style={{
                                    backgroundImage:"url(" + file.url+ ")", 
                                    }}>
                                    <img src={file.url} alt="Image thumbnail" />
                                    <span className="ico" onClick={()=>handleRemove(file.url)}><img src={crossRedCircle} alt="X" /></span>
                                </p>
                                :
                                <p key={i} className="thumbnail">
                                    <video width="150" controls>
                                    <source src={file.url} />
                                        Your browser does not support the video tag.
                                    </video>
                                    <span className="ico" onClick={()=>handleRemove(file.url)}><img src={crossRedCircle} alt="X" /></span>
                                </p>
                            ))}
                            <label className="thumbnail" htmlFor="image-attach">
                                <span>Attach image or video</span>
                                <span className="ico"><img src={plusGreenCircle} alt="+" /></span>
                            </label>
                        </div>
                        :
                        <label htmlFor="image-attach">Attach image <span className="upload-btn">Upload <span className="ico"><img src={plusGreenCircle} alt="+" /></span></span></label>
                        }*/}
                    </div> 
                    {/* <fieldset className="submit">
                        <p className="btn green"><ButtonWithSpinner text={"Send"} isLoading={isSubmitting} /></p>
                    </fieldset> */}
                    
            </form>
            
            

        </div>
    );
}

export default Message;
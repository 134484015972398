import React, {useState} from 'react';
import classNames from 'classnames';
import { Modal } from 'react-responsive-modal';
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import valUtils from "utils/validations"
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useToast from 'hooks/useToast';
import AccountService from 'services/account';

const PasswordUpdate = (props) => {
    const toast = useToast();
    const validationSchema = Yup.object().shape({
        password: valUtils.password(),
        confirmPassword: valUtils.confirmPassword("password"),
    });
    
    const {open, close } = props;
    const [isSubmitting, setIsSubmitting] = useState(false);

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        mode: 'all',
        defaultValues: {
            password: '',
            confirmPassword: ''
        },
        resolver: yupResolver(validationSchema)
    });

   
    const  onSubmitHandler = async(frmData, e)=>{
        
        e.preventDefault();

        if (isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {Password: frmData.password};

        AccountService.updatePassword(submittedData)
        .then(()=>{
            setIsSubmitting(false);
            close();
        })
        .catch(err=>{
            if(String(err).indexOf("401")!==-1){
                toast.error(String(err));
            }
            else{
                toast.error("Failed updating password",err);
            }
        })
        .finally(()=>setIsSubmitting(false));
    }

    

    return (
        <Modal classNames={{ overlay: 'verification-overlay', modal: 'verification-modal',}} 
            open={open} 
            onClose={close} 
            center>
            <h2>Password Update</h2>
           
            <form className="verification-form" onSubmit={handleSubmit(onSubmitHandler)}>
                <fieldset className="input">
                    <p className={classNames("create-pass", {error: errors?.password?.message})}>
                        <label htmlFor="create-pass" >Create your password</label>
                        <input id="create-pass" autoComplete="new-password" type="password" placeholder="New password"  {...register("password")}/>
                        <span className="error-msg">{errors?.password?.message}</span>
                    </p>
                    <p className={classNames("confirm-pass", {error: errors?.confirmPassword?.message})}>
                        <label htmlFor="confirm-pass" >Confirm password</label>
                        <input id="confirm-pass" autoComplete="new-password" type="password" placeholder="Confirm password"  {...register("confirmPassword")}/>
                        <span className="error-msg">{errors?.confirmPassword?.message}</span>
                    </p>
                </fieldset>
                <fieldset className="submit">
                    <p className="btn green">
                        <ButtonWithSpinner text={`Update`} isLoading={isSubmitting} />
                    </p>
                </fieldset>
            </form>
        </Modal>
    );
}

export default PasswordUpdate;
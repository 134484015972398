import ApiRequest from './base';
import {API_HOST} from "../configs/api";

class SupportService {
    static async getTicketList(data){
        return await ApiRequest({
                url: `${API_HOST}/Support`,
                method: "GET",
                data : data
        });
    };
    static async createTicket(data){
        return await ApiRequest({
                url: `${API_HOST}/Support`,
                method: "POST",
                data : data
        });
    };
    static async uploadAttachment(data){
        return await ApiRequest({
                url: `${API_HOST}/Support/Attachments`,
                method: "POST",
                data : data,
                headers: {"Content-Type": "multipart/form-data"}
        });
    };
    static async getTicketDetail(data){
        const {TicketRef} = data;
        return await ApiRequest({
                url: `${API_HOST}/Support/${TicketRef}`,
                method: "GET",
                data : data
        });
    };
    static async createTicketReply(data){
        // console.log("data", data)
        const {TicketRef} = data;
        return await ApiRequest({
                url: `${API_HOST}/Support/${TicketRef}`,
                method: "POST",
                data : data
        });
    };
    static async updateTicketStatus(data){
        const {TicketRef} = data;
        return await ApiRequest({
                url: `${API_HOST}/Support/${TicketRef}`,
                method: "PATCH",
                data : data
        });
    };
}

export default SupportService;
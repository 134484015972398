import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import useAccount from "hooks/useAccount";
import { useRecoilValueLoadable, useRecoilValue } from 'recoil';
import lookupCountryAtom from 'recoil/lookup/country';
import lookupCurrencyAtom from 'recoil/lookup/currency';
import validationSchema from 'utils/validationSchemas/paymentInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import ButtonWithSpinner from './buttons/ButtonSpinner';
import useLookup from 'hooks/useLookup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import ConfirmPopup from 'components/modals/ConfirmPopup';
import TCPopup from 'components/modals/T&CPopup';

const RegStepTwo = (props) => {
    const lookupProvider = useLookup();
    const  {affiliatePrograms} = lookupProvider;
    const {handlers, display, clx} = props;
    const {saveText="Save", showHeading=true} = display;
    const {postUpdatePaymentInfo} = handlers;
    const accountProvider = useAccount();
    const { paymentInfo, bankDetails } = accountProvider;
    const { AffiliatePaymentTypeID, Paypal, PaymentID, ProgramID } = paymentInfo;
    
    const {
        BankName,
        AccountName,
        AccountNumber,  //for au
        BSBNumber,  //for au
        SwiftCode,  //for intl
        IBAN,  //for intl
        Currency,
        CountryID
    } = bankDetails;
    const [countryList, setCountryList] = useState([]);
    const countryListLoadable = useRecoilValueLoadable(lookupCountryAtom); //todo: put this in uselookup
    const topCountries = [1, 72, 213, 2, 34];
    const currencyList = useRecoilValue(lookupCurrencyAtom);
    const { state: countryListState, contents: countryListContent } = countryListLoadable;

    useEffect(() => {
        switch (countryListState) {
            case "hasValue":
                setCountryList(countryListContent);
                break;
            case "hasError":
                // toast.error("Failed to load club's category", catsContent);
                break;
            case "loading":

                break;
            default:

                break;

        }
    }, [countryListState, countryListContent]) // eslint-disable-line react-hooks/exhaustive-deps
    
    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue
        
    } = useForm({
        defaultValues: {
            paymentType: AffiliatePaymentTypeID,
            paypal: Paypal,
            payID: PaymentID,
            countryID: CountryID,
            currency: Currency,
            bankName: BankName,
            accountName: AccountName,
            accountNumber: AccountNumber,
            bsb: BSBNumber,
            iban: IBAN,
            swiftCode: SwiftCode,
            programID: affiliatePrograms.find(p=>p.ItemID === ProgramID) ? ProgramID : -1
        },
        resolver: yupResolver(validationSchema)
    });

    const watchPaymentType = watch("paymentType", AffiliatePaymentTypeID);
    const wacthCountryID = watch("countryID", -1);
    
    useEffect(() => setValue("paymentType", AffiliatePaymentTypeID), [AffiliatePaymentTypeID, setValue]);
    useEffect(() => setValue("paypal", Paypal), [Paypal, setValue]);
    useEffect(() => setValue("payID", PaymentID), [PaymentID, setValue]);
    useEffect(() => setValue("countryID", CountryID), [CountryID, setValue]);
    useEffect(() => {
        if(affiliatePrograms.find(p=>p.ItemID === ProgramID))
            setValue("programID", ProgramID)
    }, [ProgramID, affiliatePrograms, setValue]);
    useEffect(() => setValue("currency", Currency), [Currency, setValue]);
    useEffect(() => setValue("bankName", BankName), [BankName, setValue]);
    useEffect(() => setValue("accountName", AccountName), [AccountName, setValue]);
    useEffect(() => setValue("accountNumber", AccountNumber), [AccountNumber, setValue]);
    useEffect(() => setValue("bsb", BSBNumber), [BSBNumber, setValue]);
    useEffect(() => setValue("iban", IBAN), [IBAN, setValue]);
    useEffect(() => setValue("swiftCode", SwiftCode), [SwiftCode, setValue]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = async (frmData, e) => {
        e.preventDefault();
        
        if(isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            AffiliatePaymentTypeID: frmData.paymentType,
            PaymentID: frmData.payID,
            Paypal: frmData.paypal,
            BankDetails: {
                BSBNumber: frmData.bsb,
                BankName: frmData.bankName,
                SwiftCode: frmData.swiftCode,
                AccountName: frmData.accountName,
                AccountNumber: frmData.accountNumber,
                IBAN: frmData.iban,
                CountryID: frmData.countryID,
                Currency: frmData.countryID === 1 ? "AUD" : frmData.currency
            }, 
            ProgramID : frmData.programID
        }

        accountProvider.updatePaymentInfo(submittedData)
        .then(()=>{
            if(postUpdatePaymentInfo && typeof postUpdatePaymentInfo === "function"){
                postUpdatePaymentInfo();
            }
        })
        .finally(()=>{
            setIsSubmitting(false);
        });
    }

    const [afPrgmComfirmPopup, setAfPrgmComfirmPopup] = useState(false);
    
    const [showTCPopup, setShowTCPopup] = useState(false);

    return (
        <form className={clx} onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="step two">
                {showHeading && <h2>How would you like to get paid?</h2>}

                { 
                    (parseInt(ProgramID) > 0) && affiliatePrograms.find(p=> p.ItemID === ProgramID)
                    ?
                    <div className="af-prgm">
                        <p>Your account is on the {affiliatePrograms.find(p=> parseInt(p.ItemID) === parseInt(ProgramID)).ItemName} program <span className="info" onClick={()=>setAfPrgmComfirmPopup(true)} ><FontAwesomeIcon icon={faInfoCircle} /></span></p>
                        <ConfirmPopup open={afPrgmComfirmPopup} 
                            close={()=>setAfPrgmComfirmPopup(false)}
                            confirm={afPrgmComfirmPopup}
                            heading={"Your account is on the " + affiliatePrograms.find(p=> parseInt(p.ItemID) === parseInt(ProgramID)).ItemName + "program"}
                            btnText="Ok, got it">
                                {
                                    ProgramID === 10 
                                    ?
                                     <p>You will get 50% share of any purchases made from the people you send to our platform.</p>
                                    : ProgramID === 12 
                                        ?
                                        <p>You will get $3 for each person that you send to our platform and they complete the registration form.</p>
                                        : 
                                         <p>You will get $10 for each person that you send to our platform and they make a purchase.</p> 

                                }
                                <p>There are a few rules that apply, so please read the <a href="/" onClick={e=>{e.preventDefault();setShowTCPopup(true)}} title="Click to find out more">Terms &amp; conditions</a>.</p>
                                <p>If you'd like to subscribe to a different program, you'll need to create a new account.</p>
                                <TCPopup open={showTCPopup} close={()=>setShowTCPopup(false)} />
                        </ConfirmPopup>
                    </div>
                    :<fieldset className={classNames("input", { error: errors?.programID?.message })}>
                        <h3>Select your affiliate program</h3>
                        <div className={classNames("radio-group", { error: errors?.programID?.message })}>
                            {
                                affiliatePrograms.map((program, i)=>
                                    <p className="revenue" key={`prg_${i}`}>
                                        <span className="radio">
                                            <input type="radio" id={`program_${i}`} name={`program_${i}`} value={program.ItemID} {...register("programID")} /><label htmlFor={`program_${i}`}>{program.ItemName}</label>
                                        </span>
                                    </p>
                                )
                            }
                            {errors?.programID?.message && <span className="error-msg">{errors?.programID?.message}</span>}
                        </div>
                    </fieldset>
                }
                
                <fieldset className="input">
                    <h3>Select payment method</h3>
                    <div className="radio-group">
                        <p className="paypal">
                            <span className="radio">
                                <input type="radio" id="paypal" name="paypal" value="PayPal"
                                    {...register("paymentType")}
                                /><label htmlFor="paypal">PayPal</label>
                            </span>
                            {
                                watchPaymentType === "PayPal" &&
                                <span className={classNames("input", { error: errors?.paypal?.message })}>
                                    <label htmlFor="paypal-acc">PayPal account</label>
                                    <input id="paypal-acc" type="text"
                                        placeholder="PayPal account"
                                        {...register("paypal")}
                                    />
                                    {errors?.paypal?.message && <span className="error-msg">{errors?.paypal?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="payid">
                            <span className="radio">
                                <input type="radio" id="payid" name="payid" value="PayID"
                                    {...register("paymentType")} /><label htmlFor="payid">PayID</label>

                            </span>
                            {
                                watchPaymentType === "PayID" &&
                                <span className={classNames("input", { error: errors?.payID?.message })}>
                                    <label htmlFor="payid-no">PayID number</label>
                                    <input id="payid-no" type="text" placeholder="PayID number" {...register("payID")} />
                                    {errors?.payID?.message && <span className="error-msg">{errors?.payID?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="bank-transfor">
                            <span className="radio">
                                <input type="radio" id="bank-transfor" name="bank-transfor"
                                    value="DirectDeposit" {...register("paymentType")}
                                /><label htmlFor="bank-transfor">Bank Transfer</label>
                            </span>

                            {
                                watchPaymentType === "DirectDeposit" &&
                                <span className="bank-detail">
                                    <span className="input">
                                        <span className={classNames("country", { error: errors?.countryID?.message })} >
                                            <label htmlFor="country" >Country</label>
                                            <span className="select">
                                                <select name="country" id="country"
                                                    {...register("countryID")} >
                                                    {
                                                        countryList
                                                        .filter(itm => topCountries.includes(itm.ItemID))
                                                        .sort((a, b) => a.ItemName > b.ItemName ? 1 : -1)
                                                        .map((country) => (
                                                            <option key={`country_${country.ItemID}`} value={country.ItemID}>{country.ItemName}</option>
                                                        ))
                                                    }
                                                    <option disabled={true} value={-2}>-------------------------------------</option>
                                                    {
                                                        countryList
                                                        .filter(itm => !topCountries.includes(itm.ItemID))
                                                        .sort((a, b) => a.ItemName > b.ItemName ? 1 : -1)
                                                        .map((country) => (
                                                            <option key={`country_${country.ItemID}`} value={country.ItemID}>{country.ItemName}</option>
                                                        ))
                                                    }
                                                </select>
                                            </span>
                                            {errors?.countryID?.message && <span className="error-msg">{errors?.countryID?.message}</span>}
                                        </span>
                                    </span>

                                    {
                                        wacthCountryID > 1 &&
                                        <span className="input">
                                            <span className={classNames("currency", { error: errors?.currency?.message })} >
                                                <label htmlFor="currency" >Currency</label>
                                                <span className="select">
                                                    <select name="currency" id="currency"
                                                        {...register("currency")} >
                                                        {
                                                            currencyList.map((currency) => (
                                                                <option key={currency} value={currency}>{currency}</option>
                                                            ))
                                                        }
                                                    </select>
                                                </span>
                                                {errors?.countryID?.message && <span className="error-msg">{errors?.countryID?.message}</span>}
                                            </span>
                                        </span>
                                    }


                                    <span className={classNames("input", { error: errors?.bankName?.message })}>
                                        <label htmlFor="f-inst">Financial institution name</label>
                                        <input id="f-inst" type="text" placeholder="Financial institution name"
                                            {...register("bankName")}
                                        />
                                        {errors?.bankName?.message && <span className="error-msg">{errors?.bankName?.message}</span>}
                                    </span>

                                    <span className={classNames("input", { error: errors?.accountName?.message })}>
                                        <label htmlFor="acc-name">Account name</label>
                                        <input id="acc-name" type="text" placeholder="Account name"
                                            {...register("accountName")}
                                        />
                                        {errors?.accountName?.message && <span className="error-msg">{errors?.accountName?.message}</span>}
                                    </span>
                                    {
                                        wacthCountryID > 1 &&
                                        <>
                                            <span className={classNames("input", { error: errors?.swiftCode?.message })}>
                                                <label htmlFor="bsb-no">BIC/SWIFT</label>
                                                <input id="bsb-no" type="text" placeholder="BIC/SWIFT"
                                                    {...register("swiftCode")} />
                                                {errors?.swiftCode?.message && <span className="error-msg">{errors?.swiftCode?.message}</span>}
                                            </span>

                                            <span className={classNames("input", { error: errors?.iban?.message })}>
                                                <label htmlFor="acc-no">International Bank Account Number (IBAN)</label>
                                                <input id="acc-no" type="text" placeholder="IBAN"
                                                    {...register("iban")}
                                                />
                                                {errors?.iban?.message && <span className="error-msg">{errors?.iban?.message}</span>}
                                            </span>
                                        </>
                                    }

                                    {
                                        wacthCountryID && parseInt(wacthCountryID) === 1 &&
                                        <>
                                            <span className={classNames("input", { error: errors?.bsb?.message })}>
                                                <label htmlFor="bsb-no">BSB Number</label>
                                                <input id="bsb-no" type="text" placeholder="BSB"
                                                    {...register("bsb")} />
                                                {errors?.bsb?.message && <span className="error-msg">{errors?.bsb?.message}</span>}
                                            </span>

                                            <span className={classNames("input", { error: errors?.accountNumber?.message })}>
                                                <label htmlFor="acc-no">Account Number</label>
                                                <input id="acc-no" type="text" placeholder="Account Number"
                                                    {...register("accountNumber")}
                                                />
                                                {errors?.accountNumber?.message && <span className="error-msg">{errors?.accountNumber?.message}</span>}
                                            </span>
                                        </>
                                    }
                                </span>
                            }
                        </p>
                        <p className="error-msg">Please select option</p>
                    </div>
                </fieldset>
            </div>
            <fieldset className="submit">
                <p className="btn green link">
                    <ButtonWithSpinner text={saveText} isLoading={isSubmitting} />
                </p>
            </fieldset>
        </form>

    );

}

export default RegStepTwo;
import * as Yup from 'yup';
import valUtils from "../validations";

const validationSchema = Yup.object().shape({
    affiliateName: valUtils.affiliateName(),
    email: valUtils.email(), 
    showWebsite: Yup.bool(),
    website: Yup.string().when(['showWebsite'], {
            is: (show) => show,
            then: Yup.string().required('Url required').url("Invalid url"),
            otherwise: Yup.string().notRequired().nullable()
    }),
    showInstagram: Yup.bool(),
    instagram: Yup.string().when(['showInstagram'], {
        is: (show) => show,
        then: Yup.string().required('Url required').url("Invalid url"),
        otherwise: Yup.string().notRequired().nullable()
    }),
    showTiktok: Yup.bool(),
    tiktok: Yup.string().when(['showTiktok'], {
        is: (show) => show,
        then: Yup.string().required('Url required').url("Invalid url"),
        otherwise: Yup.string().notRequired().nullable()
    }),
    showYoutube: Yup.bool(),
    youtube: Yup.string().when(['showYoutube'], {
        is: (show) => show,
        then: Yup.string().required('Url required').url("Invalid url"),
        otherwise: Yup.string().notRequired().nullable()
    }),
    showFacebook: Yup.bool(),
    facebook: Yup.string().when(['showFacebook'], {
        is: (show) => show,
        then: Yup.string().required('Url required').url("Invalid url"),
        otherwise: Yup.string().notRequired().nullable()
    }),
    showOnlyfans: Yup.bool(),
    onlyfans: Yup.string().when(['showOnlyfans'], {
        is: (show) => show,
        then: Yup.string().required('Url required').url("Invalid url"),
        otherwise: Yup.string().notRequired().nullable()
    }),
    showOther: Yup.bool(),
    other: Yup.string().when(['showOther'], {
        is: (show) => show,
        then: Yup.string().required('Required'),
        otherwise: Yup.string().notRequired().nullable()
    }),
    promoteChannels: Yup.string().test("isPromoteChannelsValid", "Require at least one promote channel", function(val){
        const {showWebsite, showInstagram, showTiktok, showYoutube, showFacebook, showOnlyfans, showOther} = this.parent;
        return (showWebsite || showInstagram || showTiktok || showYoutube || showFacebook || showOnlyfans || showOther)
    })    
});

export default validationSchema;

import axios from "axios";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import axiosRetry from 'axios-retry';
import auth from '../utils/auth';

const axiosApiInstance = axios.create();

// Function that will be called to refresh authorization
const refreshAuthLogic = failedRequest => {
    // console.log("failedRequest", failedRequest)
    return axios
        .post(`${auth.getRefreshTokenUrl()}`, auth.getRefreshTokenBody())
        .then(tokenRefreshResponse => {
            const {AccessToken} = tokenRefreshResponse.data;
            auth.storeBearerToken(AccessToken);
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + AccessToken;
            return Promise.resolve();
        }, ()=>{
            auth.clearTokens();
            throw new Error("refresh token expired")
        }).catch((err)=>{
            auth.clearTokens();
            throw new Error(err);
        });
        // .finally(()=>console.log("finally"));
}
// Instantiate the interceptor (you can chain it as it returns the axios instance)
createAuthRefreshInterceptor(axiosApiInstance, refreshAuthLogic);

axiosRetry(axiosApiInstance, {retries: 5});

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json"
}

const apiRequest = (action)=>{
    const {
        url,
        method,
        data,
        headers,
      } = action;
      const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
      const theData = data;
      
      const accessToken = auth.getAccessToken();
      if(accessToken)
        axiosApiInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    return axiosApiInstance.request({
        url,
        method,
        headers : {
            ...defaultHeaders,
            ...headers
        },
        [dataOrParams]: theData
    });
}

export const apiRequestCustomApiToken = (action)=>{
    const {
        url,
        method,
        data,
        headers,
      } = action;
      const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
      const theData = data;
      const accessToken = auth.getCustomToken();
      if(accessToken)
        axios.defaults.headers.common["Authorization"] = `${accessToken}`;

    return axios.request({
        url,
        method,
        headers : {
            ...defaultHeaders,
            ...headers
        },
        [dataOrParams]: theData
    });
}

export const apiRequestNoInterceptor = (action)=>{
    const {
        url,
        method,
        data,
        headers,
      } = action;
      const dataOrParams = ["GET", "DELETE"].includes(method) ? "params" : "data";
      const theData = data;
      const accessToken = auth.getAccessToken();
      if(accessToken)
        axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

    return axios.request({
        url,
        method,
        headers : {
            ...defaultHeaders,
            ...headers
        },
        [dataOrParams]: theData
    });
}

export default apiRequest;


// import banner640x100JPG from "assets/img/banners/640x100.jpg";
// import banner728x90JPG from "assets/img/banners/728x90.jpg";
// import banner1456x180JPG from "assets/img/banners/1456x180.jpg";
// import banner320x1200JPG from "assets/img/banners/320x1200.jpg";
// import banner300x600JPG from "assets/img/banners/300x600.jpg";
// import banner600x1200JPG from "assets/img/banners/600x1200.jpg";
// import banner300x250JPG from "assets/img/banners/300x250.jpg";
// import banner600x500JPG from "assets/img/banners/600x500.jpg";
// import banner160x600JPG from "assets/img/banners/160x600.jpg";
// import banner320x50GIF from "assets/img/banners/320x50.gif";
// import banner728x90GIF from "assets/img/banners/728x90.gif";
// import banner1456x180GIF from "assets/img/banners/1456x180.gif";
// import banner300x600GIF from "assets/img/banners/300x600.gif";
// import banner600x1200GIF from "assets/img/banners/600x1200.gif";
// import banner160x600GIF from "assets/img/banners/160x600.gif";
// import banner320x1200GIF from "assets/img/banners/320x1200.gif";
// import banner300x250GIF from "assets/img/banners/300x250.gif";
// import banner600x500GIF from "assets/img/banners/600x500.gif";

export const dating = [
    {
        name : 'banner 640x100 JPG',
        //img : banner640x100JPG,
        url: 'https://redhotpie.com.au/image/banners/dating/640x100.jpg',
    },
    {
        name : 'banner 728x90 JPG',
        //img : banner728x90JPG
        url: 'https://redhotpie.com.au/image/banners/dating/728x90.jpg',
    },
    {
        name : 'banner 1456x180 JPG',
        //img : banner1456x180JPG
        url: 'https://redhotpie.com.au/image/banners/dating/1456x180.jpg',
    },
    {
        name : 'banner 320x1200 JPG',
        //img : banner320x1200JPG
        url : 'https://redhotpie.com.au/image/banners/dating/320x1200.jpg',
    },
    {
        name : 'banner 300x600 JPG',
        //img : banner300x600JPG,
        url: 'https://redhotpie.com.au/image/banners/dating/300x600.jpg'
    },
    {
        name : 'banner 600x1200 JPG',
        //img : banner600x1200JPG
        url : 'https://redhotpie.com.au/image/banners/dating/600x1200.jpg'
    },
    {
        name : 'banner 300x250 JPG',
        //img: banner300x250JPG
        url: 'https://redhotpie.com.au/image/banners/dating/300x250.jpg'
    },
    {
        name : 'banner 600x500 JPG',
        //img : banner600x500JPG
        url: 'https://redhotpie.com.au/image/banners/dating/600x500.jpg'
    },
    {
        name : 'banner 160x600 JPG',
        //img : banner160x600JPG
        url: 'https://redhotpie.com.au/image/banners/dating/160x600.jpg'
    },
    {
        name : 'banner 320x50 GIF',
        //img : banner320x50GIF
        url: 'https://redhotpie.com.au/image/banners/dating/320x50.gif'
    },

    {
        name : 'banner 728x90 GIF',
        //img : banner728x90GIF
        url: 'https://redhotpie.com.au/image/banners/dating/728x90.gif'
    },
    {
        name : 'banner 1456x180 GIF',
        //img : banner1456x180GIF
        url: 'https://redhotpie.com.au/image/banners/dating/1456x180.gif'
    },
    {
        name : 'banner 300x600 GIF',
        //img : banner300x600GIF
        url: 'https://redhotpie.com.au/image/banners/dating/300x600.gif'
    },
    {
        name : 'banner 600x1200 GIF',
        //img : banner600x1200GIF
        url: 'https://redhotpie.com.au/image/banners/dating/600x1200.gif'
    },
    {
        name : 'banner 160x600 GIF',
        //img : banner160x600GIF
        url: 'https://redhotpie.com.au/image/banners/dating/160x600.gif'
    },
    {
        name : 'banner 320x1200 GIF',
        //img : banner320x1200GIF
        url: 'https://redhotpie.com.au/image/banners/dating/320x1200.gif'
    },
    {
        name : 'banner 300x250 GIF',
        //img : banner300x250GIF
        url: 'https://redhotpie.com.au/image/banners/dating/300x250.gif'
    },
    {
        name : 'banner 600x500 GIF',
        //img : banner600x500GIF
        url: 'https://redhotpie.com.au/image/banners/dating/600x500.gif'
    }
];

export const hookups = {

};

export const swingers = [
    {
        name: "SwingersBoard 728x200 GIF",
        url: "https://redhotpie.com.au/image/banners/swingers/SwingersBoard-728x200.gif"
    },
    {
        name: "SwingersBoard 728x200 JPG",
        url: "https://redhotpie.com.au/image/banners/swingers/SwingersBoard-728x200.jpg"
    },
    {
        name: "SwingersBoard 300x250 JPG",
        url: "https://redhotpie.com.au/image/banners/swingers/SwingersBoard-300x250.jpg"
    },
    {
        name: "SwingersBoard 300x250 GIF",
        url: "https://redhotpie.com.au/image/banners/swingers/SwingersBoard-300x250.jpg"
    }
];

export const fetish = {

};

export const logo = {

};
import SupportForm from "components/ContactSupportForm";
import useToast from "hooks/useToast";
import LoggedInLayout from 'layouts/LoggedInPlainLayout';
import auth from 'utils/auth';

const ContactSupport = (props) => {
    const toast = useToast()
    const {history} = props;

    const goBack = ()=> history.goBack();

    const postCreateNewTicket = ()=>{
        toast.success("Succesfully create new ticket");
        history.goBack();
        
    }

    const isLoggedInAuth = auth.isLoggedIn();

    return (
        isLoggedInAuth
        ? <LoggedInLayout {...props}>
            <SupportForm showBackBtn={true} onBackClicked={goBack} postProcFunc={postCreateNewTicket} />
            </LoggedInLayout>
        : <SupportForm showBackBtn={true} onBackClicked={goBack} postProcFunc={postCreateNewTicket} />
    );
        
}

export default ContactSupport;


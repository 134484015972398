import React, { useEffect, useState, useRef } from 'react';
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS } from 'configs/page';
import { useParams } from 'react-router';
import useCampaigns from 'hooks/useCampaigns';
import classNames from 'classnames';
import useLookup from 'hooks/useLookup';
import useToast from 'hooks/useToast';
import moment from 'moment';
import { numberFormatter } from 'utils/formatter';
import { PAGE_NAV } from 'configs/page';
import Stats from 'components/Stats';
import ConfirmPopup from 'components/modals/ConfirmPopup';

const CampaignDetails = (props) => {

    const { history } = props;
    const toast = useToast();
    const lookupProvider = useLookup();
    const campaignProvider = useCampaigns();
    const [leftMenu, setLeftMenu] = useLeftMenu();
    let { campaignID: campaignDetailID } = useParams();
    const {
        dateRangeID,
        setDateRangeID,
        fetchCampaigns,
        campaignsSummaryLoadable,
        // campaignDetailID,
        // setCampaignDetailID,
        campaignDetailLoadable,
        fetchCampaign,
        deleteCampaign

    } = campaignProvider;
    const { state: campaignDetailState, contents: campaignDetailContent } = campaignDetailLoadable;
    const { state: campaignListState, contents: campaignListContent } = campaignsSummaryLoadable;
    const { statDates } = lookupProvider;
    const [campaignList, setCampaignList] = useState();
    const [dateSelDropdownOn, setDateSelDropdownOn] = useState(false);
    const [selectedDate, setSelectedDate] = useState("");
    const [ddlSelCampaign, setDdlSelCampaign] = useState(false);
    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [isFetchingCampaign, setIsFetchingCampaign] = useState(false);
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])

    //setup menu
    useEffect(() => {
        if (leftMenu.value !== "CAMPAIGNS")
            mountedRef.current  && setLeftMenu(MENUS.find(menu => menu.value === "CAMPAIGNS"));
    }, [leftMenu, setLeftMenu]);

    useEffect(() => {
        if (isFetchingCampaign) return;
        if (!campaignDetailID) return;
        if(isNaN(campaignDetailID)) return;
        if(parseInt(campaignDetailID) < 1) return;
        if(campaignDetailState !== "hasValue") return;
        if (campaignDetailContent?.CampaignDetail?.CampaignID === parseInt(campaignDetailID)) return;
                    
        mountedRef.current && setIsFetchingCampaign(true);
        fetchCampaign(campaignDetailID, dateRangeID)
            .finally(() => mountedRef.current && setIsFetchingCampaign(false));
    }, [campaignDetailID, campaignDetailState, campaignDetailContent, dateRangeID, fetchCampaign, isFetchingCampaign]);

    useEffect(() => {
        switch (campaignDetailState) {
            case "hasValue":
                if (campaignDetailContent)
                    setCampaignList(campaignDetailContent);
                break;
            case "hasError":
                toast.error(campaignDetailContent);
                setCampaignList();
                break;
            case "loading":
                setCampaignList();
                break;
            default:

                break;

        }
    }, [campaignDetailState, campaignDetailContent, toast]);

    useEffect(() => {
        if (!campaignDetailID) return;
        if(isNaN(campaignDetailID)) return;
        if (!selectedDate && statDates.length > 0) {
            const selDate = statDates.find(dt => dt.ItemID === dateRangeID);
            if (selDate) 
                mountedRef.current && setSelectedDate(selDate.ItemName);
        }
    }, [selectedDate, setSelectedDate, statDates, dateRangeID, campaignDetailID])

    useEffect(() => {
        if (!campaignDetailID) return;
        if(isNaN(campaignDetailID)) return;

        if (campaignListState === "hasValue"
            && campaignListContent
            && campaignListContent.StatsSummaries
            && campaignListContent.StatsSummaries.length > 0
            && campaignDetailID > 0
        ) {
            const selDate = campaignListContent.StatsSummaries
                .find(dt => dt.CampaignDetail.CampaignID === parseInt(campaignDetailID));
            
            if (selDate && selDate.CampaignDetail.Title !== selectedCampaign) {
                    mountedRef.current && setSelectedCampaign(selDate.CampaignDetail.Title)
            };
        }
    }, [selectedCampaign, setSelectedCampaign, campaignListState, campaignListContent, campaignDetailID])
    
    const [campaignNames, setCampaignNames] = useState([]);

    useEffect(() => {
        
        if (!campaignDetailID) return;
        if(isNaN(campaignDetailID)) return;
        if (campaignNames && campaignNames.length > 0) return;
        if (campaignListState === "hasValue" &&
            campaignListContent
            && campaignListContent.StatsSummaries
            && campaignListContent.StatsSummaries.length > 0) {
                mountedRef.current &&  
                setCampaignNames([...campaignListContent.StatsSummaries]
                    .sort((a, b) => a.CampaignDetail.Title.toLowerCase() > b.CampaignDetail.Title.toLowerCase() ? 1 : -1));
        }
    }, [campaignListState, campaignListContent, campaignNames, setCampaignNames, campaignDetailID]);

    const [traffic, setTraffic] = useState(0);
    const [join, setJoin] = useState(0);
    const [sales, setSales] = useState(0)

    useEffect(() => {
        if (!campaignDetailID) return;
        if(isNaN(campaignDetailID)) return;
        if (campaignList
            && campaignList.Stats
            && campaignList.Stats.length > 0) {
                mountedRef.current && setTraffic(campaignList.Stats.reduce((acc, curr) => acc + curr.Traffic, 0));
                mountedRef.current && setJoin(campaignList.Stats.reduce((acc, curr) => acc + curr.Joins, 0));
                mountedRef.current && setSales(campaignList.Stats.reduce((acc, curr) => acc + curr.Upgrades, 0));
        }
        else {
            mountedRef.current && setTraffic(0);
            mountedRef.current && setJoin(0);
            mountedRef.current && setSales(0);
        }
    }, [campaignList, traffic, setTraffic, join, setJoin, sales, setSales, campaignDetailID])

    useEffect(() => {
        if(!campaignDetailID || isNaN(campaignDetailID)){
            history.push(PAGE_NAV.CAMPAIGNS);
            return;
        }
        if (campaignDetailID > 0 
            && campaignListState === "hasValue" 
            && campaignListContent
            && campaignListContent.StatsSummaries
            && campaignListContent.StatsSummaries.length > 0) {
                
            const selDate = campaignListContent?.StatsSummaries
                .find(dt => dt.CampaignDetail.CampaignID === parseInt(campaignDetailID));
        
            if(!selDate){
                history.push(PAGE_NAV.CAMPAIGNS);
                return;
            }
        }
        
    }, [campaignListState, campaignListContent, campaignDetailID, history]);

    const updateSelDate = (date, e) => {
        e.preventDefault();
        setDateRangeID(date.ItemID);
        setSelectedDate(date.ItemName);
        fetchCampaigns(date.ItemID, campaignListContent)
        .then(() => {  
            fetchCampaign(campaignDetailID, date.ItemID) 
        })
        setDateSelDropdownOn(false);
    }

    const updateSelectedCampaign = (campaign, e) => {
        e.preventDefault();
        setDdlSelCampaign(false);
        history.push(`${PAGE_NAV.CAMPAIGNS}/${campaign.CampaignDetail.CampaignID}`);
    }

    const [delConfirmPopup, setDelConfirmPopup] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onDeleteConfirmed = ()=>{
        if(isSubmitting) return;
        if(!campaignDetailID || isNaN(campaignDetailID) || parseInt(campaignDetailID)< 1){
            return;
        }
        
        if(mountedRef.current) {
            setIsSubmitting(true);
            deleteCampaign(campaignDetailID)
            .then(()=>{
                if(mountedRef.current) {
                    setDelConfirmPopup(false);
                    setIsSubmitting(false);
                    history.push(PAGE_NAV.CAMPAIGNS);
                }
            }, (err)=>{
                toast.error("Failed to remove campaign", err);
            })
            .finally(()=>{ mountedRef.current && setIsSubmitting(false);})
        };
    }

    const copyLink = useRef(null);
    const copyLinkToClipboard = () => {
        copyLink.current.select();
        document.execCommand('copy');
    }

    return (
        <div onClick={()=>{setDdlSelCampaign(false); setDateSelDropdownOn(false);}}>
        <LoggedInLayout {...props}>

            <Stats
                statDates={statDates}
                selectedDate={selectedDate}
                dateSelDropdownOn={dateSelDropdownOn}
                setDateSelDropdownOn={setDateSelDropdownOn}
                updateSelDate={(date, e) => updateSelDate(date, e)}

                campaignNames={campaignNames}
                selectedCampaign={selectedCampaign}
                campaignSelDropdownOn={ddlSelCampaign}
                setCampaignSelDropdownOn={setDdlSelCampaign}
                updateSelCampaign={(campaign, e) => updateSelectedCampaign(campaign, e)}

                traffic={traffic}
                joins={join}
                upgrades={sales}
            />

            <div className="generated-link">
                <h2>Your generated link is:</h2>
                <div className="copy-link">
                    <input ref={copyLink} type="text" readOnly={true} value={campaignList?.CampaignDetail?.LandingPage ? campaignList?.CampaignDetail?.LandingPage : ''} />
                    <p className="btn green"><button onClick={copyLinkToClipboard}>Copy</button></p>
                </div>
                <p>Use this link with any content you post to direct traffic to the landing page</p>
            </div>

            <div className="table-container">
                <table className="compaign-detail-table">
                    <thead className={classNames("thead")}>
                        <tr>
                            <th>Date</th>
                            <th>Clicks</th>
                            <th>Joins</th>
                            <th>Sales</th>
                            {/* <th>Earnings</th> */}
                        </tr>
                    </thead>
                    <tbody>
    
                        {
                            campaignList
                            && campaignList.Stats
                            && campaignList.Stats.length > 0
                            && [...campaignList.Stats]
                                .sort((a, b) => a.Date > b.Date ? 1 : -1)
                                .map((campaign, i) => (
                                    <tr key={`campaign_detail_${i}`}>
                                        <td>{moment(campaign.Date).format("DD/MM/YYYY")}</td>
                                        <td>{numberFormatter.format(campaign.Traffic)}</td>
                                        <td>{numberFormatter.format(campaign.Joins)} {campaign.Traffic > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Joins * 100 / campaign.Traffic)}%)` : ``}</td>
                                        <td>{numberFormatter.format(campaign.Upgrades)} {campaign.Upgrades > 0 && campaign.Joins > 0 ? `(${Math.round(campaign.Upgrades * 100 / campaign.Joins)}%)` : ``}</td>
                                    </tr>
                                ))
                        }
                        {
                            campaignList
                            && campaignList.Stats
                            && !campaignList.Stats.length > 0 &&
                            <tr>
                                <td colspan="4" style={{padding: "50px 0", textAlign: "center"}}>Your campaign activity will appear here</td>
                            </tr>
                        }
                    </tbody>
                </table>
                <p className="btn"><button onClick={(e)=>{e.preventDefault();setDelConfirmPopup(true)}}>Delete campaign</button></p>
            </div>


            <ConfirmPopup open={delConfirmPopup} 
                close={()=>setDelConfirmPopup(false)}
                confirm={onDeleteConfirmed}
                heading="Delete campaign" 
                btnText="Delete campaign">
                    <p>Are you sure you want to delete this campaign: {selectedCampaign}?</p>
            </ConfirmPopup>
        </LoggedInLayout>
        </div>
    );
}

export default CampaignDetails;
import {  useRecoilValueLoadable, useRecoilState, useRecoilCallback, useSetRecoilState } from "recoil";
import campaignDateRangeIDAtom from "recoil/stats/campaignDateRangeID";
import campaignsAtom from 'recoil/stats/campaigns';
import campaignDetailsIDAtom from 'recoil/stats/campaignDetailID';
import campaignDetailsAtom from 'recoil/stats/campaignDetail';
import useStatsService from 'services/stats';
import useCampaignService from "services/campaign";
import useToast from "./useToast";
import { CAMPAIGN_ID_NOTFOUND } from "configs/constants";
import { useEffect, useRef, useState } from "react";

const useCampaigns = ()=>{
    const campaignService = useCampaignService();
    const statsService = useStatsService();
    const toast = useToast();
    const campaignsSummaryLoadable = useRecoilValueLoadable(campaignsAtom);
    const setCampaignsSummary = useSetRecoilState(campaignsAtom);
    const [dateRangeID, setDateRangeID] = useRecoilState(campaignDateRangeIDAtom);
    const [campaignDetailID, setCampaignDetailID] = useRecoilState(campaignDetailsIDAtom);
    const campaignDetailLoadable = useRecoilValueLoadable(campaignDetailsAtom);
    // const setCampaignDetail = useSetRecoilState(campaignDetailsAtom);
    const { state: campaignListState, contents: campaignListContent } = campaignsSummaryLoadable;
    
    const fetchCampaigns = useRecoilCallback(({ set }) => async (id, current) => {
        if(current && parseInt(current?.DateRangeItemID) === parseInt(id)){
            return;
        }
        
        return statsService.getCampaign(id)
        .then(response=>{
            set(campaignsAtom, response.data);
        })
        .catch(err=>{toast.error(err)});
    });

    const fetchCampaign = useRecoilCallback(({ set }) => async (campaignID, dateID) => {
        if(campaignID < 1) {
            set(campaignDetailsAtom, null);
            return;
        };
        if(campaignListState !== "hasValue") return;
        if(!campaignListContent) return;
        
        const selDate = campaignListContent?.StatsSummaries
                .find(dt => dt.CampaignDetail.CampaignID === parseInt(campaignID));
        
        if(!selDate){
            set(campaignDetailsAtom, null);
            set(campaignDetailsIDAtom, CAMPAIGN_ID_NOTFOUND);
            return;
        }
        return statsService.getCampaignDetails({ CampaignID: campaignID, DateRangeItemID: dateID })
            .then(response => set(campaignDetailsAtom, response.data))
            .catch(err => toast.error(err));
    });

    const addNewCampaign = async(data)=>{
         return campaignService.createCampaign(data)
                .then((response)=>{
                    //add to campaigns
                    setCampaignsSummary(prev=>({
                        ...prev,
                        StatsSummaries : [
                            ...prev.StatsSummaries,
                            {
                                CampaignDetail : response.data,
                                Stats : {
                                    Traffic :0,
                                    Joins :0,
                                    Upgrades: 0,
                                    Revenue: 0
                                }
                            }
                        ]
                    }));
                    return response.data.CampaignID;
                }, (err)=>{
                    // toast.error("Failed to add new campaign", err);
                    throw new Error(err);
                });
    }

    const deleteCampaign = (id)=>{
        return campaignService.hideCampaign(id)
                .then((response)=>{
                    const {Result} = response.data;

                    if(!Result){
                        // toast.error("Failed to remove this campaign");
                        throw new Error("Failed to remove this campaign");
                    }

                    //remove from summary
                    setCampaignsSummary(prev=>({
                        ...prev,
                        StatsSummaries: prev.StatsSummaries.filter(s=>s.CampaignDetail.CampaignID !== parseInt(id)),
                    }));

                    return true;
                },
                (err)=>{
                    //toast.error("Failed to remove campaign", err);
                    throw new Error(err);
                });
    };

     
    const [isFetchingCampaigns, setIsFetchingCampaigns] = useState(false)
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])

    useEffect(()=>{
        if(isFetchingCampaigns) return;
        if(campaignListState !== "hasValue") return;
        if(campaignListContent) return;
        mountedRef.current && setIsFetchingCampaigns(true);
        fetchCampaigns(dateRangeID).then(()=>{
            mountedRef.current && setIsFetchingCampaigns(false)
        });
    }, [campaignListState, campaignListContent, fetchCampaigns, dateRangeID, isFetchingCampaigns, setIsFetchingCampaigns])

    return {
        dateRangeID, 
        setDateRangeID,
        campaignsSummaryLoadable,
        fetchCampaigns,
        campaignDetailID, 
        setCampaignDetailID,
        campaignDetailLoadable,
        fetchCampaign,
        addNewCampaign,
        deleteCampaign
    };
}

export default useCampaigns;
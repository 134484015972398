import moment from "moment"

export const generateEarningsYearOptions = (dateJoined, getMaxYears=3) =>{
    const joinedYear = moment(dateJoined).year();
    let maxYear = moment().year();
    const minYear = maxYear - getMaxYears + 1;
    const endYear = joinedYear > minYear ? joinedYear : minYear;
    let years = [];
    while (maxYear >= endYear) {
        years.push(maxYear);
        maxYear--;
    
    }
    
    return years;
}


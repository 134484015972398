import React from 'react';
import { Modal } from 'react-responsive-modal';

import ContactSupport from 'components/ContactSupportForm';
import ContactSupportLogout from 'components/ContactSupportFormLogout';

const ContactSupportPopup = (props) => {
    const {open, close, postProcFunc, loggedin=true } = props;

    return (
        <Modal classNames={{ overlay: 'contact-support-overlay', modal: 'contact-support-modal',}} 
            open={open} 
            onClose={close}
            showCloseIcon={false}
            center>
            {
                loggedin ?
                <ContactSupport isOpenInPopup={true} onClose={close} postProcFunc={postProcFunc} />
                :
                <ContactSupportLogout isOpenInPopup={true} onClose={close} postProcFunc={postProcFunc} />
            }


        </Modal>
    );
}

export default ContactSupportPopup;
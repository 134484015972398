const Keys = {
    Lookup :  {
        Country: "categoryLookupAtom",
        Currency:  "currencyLookupAtom",
        StatDates: "statDatesLookupAtom",
        LandingPages: "landingPagesLookupAtom",
        AffiliatePrograms:  "AffiliateProgramsLookupAtom",
    },
    Account : {
        Auth: "authTokensAtom",
        AccountDetails: "accountDetailsAtom",
        Profiles: "accountProfilesAtom",
        PhoneDetails: "accountPhoneDetailsAtom",
        PaymentInfo: "accountPaymentInfoAtom",
        BankDetails: "accountBankDetailsAtom",
        BillingInfo: "accountBillingInfoAtom",
        PromoteChannels: "accountPromoteChannelsAtom",
        RegStep: "accountRegStepAtom"

    },  
    Nav: {
        LeftMenu: "navLeftMenuAtom"
    },
    Statistic : {
        Campaigns: "statisticCampaignsAtom",
        CampaignDateRangeID : "statisticCampaignDateRangeIDAtom",
        EarningsSummary : "statisticEarningsSummaryAtom",
        CampaignDetails: "statisticCampaignDetailsAtom",
        CampaignDetailsID: "statisticCampaignDetailsIDAtom",

    },
    Common : {
        ErrPopup: "commonErrPopupAtom"
    },
    Support : {
        TicketList: "supportTicketListAtom",
        TicketListSearchCriteria:  "supportTicketListSearchCriteriaAtom",
        TicketDetails: "supportTicketDetailsAtom"
    }
}

export default Keys;
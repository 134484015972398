import React from 'react';
import aqLogo from "../assets/img/aq-logo-black.png";
import { Link } from 'react-router-dom';
import {PAGE_NAV} from "configs/page";

const LoginHeader = () => {
    return (
            <div className="header">
                <p className="page-logo"><Link to={PAGE_NAV.HOME} title="affiliate-quarter.frootdigital.com"><img src={aqLogo} alt="Affiliate quarter" /></Link></p>
            </div>
    );
}

export default LoginHeader;
import axios from 'axios';
import  {API_HOST} from "configs/api";

const defaultHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json"
};

const privateFetch = axios.create({
    baseURL: API_HOST,
    headers: {...defaultHeaders},
});

export default privateFetch;
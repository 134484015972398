import {atom} from "recoil";
import keys from "configs/atoms";
import {REFRESHTOKEN_KEY, ACCESSTOKEN_KEY} from "configs/api";

const initValue = {
    isAuthenticated: localStorage.getItem(REFRESHTOKEN_KEY) && localStorage.getItem(ACCESSTOKEN_KEY) ? true : false, 
    isLoggedIn: false, 
    accountLoaded: false, 
    redirToLogout: false,
    isRefreshTokenExpired: false
}

const auth = atom(({
    key: keys.Account.Auth,
    default: initValue 
}));

export default auth;
import React, {  useState } from 'react';
import classNames from 'classnames';
import LeftMenu from 'navigations/leftMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faCaretDown, faCaretUp, faUser } from "@fortawesome/free-solid-svg-icons"
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS, PAGE_NAV } from 'configs/page';
// import { Redirect } from 'react-router-dom';
// import useLogout from 'hooks/useLogout';
import useFullRegOnly from "hooks/useFullRegOnly";
// import useAuth from 'hooks/useAuth';
// import useAuthProvider from 'hooks/useAuthProvider';
import Footer from "components/Footer";

const LoggedInDisplay  = (props) => {
    const { children, history, pageTitle } = props;

    const [leftMenu] = useLeftMenu();
    
    // const logoutProvider = useLogout();
    const [mobileNavOn, setMobileNavOn] = useState(false);
    const [accNavOn, setAccNavOn] = useState(false);
    const [, setDateSelDropdownOn] = useState(false);

    const onMenuClicked = (e, menu) => {
        e.preventDefault();
        // if (menu.value === "LOGOUT") {
        //     logoutProvider().then(() => { history.push(PAGE_NAV.LOGIN) });
        //     return;
        // }
        history.push(PAGE_NAV[menu.value]);
    }

    return (
        <>
            {
               useFullRegOnly(
                    <div className={classNames("main", leftMenu.value.toLowerCase(), "affiliate")} onClick={() => { setMobileNavOn(false); setAccNavOn(false); setDateSelDropdownOn(false); }}>
                        <div className="main-content">
                            <LeftMenu on={mobileNavOn} {...props} />
                            <div className={classNames("section", "content")} >
                                <div className="section-header">
                                    <div className="page-title">
                                        <p className="mobile-nav-btn">
                                            <a href="/">
                                                <FontAwesomeIcon icon={faBars} onClick={(e) => { e.preventDefault(); setMobileNavOn(!mobileNavOn); e.stopPropagation() }} />
                                            </a>
                                        </p>
                                        <h1>{!pageTitle ? leftMenu.text : pageTitle}</h1>
                                        <div className="user">
                                            <div className="avatar">
                                                <a href="/" onClick={(e) => { e.preventDefault(); setAccNavOn(!accNavOn); e.stopPropagation() }}>
                                                    <FontAwesomeIcon icon={faUser} />
                                                </a>
                                            </div>
                                            <FontAwesomeIcon icon={accNavOn ? faCaretUp : faCaretDown} onClick={(e) => { setAccNavOn(!accNavOn); e.stopPropagation() }} />
                                            <div className={classNames("account-nav", { "on": accNavOn })}>
                                                <ul>
                                                    {
                                                        MENUS.filter(menu => menu.positions.includes("account")).map((menu, i) => <li key={`menu_${i}`}><a href={PAGE_NAV[menu.value]} onClick={(e) => { onMenuClicked(e, menu) }} title={menu.text}><img alt={menu.text} src={menu.icon} title={menu.text} /> <span>{menu.text}</span></a></li>)
                                                    }
                                                    {/* <li><a href="/" title="My account"><img alt={"My account"} src={userIco} title="My account" /> <span>My account</span></a></li>
            <div className={classNames("main", leftMenu.value.toLowerCase(), "affiliate")}>
                <LeftMenu on={mobileNavOn} {...props}  />
                <div className={classNames("section", "content")} onClick={()=>{setMobileNavOn(false); setAccNavOn(false); setDateSelDropdownOn(false);}}>
                    <div className="section-header">
                        <div className="page-title">
                            <p className="mobile-nav-btn">
                                <a href="/">
                                    <FontAwesomeIcon icon={faBars} onClick={(e)=>{e.preventDefault();setMobileNavOn(!mobileNavOn);e.stopPropagation()}} />
                                </a>
                            </p>
                            <h1>{leftMenu.text}</h1>
                            <div className="user">
                                <div className="avatar">
                                    <a href="/" onClick={(e)=>{e.preventDefault();setAccNavOn(!accNavOn);e.stopPropagation()}}>
                                        <img src="https://digitalquarter.com/wp-content/uploads/2017/04/ProfileMark.jpg" alt="name of the member" />
                                    </a>
                                </div>
                                <FontAwesomeIcon icon={accNavOn ? faCaretUp : faCaretDown} onClick={(e)=>{setAccNavOn(!accNavOn);e.stopPropagation()}} />
                                <div className={classNames("account-nav", {"on": accNavOn})}>
                                <ul>
                                    {
                                        MENUS.filter(menu=>menu.positions.includes("account")).map((menu, i)=><li key={`menu_${i}`}><a href={PAGE_NAV[menu.value]} onClick={(e)=>{onMenuClicked(e, menu)}} title={menu.text}><img alt={menu.text} src={menu.icon} title={menu.text} /> <span>{menu.text}</span></a></li>)
                                    }
                                    {/* <li><a href="/" title="My account"><img alt={"My account"} src={userIco} title="My account" /> <span>My account</span></a></li>
                                    <li><a href="/" title="Support"><img alt={"Support"} src={SupportDarktIco} title="Support" /> <span>Support</span></a></li>
                                    <li><a href="/" title="Logout"><img alt={"Logout"} src={LogoutDarkIco} title="Log out" /> <span>Logout</span></a></li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {children}
                            </div>
                        </div>
                        <Footer loggedin={true} />
                    </div>)
            }
        </>
    );
}

const LoggedInLayout = (props) => {
    return (
        <>              
            <LoggedInDisplay {...props} />
        </>
    );
}

export default LoggedInLayout;
import React,{ useState, useEffect } from 'react';
import classNames from "classnames";
import LoginHeader from "../components/LoginHeader";
import MobileVerify from '../components/modals/PhoneVerification';
import { Link } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import PhoneCountryCodePopup from 'components/modals/PhoneCountryCodePopup';
import valUtils from "utils/validations";
import { allCountries } from 'country-telephone-data';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import * as Yup from 'yup';
import service from "services/account";
import {PAGE_NAV} from "configs/page";
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import useToast from 'hooks/useToast';

const ForgotPassword = (props) => {
    const accService = service();
    const toast = useToast();
    const {history} = props;
    const emailForm = useForm({ mode: 'all', resolver: yupResolver(Yup.object().shape({
        email: valUtils.notUniqueEmail()
    })) });

    const phoneForm = useForm({ mode: 'all', resolver: yupResolver(Yup.object().shape({
        phoneCountryCode:  Yup.object()
            .required('Phone country code is required')
            .oneOf(allCountries, "Please select a country code from the list"),
        phoneNumber: Yup.string()
            .required("Phone number is required")
            .matches(/^\d+$/, 'Phone number should have digits only')
            .test("isPhoneNumberValid", "Invalid phone number", function(val){
                const {phoneCountryCode} = this.parent;
                if(!phoneCountryCode) return true;
                const {iso2, dialCode} = phoneCountryCode;
                return isValidPhoneNumber(`+${dialCode}${val}`, iso2.toUpperCase());
            })
    })) });

    const [countryCodeOpen, setCountryCodeOpen] = useState(false);
    
    const setPhoneCountryCode = (pcc)=>{
        phoneForm.setValue("phoneCountryCode", pcc);
        phoneForm.setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }

    useEffect(()=>{
       const pcc = allCountries.find(itm=>itm.iso2==="au");
       phoneForm.setValue("phoneCountryCode", pcc);
       phoneForm.setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }, [phoneForm])

    const [tabIndex, setTabIndex] = useState(1);
    const [emailSubmitted, setEmailSubmitted] = useState(false);
    const [phoneSubmitted, setPhoneSubmitted] = useState(false);
    const [phoneVerifyOpen, setPhoneVerifyOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phoneDetail, setPhoneDetail] = useState({PhoneNumber:'', PhoneCountryCode:'', PhoneAlpha2Code:''})
    const [affList, setAffList] = useState([]);

    const emailFormSubmitHandler = async(frmData)=>{
        if(isSubmitting) return
        setIsSubmitting(true);
        accService.requestEmailPasswordReset({EmailAddress: frmData.email})
        .then(()=>(setEmailSubmitted(true)))
        //     (resp)=>{
        //     if(resp.data.Result){
        //         history.push(`${PAGE_NAV.RESET_PWD}/${resp.data.Result}`);
        //     }
        //     else {
        //         setEmailSubmitted(true)
        //     }
        // })
        .catch((err)=>{
            toast.error("Failed on requesting password reset by email", err);
            throw err;
        })
        .finally(()=>{setIsSubmitting(false)})
    }


    const onRequestSendPasswordResetTxtMsgCode = async(detail)=>{
        const submittedData = detail ? detail : {
            PhoneNumber: phoneDetail.PhoneNumber,
            PhoneCountryCode: phoneDetail.PhoneCountryCode,
            PhoneAlpha2Code: phoneDetail.PhoneAlpha2Code
        };
        return accService.requestTextPasswordReset(submittedData)
        .catch((err)=>{
            toast.error("Failed on requesting sms code", err);
            throw err;
        });
    }

    const phoneFormSubmitHandler = async(frmData)=>{
        
        if(isSubmitting) return
        setIsSubmitting(true);

        setPhoneDetail({
            PhoneNumber: frmData.phoneNumber,
            PhoneCountryCode: frmData.phoneCountryCode.dialCode,
            PhoneAlpha2Code: frmData.phoneCountryCode.iso2
        });

        setTimeout(()=>{
            onRequestSendPasswordResetTxtMsgCode({
                PhoneNumber: frmData.phoneNumber,
                PhoneCountryCode: frmData.phoneCountryCode.dialCode,
                PhoneAlpha2Code: frmData.phoneCountryCode.iso2
            })
            .then(()=>{
                setPhoneVerifyOpen(true);
                setIsSubmitting(false);
            })
            .catch(()=>setIsSubmitting(false))
        }, 300);
    }

    const postPhoneVerificationSuccessFunc = (resp)=>{
        const {data} = resp;
        if (!data || data.length===0){
            setPhoneVerifyOpen(false);
            //display error message?
            return;
        }
        
        if(data.length === 1){
            history.push(`${PAGE_NAV.RESET_PWD}/${resp.data[0].Guid}`);
            return;
        }

        setAffList(data);
        setPhoneSubmitted(true);
        setPhoneVerifyOpen(false);

    }

    const onPhoneVerificationClicked = (code)=>{
        const submittedData = {
            PhoneNumber: phoneDetail.PhoneNumber,
            PhoneCountryCode: phoneDetail.PhoneCountryCode,
            PhoneAlpha2Code: phoneDetail.PhoneAlpha2Code,
            VerificationCode: code
        }
        return accService.validateTextPasswordResetCode(submittedData)
                .catch((err)=>{
                    toast.error("Failed on verifying sms code", err);
                    throw err;
                });
        
    }

    return (
        <div className="section login forgot-password">
            
            <LoginHeader />

            <div className="content">

                <h1>Forgot pasword?</h1>
                {
                    !emailSubmitted &&  !phoneSubmitted && 
                    <>
                    <p>Don’t worry, just enter your email address or mobile number registered with Affiliate Quarter and we will send you instructions to reset your password.</p>
                    <div className="recover-password tab">
                    <div className="tab">
                        <div className="tab-header">
                            <ul>
                                <li className={classNames({on: tabIndex === 1})} onClick={()=>setTabIndex(1)}>Email</li>
                                <li className={classNames({on: tabIndex === 2})} onClick={()=>setTabIndex(2)}>Phone number</li>
                            </ul>
                        </div>
                        <div className="tab-content">
                            <div className={classNames("content", {on: tabIndex === 1})}>
                                <form className="login-form" onSubmit={emailForm.handleSubmit(emailFormSubmitHandler)}>
                                    <fieldset className="input">
                                    <p className={classNames("email", {error: emailForm.formState.errors?.email?.message})}>
                                        <input type="email" placeholder="Enter your email"
                                            {...emailForm.register("email")}
                                        />
                                        {emailForm.formState.errors?.email?.message && <span className="error-msg">{emailForm.formState.errors?.email?.message}</span>}
                                    </p>
                                </fieldset>
                                <fieldset className="submit">
                                    <p className="btn green">
                                        <ButtonWithSpinner text="Request password reset" isLoading={isSubmitting} />
                                    </p>
                                </fieldset>
                                </form>
                            </div>
                            <div className={classNames("content", {on: tabIndex === 2})}>
                                <form className="login-form" onSubmit={phoneForm.handleSubmit(phoneFormSubmitHandler)}>
                                    <fieldset className="input">
                                    <p className={classNames("phone", {error: phoneForm.formState.errors?.phoneNumber?.message})}>
                                        <span className="field">
                                            <input type="hidden" id="hdnPhoneCountryCode" {...phoneForm.register("phoneCountryCode")} />
                                            <input className="area-code" id="area-code" type="tel" placeholder="+61" {...phoneForm.register("phoneCountryCodeDisplay")} onClick={()=>setCountryCodeOpen(true)}  readOnly />
                                            <input id="phone" className="phone" type="tel" placeholder="Enter your phone number" {...phoneForm.register("phoneNumber")} />
                                            {/* <input className="area-code" type="tel" placeholder="+61"/>
                                            <input className="phone-number" type="tel" placeholder=""/> */}
                                        </span>
                                        {phoneForm.formState.errors?.phoneNumber?.message && <span className="error-msg">{phoneForm.formState.errors?.phoneNumber?.message}</span> }
                                    </p>
                                </fieldset>
                                <fieldset className="submit">
                                    <p className="btn green"><ButtonWithSpinner text="Request password reset" isLoading={isSubmitting} /></p>
                                </fieldset>
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    </>
                }
                {
                    emailSubmitted && <div className="message email">Ok! If there’s an Affiliate Quarter account linked to this email address, then you should receive an email shortly. Please check your junk mail folder.</div>
                }
                {
                    countryCodeOpen && <PhoneCountryCodePopup  open={countryCodeOpen} close={()=>setCountryCodeOpen(false)} setCountryCode={setPhoneCountryCode} defaultValue={phoneForm.getValues("phoneCountryCode")} />
                }
                {
                    phoneSubmitted &&
                    <div className="account-names">
                        <ul>
                            {
                                affList.map((aff, i)=>
                                    <li key={i}><Link to={`${PAGE_NAV.RESET_PWD}/${aff.Guid}`}>{aff.Username}</Link></li>
                                )
                            }
                            
                        </ul>
                    </div>
                }
                <MobileVerify open={phoneVerifyOpen} 
                        close={()=>setPhoneVerifyOpen(false)} 
                        verifyFunc={()=>setPhoneVerifyOpen(false)} 
                        postProcessFunc={postPhoneVerificationSuccessFunc} 
                        onPhoneVerificationClicked={onPhoneVerificationClicked} 
                        showCloseIcon={true}
                        onRequestResendCode={onRequestSendPasswordResetTxtMsgCode}
                        />



                {/* <form className="login-form">
                    <fieldset className="input">
                        <p className="name" ><input type="text" placeholder="Name" /></p>
                        <p className="email"><input type="email" placeholder="Email"/></p>
                    </fieldset>
                    <fieldset className="submit">
                        <p className="btn green"><input type="button" value="Login" /></p>
                    </fieldset>
                    <p><a href="#" title="Click here to reset password">Forgot password?</a></p>
                    <p>Not a member? <a href="#" title="Click here to join">Join now</a></p>
                </form> */}
            </div>
            <div className="footer">
                <div className="content"><p>Powered by Digital Quarter</p></div>
            </div>
        </div>
    );
}

export default ForgotPassword;
import { atom} from "recoil";
import atomKeys from "configs/atoms";

const initState = {
    Name: "",
    Email: ""
};

const profilesAtom = atom({
    key: atomKeys.Account.Profiles,
    default: initState
});

export default profilesAtom;
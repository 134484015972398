import React, {useState} from 'react';
import { Modal } from 'react-responsive-modal';
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';

const MobileVerify = (props) => {
    const {open, close, 
        onPhoneVerificationClicked, 
        postProcessFunc,
        onRequestResendCode,
        showCloseIcon=true} = props;
    const [code, setCode] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onGetCodeClicked = async()=>{
        if(isSubmitting) return;
        setIsSubmitting(true);
        
        onRequestResendCode()
        .then(()=>{})
        .finally(()=>setIsSubmitting(false));
    }

    const  onValidateCodeClicked = async(e)=>{
        e.preventDefault();
        if(isSubmitting) return;
        if(!code) return;

        setIsSubmitting(true);

        onPhoneVerificationClicked(code)
        .then((resp)=>{
            if(postProcessFunc && typeof postProcessFunc === "function"){
                postProcessFunc(resp);
            }
            else {
                close();
            }
        })
        .finally(()=>setIsSubmitting(false));

    }

    const onKeyDownHandler = (e) => {
        if (e.keyCode === 13) {
            // e.preventDefault();
            onValidateCodeClicked(e);
        }
      };

    return (
        <Modal classNames={{ overlay: 'verification-overlay', modal: 'verification-modal',}} 
            open={open} 
            onClose={close} 
            closeOnOverlayClick={true}
            closeOnEsc={true}
            showCloseIcon={showCloseIcon}
            center>
            <h2>Verify mobile number</h2>
            <p>You should receive a text message<br /> from us in a moment.</p>
            <p className="note"><span>Please enter the verification code you receive.</span></p>
            <form className="verification-form" onKeyDown={onKeyDownHandler}>
                <fieldset className="input">
                    <p><label>Verification code</label><input className="verification-code" type="number" placeholder="Enter the verification" onChange={e => { setCode(e.target.value)}}/></p>
                    <p>Didn’t receive the text? <a href="/"  onClick={e=>{e.preventDefault();onGetCodeClicked()}} title="Click to get another code">Get another code</a></p>
                </fieldset>
                <fieldset className="submit">
                    <p className="btn green">
                        <ButtonWithSpinner text="Verify" onClick={e=>onValidateCodeClicked(e)} isLoading={isSubmitting} />
                        {/* <input type="button" value="Verify" onClick={e=>onValidateCodeClicked(e)} /> */}
                    </p>
                </fieldset>
            </form>
        </Modal>
    );
}

export default MobileVerify;
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import circlePlusIco from "assets/img/plus-circle-ico.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import useLookup from 'hooks/useLookup';
import useCampaigns from 'hooks/useCampaigns';
import ConfirmPopup from 'components/modals/ConfirmPopup';
import Stats from 'components/Stats';
import { BrowserView, MobileView } from 'react-device-detect';
import useToast from 'hooks/useToast';
import { Link } from 'react-router-dom';
import { PAGE_NAV } from 'configs/page';
import { currencyFormatter, numberFormatter } from 'utils/formatter';

const CampaignList = (props) => {
    const lookupProvider = useLookup();
    const campaignProvider = useCampaigns();
    const { statDates } = lookupProvider;
    const { dateRangeID,
        setDateRangeID,
        campaignsSummaryLoadable,
        fetchCampaigns,
        deleteCampaign
    } = campaignProvider;
    const toast = useToast();
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])
    
    const [campaignList, setCampaignList] = useState();
    const { state: campaignListState, contents: campaignListContent } = campaignsSummaryLoadable;

    useEffect(() => {
        switch (campaignListState) {
            case "hasValue":
                if(campaignListContent)
                    setCampaignList(campaignListContent);
                break;
            case "hasError":
                toast.error(campaignListContent);
                setCampaignList();
                break;
            case "loading":
                setCampaignList();
                break;
            default:

                break;
        }
    }, [campaignListState, campaignListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    const [selectedDate, setSelectedDate] = useState("");
    const updateSelDate = (date, e) => {
        e.preventDefault();
        fetchCampaigns(date.ItemID, campaignListContent);
        setDateRangeID(date.ItemID);
        setSelectedDate(date.ItemName);
        setDateSelDropdownOn(false);
    }

    const [dateSelDropdownOn, setDateSelDropdownOn] = useState(false);

    useEffect(() => {
        if (!selectedDate && statDates.length > 0) {
            const selDate = statDates.find(dt => dt.ItemID === dateRangeID);
            if (selDate)  mountedRef.current && setSelectedDate(selDate.ItemName);
        }
    }, [selectedDate, setSelectedDate, statDates, dateRangeID]);

    const [delConfirmPopup, setDelConfirmPopup] = useState(false);
    const [selCampaign, setSelCampaign] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onDelConfirmPopupClosed = ()=>{
        setSelCampaign(null);
        setDelConfirmPopup(false);
    }

    const onDelConfirmPopupConfirmed = ()=>{
        if(isSubmitting) return;
        if(!selCampaign || selCampaign?.CampaignDetail?.CampaignID < 1){
            return;
        }
        
        if(mountedRef.current) {
            setIsSubmitting(true);
            deleteCampaign( selCampaign.CampaignDetail.CampaignID)
            .then(()=>{
                if(mountedRef.current) {
                    onDelConfirmPopupClosed();
                }
            }, (err)=>{
                toast.error("Failed to remove campaign", err);
            })
            .finally(()=>{ mountedRef.current && setIsSubmitting(false);})
        };
    }

    const onDelIconClicked = (camp)=>{
        setSelCampaign(camp);
        setDelConfirmPopup(true);
    }

    return (

        <div onClick={()=>{setDateSelDropdownOn(false);}}> 
            <Stats 
                statDates={statDates}
                selectedDate={selectedDate}
                dateSelDropdownOn={dateSelDropdownOn}
                setDateSelDropdownOn={setDateSelDropdownOn}
                updateSelDate={(date, e)=>updateSelDate(date, e)}
                traffic={campaignList && campaignList.StatsSummaries && campaignList.StatsSummaries.length > 0 ? campaignList.StatsSummaries.reduce((acc, curr) => acc + curr.Stats.Traffic, 0) : 0} 
                joins={campaignList && campaignList.StatsSummaries && campaignList.StatsSummaries.length > 0 ? campaignList.StatsSummaries.reduce((acc, curr) => acc + curr.Stats.Joins, 0) : 0} 
                upgrades={campaignList && campaignList.StatsSummaries && campaignList.StatsSummaries.length > 0 ? campaignList.StatsSummaries.reduce((acc, curr) => acc + curr.Stats.Upgrades, 0) : 0} 
            />
            
            <div className={classNames("section", "my-compaign", "card-group")}>
                <div className="section-header">
                    <h3>My campaigns</h3>
                    <p className="add-compaign"><Link to={PAGE_NAV.CAMPAIGN_ADD} title="Add campaign"><img src={circlePlusIco} alt="+" /> <span>Add campaign</span></Link></p>
                </div>
                
                <BrowserView>
                    <table className="compaign-table">
                        <thead className={classNames("thead")}>
                            <tr>

                                <th></th>
                                <th>Click</th>
                                <th>Joins</th>
                                <th>Sales</th>
                                <th>Earnings</th>
                                <th> </th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                campaignList
                                && campaignList.StatsSummaries
                                && campaignList.StatsSummaries.length > 0
                                && [...campaignList.StatsSummaries]
                                    .map(c=>({
                                        ...c,
                                        CampaignDetail: {
                                            ...c.CampaignDetail,
                                            Title: c.CampaignDetail.Title ? c.CampaignDetail.Title : 'Campaign', 
                                            
                                        }}))
                                    .sort((a, b) => a.CampaignDetail.Title.toLowerCase() > b.CampaignDetail.Title.toLowerCase() ? 1 : -1)
                                    .map((campaign, i) => (
                                        <tr key={`campaign_${campaign.CampaignDetail.CampaignID}_${i}`}>
                                            <td><Link to={`${PAGE_NAV.CAMPAIGNS}/${campaign.CampaignDetail.CampaignID}`}>{campaign.CampaignDetail.Title}</Link></td>
                                            <td>{numberFormatter.format(campaign.Stats.Traffic)}</td>
                                            <td>{numberFormatter.format(campaign.Stats.Joins)} {campaign.Stats.Traffic > 0 && campaign.Stats.Joins > 0 ? `(${Math.round(campaign.Stats.Joins * 100 / campaign.Stats.Traffic)}%)` : ``}</td>
                                            <td>{numberFormatter.format(campaign.Stats.Upgrades)} {campaign.Stats.Upgrades > 0 && campaign.Stats.Joins > 0 ? `(${Math.round(campaign.Stats.Upgrades * 100 / campaign.Stats.Joins)}%)` : ``}</td>
                                            <td>{currencyFormatter.format(campaign.Stats.Revenue)}</td>
                                            <td onClick={(e)=>{e.preventDefault(); onDelIconClicked(campaign)}}><FontAwesomeIcon icon={faTrashAlt} /></td>
                                        </tr>
                                    ))
                            }
                        </tbody>
                    </table>
                </BrowserView>

                <MobileView>
                    {
                        campaignList
                        && campaignList.StatsSummaries
                        && campaignList.StatsSummaries.length > 0
                        && [...campaignList.StatsSummaries]
                            .map(c=>({
                                ...c,
                                CampaignDetail: {
                                    ...c.CampaignDetail,
                                    Title: c.CampaignDetail.Title ? c.CampaignDetail.Title : 'Campaign', 
                                    
                                }}))
                            .sort((a, b) => a.CampaignDetail.Title.toLowerCase() > b.CampaignDetail.Title.toLowerCase() ? 1 : -1)
                            .map((campaign, i) => (
                                <div className="table" key={`campaign_${campaign.CampaignDetail.CampaignID}_${i}`}>
                                        <div className="row-header"><Link to={`${PAGE_NAV.CAMPAIGNS}/${campaign.CampaignDetail.CampaignID}`}>{campaign.CampaignDetail.Title}</Link> <FontAwesomeIcon icon={faTrashAlt} onClick={(e)=>{e.preventDefault(); onDelIconClicked(campaign)}} /></div>
                                        <div className="th-td">
                                            <div>
                                                <div className="th">Click</div>
                                                <div className="th">Join</div>
                                                <div className="th">Sales</div>
                                                <div className="th">Earnings</div>
                                            </div>
                                            <div>
                                                <div className="td">{numberFormatter.format(campaign.Stats.Traffic)}</div>
                                                <div className="td">{numberFormatter.format(campaign.Stats.Joins)} {campaign.Stats.Traffic > 0 && campaign.Stats.Joins > 0 ? `(${Math.round(campaign.Stats.Joins * 100 / campaign.Stats.Traffic)}%)` : ``}</div>
                                                <div className="td">{numberFormatter.format(campaign.Stats.Upgrades)} {campaign.Stats.Upgrades > 0 && campaign.Stats.Joins > 0 ? `(${Math.round(campaign.Stats.Upgrades * 100 / campaign.Stats.Joins)}%)` : ``}</div>
                                                <div className="td">{currencyFormatter.format(campaign.Stats.Revenue)}</div>
                                            </div>
                                        </div>
                                </div>
                            ))
                    }
                </MobileView>

                <ConfirmPopup 
                    open={delConfirmPopup} 
                    close={onDelConfirmPopupClosed}
                    confirm={onDelConfirmPopupConfirmed} 
                    heading="Delete campaign" 
                    btnText="Delete campaign">
                    <p>Are you sure you want to delete this campaign : {selCampaign?.CampaignDetail?.Title}?</p>
                </ConfirmPopup>

            </div>
        </div>
    );
}

export default CampaignList;
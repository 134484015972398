
import * as Yup from 'yup';
import lookupService from "services/lookup";
import isEmail from 'sane-email-validation';

class ValidationsUtil {
  static affiliateName(){
    return Yup
    .string()
    .required('Affiliate name is required')
    .min(5, 'Affiliate name minimum 5 characters')
    .max(20, 'Affiliate name maximum 20 characters')
    .matches(/^[a-zA-Z0-9_ ]{5,20}$/, 'Affiliate name must only contain letters, numbers & underscore')
    .test(
        'checkUniqueAffiliateName', 
        "This affiliate name is already in used", 
        value=>{ 
            return value.match(/^[a-zA-Z0-9_ ]{5,20}$/)
            ? lookupService.getLookupAvailability("affiliatename", value)
                .then(response =>{
                    const {data} = response;
                    const {IsAvailable} = data ? data : {IsAvailable:false};
                    return IsAvailable === true
                })
            : true
            })
    ;
    // .test(async (v, context) => {
    //   try {
    //       await Yup.string().required('Affiliate name is required')
    //             .min(5, 'Affiliate name minimun 5 characters')
    //             .max(20, 'Affiliate name maximum 20 characters')
    //             .matches(/^[a-zA-Z0-9_ ]{5,20}$/, 'Affiliate name must only contain letters, numbers & underscore').validate(v);
    //       await Yup.string().test(
    //             'checkUniqueAffiliateName', 
    //             "This affiliate name is already in used", 
    //             value=>{ 
    //                   return lookupService.getLookupAvailability("affiliatename", value)
    //                     .then(response =>{
    //                         const {data} = response;
    //                         const {IsAvailable} = data ? data : {IsAvailable:false};
    //                         return IsAvailable === true
    //                     })
    //               }).validate(v);
    //   } catch ({message}) {
    //       return context.createError({message});
    //   }
    //   return true;
    // })
    
    // .test(
    //     'checkUniqueAffiliateName', 
    //     "This affiliate name is already in used", 
    //     value=>{ 
    //         // return 
    //         // value.match(/^[a-zA-Z0-9_ ]{5,20}$/)
    //         // ? 
    //           return lookupService.getLookupAvailability("affiliatename", value)
    //             .then(response =>{
    //                 const {data} = response;
    //                 const {IsAvailable} = data ? data : {IsAvailable:false};
    //                 return IsAvailable === true
    //             })
    //         // : true
    //         })
    // ;
  }
  static email() {
    return Yup.string()
        .required('Email is required')
        .email('Email is invalid')
        .test(
            'checkUniqueEmail', 
            "This Email is already in used", 
            value=>{ 
                return value && isEmail(value) 
                ? lookupService.getLookupAvailability("email", value)
                    .then(response =>{
                        const {data} = response;
                        const {IsAvailable} = data ? data : {IsAvailable:false};
                        return IsAvailable === true
                    })
                : true
                })
        ; 
  }
  static notUniqueEmail() {
    return Yup.string()
        .required('Email is required')
        .email('Email is invalid'); 
  }
  static password(){
    return Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required');
  }
  static confirmPassword(pwdRef){
    return Yup.string()
    .oneOf([Yup.ref(pwdRef), null], 'Passwords must match')
    .required('Confirm Password is required');
  }
  
  
}

export default ValidationsUtil;
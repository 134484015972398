import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import validationSchema from 'utils/validationSchemas/editProfile';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import useAccount from "hooks/useAccount";
import ButtonWithSpinner from './buttons/ButtonSpinner';
import PasswordUpdateModal from './modals/PasswordUpdate';
import PhoneUpdateModal  from './modals/PhoneNumberUpdatePopup';

const EditProfileForm = (props) => {
    const accountProvider = useAccount();
    const { profiles, promoteChannels, phoneDetail } = accountProvider;
    const { Name, Email } = profiles;
    const {
        PromoteWebsite, PromoteInstagram, PromoteTikTok,
        PromoteYoutube, PromoteFacebook, PromoteOnlyFans, PromoteOther,
    } = promoteChannels;
    const { PhoneNumber, PhoneCountryCode } = phoneDetail;

    const {
        handleSubmit,
        formState: { errors, isSubmitted },
        register,
        watch,
        setValue,
    } = useForm({
        mode: 'all',
        defaultValues: {
            affiliateName: Name,
            email: Email,
            website: PromoteWebsite,
            showWebsite: !!PromoteWebsite,
            instagram: PromoteInstagram,
            showInstagram: !!PromoteInstagram,
            tiktok: PromoteTikTok,
            showTiktok: !!PromoteTikTok,
            youtube: PromoteYoutube,
            showYoutube: !!PromoteYoutube,
            facebook: PromoteFacebook,
            showFacebook: !!PromoteFacebook,
            onlyfans: PromoteOnlyFans,
            showOnlyfans: !!PromoteOnlyFans,
            other: PromoteOther,
            showOther: !!PromoteOther
        },
        resolver: yupResolver(validationSchema)
    });
    const watchShowWebsite = watch("showWebsite", !!PromoteWebsite);
    const watchShowInstagram = watch("showInstagram", !!PromoteInstagram);
    const watchShowTiktok = watch("showTiktok", !!PromoteTikTok);
    const watchShowYoutube = watch("showYoutube", !!PromoteYoutube);
    const watchShowFacebook = watch("showFacebook", !!PromoteFacebook);
    const watchShowOnlyfans = watch("showOnlyfans", !!PromoteOnlyFans);
    const watchShowOther = watch("showOther", !PromoteOther);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPasswordUpdate, setShowPasswordUpdate] = useState(false);
    const [showPhoneUpdate, setShowPhoneUpdate] = useState(false)

    const onEditPasswordClicked = (e)=>{
        e.preventDefault();
        setShowPasswordUpdate(true);
    }

    const onEditPhoneClicked = (e)=>{
        e.preventDefault();
        setShowPhoneUpdate(true);
    }

    const onSubmitHandler = async (frmData, e) => {
        e.preventDefault();

        if (isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            Name: frmData.affiliateName,
            Email: frmData.email,
            PromoteChannels: {
                PromoteWebsite: frmData.showWebsite && frmData.website ? frmData.website : '',
                PromoteInstagram: frmData.showInstagram && frmData.instagram ? frmData.instagram : '',
                PromoteTikTok: frmData.showTiktok && frmData.tiktok ? frmData.tiktok : '',
                PromoteYoutube: frmData.showYoutube && frmData.youtube ? frmData.youtube : '',
                PromoteFacebook: frmData.showFacebook && frmData.facebook ? frmData.facebook : '',
                PromoteOnlyFans: frmData.showOnlyfans && frmData.onlyfans ? frmData.onlyfans : '',
                PromoteOther: frmData.showOther && frmData.other ? frmData.other : ''
            }
        };
        accountProvider.updateProfileDetails(submittedData)
            .then(() => {

            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }

    useEffect(() => setValue("affiliateName", Name), [Name, setValue]);
    useEffect(() => setValue("email", Email), [Email, setValue]);
    useEffect(() => {
        setValue("website", PromoteWebsite);
        setValue("showWebsite", !!PromoteWebsite);
    }, [PromoteWebsite, setValue]);
    useEffect(() => {
        setValue("instagram", PromoteInstagram);
        setValue("showInstagram", !!PromoteInstagram);
    }, [PromoteInstagram, setValue]);
    useEffect(() => {
        setValue("tiktok", PromoteTikTok);
        setValue("showTiktok", !!PromoteTikTok);
    }, [PromoteTikTok, setValue]);
    useEffect(() => {
        setValue("youtube", PromoteYoutube);
        setValue("showYoutube", !!PromoteYoutube);
    }, [PromoteYoutube, setValue]);
    useEffect(() => {
        setValue("facebook", PromoteFacebook);
        setValue("showFacebook", !!PromoteFacebook);
    }, [PromoteFacebook, setValue]);
    useEffect(() => {
        setValue("onlyfans", PromoteOnlyFans);
        setValue("showOnlyfans", !!PromoteOnlyFans);
    }, [PromoteOnlyFans, setValue]);
    useEffect(() => {
        setValue("other", PromoteOther);
        setValue("showOther", !!PromoteOther);
    }, [PromoteOther, setValue]);


    return (
        <>
            <form className="acc-form" onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="user-details">
                    <fieldset className="input">
                        <p className={classNames("name", { error: errors?.affiliateName?.message })} >
                            <span>
                                <label htmlFor="given-name">Affiliate name</label>
                                <input id="given-name" type="text" placeholder="Affiliate Name" {...register("affiliateName")} />
                                <span className="error-msg">{errors?.affiliateName?.message}</span>
                            </span>
                        </p>

                        <p className={classNames("number")} >
                            <span>
                                <label htmlFor="mobile-number">Mobile number</label>
                                <input id="phone" className="phone" type="tel" placeholder="Mobile Number" value={PhoneNumber ? `+${PhoneCountryCode} ${PhoneNumber}` : ``} readOnly disabled={true} />
                            </span>
                            <span className="update"><a href="/" title="Update mobile number" onClick={onEditPhoneClicked}>{PhoneNumber ? `Update` : 'Add'} mobile number</a></span>
                        </p>

                        <p className={classNames("email", { error: errors?.email?.message })}>
                            <span>
                                <label htmlFor="email" >Email</label>
                                <input id="email" autoComplete="email" type="email" placeholder="Email" {...register("email")} />
                                <span className="error-msg">{errors?.email?.message}</span>
                            </span>
                        </p>

                        <p className={classNames("pass")}>
                            <span>
                                <label htmlFor="create-pass" >Password</label>
                                <input id="create-pass" value="*************************" type="password" placeholder="Password" readOnly disabled={true} />
                            </span>
                            <span className="update"><a href="/" title="Update password" onClick={onEditPasswordClicked}>Update password</a></span>
                        </p>

                    </fieldset>
                </div>

                <fieldset className="input">
                    <div className={classNames("checkbox-group", { error: (isSubmitted && !watchShowWebsite && !watchShowInstagram && !watchShowTiktok && !watchShowYoutube && !watchShowFacebook && !watchShowOnlyfans && !watchShowOther) })}>
                        <p>Where will you promote RedHotPie?</p>

                        <p className="website">
                            <span className="checkbox">
                                <input type="checkbox" id="website" name="website" {...register("showWebsite")} /><label htmlFor="website">Website</label>
                            </span>
                            {
                                watchShowWebsite &&
                                <span className={classNames("input", { error: errors?.website?.message })}>
                                    <label htmlFor="web-url">Please enter your website URL</label>
                                    <input id="web-url" type="text" placeholder="Please enter your website URL" {...register("website", { required: "Please enter url", url: "Invalid url" })} />
                                    {errors?.website?.message && <span className="error-msg">{errors?.website?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="instagram">
                            <span className="checkbox">
                                <input type="checkbox" id="instagram" name="instagram" {...register("showInstagram")} /><label htmlFor="instagram">Instagram</label>
                            </span>
                            {
                                watchShowInstagram &&
                                <span className={classNames("checkbox-input", { error: errors?.instagram?.message })}>
                                    <label htmlFor="instagram-url">Please enter your Instagram URL</label>
                                    <input id="instagram-url" type="text" placeholder="Please enter your Instagram URL" {...register("instagram")} />
                                    {errors?.instagram?.message && <span className="error-msg">{errors?.instagram?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="tiktok">
                            <span className="checkbox">
                                <input type="checkbox" id="tiktok" name="tiktok" {...register("showTiktok")} /><label htmlFor="tiktok">TikTok</label>
                            </span>
                            {
                                watchShowTiktok &&
                                <span className={classNames("input", { error: errors?.tiktok?.message })}>
                                    <label htmlFor="tiktok-url">Please enter your Tiktok channel URL</label>
                                    <input id="tiktok-ur" type="text" placeholder="Please enter your Tiktok channel URL" {...register("tiktok")} />
                                    {errors?.tiktok?.message && <span className="error-msg">{errors?.tiktok?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="youtube">
                            <span className="checkbox">
                                <input type="checkbox" id="youtube" name="youtube" {...register("showYoutube")} /><label htmlFor="youtube">Youtube</label>
                            </span>
                            {
                                watchShowYoutube &&
                                <span className={classNames("input", { error: errors?.youtube?.message })}>
                                    <label htmlFor="youtube-url">Please enter your Youtube channel URL</label>
                                    <input id="youtube-url" type="text" placeholder="Enter your Youtube channel url" {...register("youtube")} />
                                    {errors?.youtube?.message && <span className="error-msg">{errors?.youtube?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="facebook">
                            <span className="checkbox">
                                <input type="checkbox" id="facebook" name="facebook" {...register("showFacebook")} /><label htmlFor="facebook">Facebook</label>
                            </span>
                            {
                                watchShowFacebook &&
                                <span className={classNames("input", { error: errors?.facebook?.message })}>
                                    <label htmlFor="facebook-url">Please enter your Facebook URL</label>
                                    <input id="facebook-url" type="text" placeholder="Please enter your Facebook URL" {...register("facebook")} />
                                    {errors?.facebook?.message && <span className="error-msg">{errors?.facebook?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="only-fans">
                            <span className="checkbox">
                                <input type="checkbox" id="only-fans" name="only-fans" {...register("showOnlyfans")} /><label htmlFor="only-fans">OnlyFans</label>
                            </span>
                            {
                                watchShowOnlyfans &&
                                <span className={classNames("input", { error: errors?.onlyfans?.message })}>
                                    <label htmlFor="only-fans-url">Please enter your OnlyFans URL</label>
                                    <input id="only-fans-url" type="text" placeholder="Please enter your OnlyFans URL" {...register("onlyfans")} />
                                    {errors?.onlyfans?.message && <span className="error-msg">{errors?.onlyfans?.message}</span>}
                                </span>
                            }

                        </p>

                        <p className="other">
                            <span className="checkbox">
                                <input type="checkbox" id="other" name="other" {...register("showOther")} /><label htmlFor="other">Other</label>
                            </span>
                            {
                                watchShowOther &&
                                <span className={classNames("input", { error: errors?.other?.message })}>
                                    <label htmlFor="other-url">Eg Email marketing, Google Adwords ...etc </label>
                                    <input id="other-url" type="text" placeholder="Eg Email marketing, Google Adwords ...etc " {...register("other")} />
                                    {errors?.other?.message && <span className="error-msg">{errors?.other?.message}</span>}
                                </span>
                            }

                        </p>

                        {isSubmitted && !watchShowWebsite && !watchShowInstagram && !watchShowTiktok && !watchShowYoutube && !watchShowFacebook && !watchShowOnlyfans && !watchShowOther && errors?.promoteChannels?.message && <p className="error-msg">{errors?.promoteChannels?.message}</p>}
                    </div>
                </fieldset>

                <fieldset className="submit">
                    <p className="btn green link">
                        <ButtonWithSpinner text={`Update`} isLoading={isSubmitting} />
                    </p>
                </fieldset>

            </form>
            {
                showPasswordUpdate && <PasswordUpdateModal open={showPasswordUpdate} close={()=>setShowPasswordUpdate(false)} />
            }
            {
                showPhoneUpdate &&  <PhoneUpdateModal open={showPhoneUpdate} close={()=>setShowPhoneUpdate(false)}></PhoneUpdateModal>
            }
            
           
        </>
    );

}

export default EditProfileForm;
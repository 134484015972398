import useAccount from "./useAccount";
import useAuth from './useAuth';
import { useEffect, useState } from 'react';
import {JOIN_STEP} from "configs/page";
import { useRecoilState } from 'recoil';
import regStepAtom from 'recoil/account/regStep';
import auth from 'utils/auth';

const useRegSteps = ()=>{
    const [currentStep, setCurrentStep] = useRecoilState(regStepAtom);
    const isLoggedInAuth = auth.isLoggedIn()
    const accountProvider = useAccount();
    const {accountLoaded} = useAuth();
    const [isFetching, setIsFetching] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    
    useEffect(()=>{
        if(isLoggedInAuth){
            if(!accountLoaded){
                if(!isFetching){
                    setIsFetching(true);
                    accountProvider.getAccount().then(()=>setIsFetching(false));
                }
                
            }
            else if(!isLoaded) {
                setIsLoaded(true);
                const {paymentInfo,
                    bankDetails,
                    profiles,
                    // phoneDetail,
                    billingInfo
                } = accountProvider;
              
                const {Name, Email} = profiles;
                // const {PhoneNumber, PhoneVerified} = phoneDetail
                
                if(!Name || !Email ){//|| !PhoneNumber){
                    setCurrentStep(JOIN_STEP.CREATE_ACCOUNT);
                }
                // else if(!PhoneVerified){
                //     setCurrentStep(JOIN_STEP.VERIFY_PHONE_NUMBER);
                // }
                else {
                    
                    const {AffiliatePaymentTypeID, Paypal, PaymentID} = paymentInfo;
                    // console.log("AffiliatePaymentTypeID",AffiliatePaymentTypeID)
                    const {BankName} = bankDetails;
            
                    if(
                        (AffiliatePaymentTypeID === "Paypal" && !Paypal)
                        || (AffiliatePaymentTypeID === "PayID" && !PaymentID)
                        || (AffiliatePaymentTypeID === "DirectDeposit" && !BankName)
                    ){
                        setCurrentStep(JOIN_STEP.PAYMENT_INFO);
                    }
                    else {
                        const {BillingName} = billingInfo;
                        if(!BillingName){
                            setCurrentStep(JOIN_STEP.BILLING_INFO);
                        }
                        else {
                            setCurrentStep(JOIN_STEP.DONE);
                        }
                    }
                }
            }
            
        }
        return ()=>{}
    },[isLoggedInAuth, accountLoaded, setCurrentStep, accountProvider, isFetching, setIsFetching, isLoaded, setIsLoaded]);

   return [currentStep, setCurrentStep];
}

export default useRegSteps;
import React, { useEffect } from 'react';
import AffiliateHeader from "../components/AffiliateHeader";
import CreateAccountForm from 'components/CreateAccountForm';
import RegStepTwo from 'components/PaymentInfoForm';
import RegStepThree from 'components/BillingInfoForm';
import {JOIN_STEP, PAGE_NAV} from "configs/page";
import useRegSteps from 'hooks/useRegSteps';
import Footer from "components/Footer";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";


const AffiliateReg = (props) => {
    const {history} = props;
    const [currentStep, setCurrentStep] = useRegSteps(); //useRecoilState(regStepAtom);

    const postCreateAccountFunc = ()=>{
        // setCurrentStep(JOIN_STEP.VERIFY_PHONE_NUMBER);
        setCurrentStep(JOIN_STEP.PAYMENT_INFO);
    }

    const postPhoneVerificationFunc = ()=>{
        // setCurrentStep(JOIN_STEP.PAYMENT_INFO);
    }

    const postUpdatePaymentInfo = ()=>{
        setCurrentStep(JOIN_STEP.BILLING_INFO);
    }

    const postUpdateBillingInfo = ()=>{
        setCurrentStep(JOIN_STEP.DONE);
        history.push(PAGE_NAV.DASHBOARD);
    }

    const handleBackFromBillingInfo = ()=>{
        setCurrentStep(JOIN_STEP.PAYMENT_INFO);
    }

    useEffect(()=>{
        if(currentStep === JOIN_STEP.DONE){
            history.push(PAGE_NAV.DASHBOARD);
        }
    }, [currentStep, history])

    return (
        <div className="main">
         <div className="section header">
            <AffiliateHeader {...props} />
         </div>
            
        <div className="section reg">

            <div className="content">

               {(currentStep === JOIN_STEP.CREATE_ACCOUNT || currentStep === JOIN_STEP.VERIFY_PHONE_NUMBER) && <CreateAccountForm handlers={{postCreateAccountFunc, postPhoneVerificationFunc}}  />} 
               { currentStep === JOIN_STEP.PAYMENT_INFO &&  <RegStepTwo handlers={{postUpdatePaymentInfo}} display={{saveText:"Next"}} clx="reg-form" />}
               {currentStep === JOIN_STEP.BILLING_INFO &&  <RegStepThree handlers={{postUpdateBillingInfo, onBackButtonClicked:handleBackFromBillingInfo}} display={{showBackButton:true, saveText:"Next"}} clx="reg-form" />}
                        
           
                    {/* <fieldset className="submit">
                        {currentStep !== 1 && <p className="btn link"><a href="/"  title="Click to go back" onClick={(e)=>{e.preventDefault(); handleBackStep()}}><FontAwesomeIcon icon={faChevronLeft} /> Back</a></p> }
                        <p className="btn green link"><a href="/" title="Click to proceed" onClick={(e)=>{e.preventDefault(); handleNextStep()}}>Next</a></p>
                    </fieldset> */}
                    
                {/* </form> */}


                <div className="application-info">  
                    <h2>Open a live affiliate account</h2>
                    <ol>
                        <li>
                            <h3>Start your application</h3>
                            <p>Fill in your details using the form on this page. Our website is encrypted to secure your personal information</p>
                        </li>
                        <li>
                            <h3>How would you like to get paid?</h3>
                            <p>Fill in your details using the form on this page. Our website is encrypted to secure your personal information</p>
                        </li>
                        <li>
                            <h3>Billing information</h3>
                            <p>Fill in your details using the form on this page. Our website is encrypted to secure your personal information</p>
                        </li>
                    </ol>

                </div>
                
            </div>

        </div>

        <Footer />
        </div>
    )
}

export default AffiliateReg;
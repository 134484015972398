import React, {useEffect, useState, useRef} from 'react';
import {PAGE_NAV} from "configs/page";
import { Redirect } from 'react-router-dom';
import useLogout from 'hooks/useLogout';

const Logout = () => {
    const logoutProvider = useLogout();
    const [loggedOut, setLoggedOut] = useState(false);
    const mountedRef = useRef(true);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, []);

    useEffect(()=>{
      if(isSubmitting) return;
      mountedRef.current && setIsSubmitting(true);

      logoutProvider()
        .finally(()=> {
          if(mountedRef.current){ 
            setLoggedOut(true);
            setIsSubmitting(false);
          }
      });
    },[setLoggedOut,logoutProvider,isSubmitting, setIsSubmitting]);
    
    return loggedOut ? <Redirect to={PAGE_NAV.LOGIN} /> : null ;
}

export default Logout;
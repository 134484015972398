import React from 'react';
import { Route, Switch } from 'react-router-dom';
// import Home from "pages/AffiliateQuarter";
import PageViews from "./pageNav";
// import {PAGE_NAV} from "configs/page";

const MainNav = ()=>{
    return (
        <Switch>
            {/* <Route exact path={PAGE_NAV.HOME}  component={Home} /> */}
            <Route component={PageViews} />
        </Switch>
    );
}

export default MainNav;
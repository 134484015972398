import {atom, selector} from "recoil";
import keys from "configs/atoms";
import selectorKeys from "configs/selectors";
import service from "services/lookup"

const lookupCountrySelector = selector({
    key: selectorKeys.Lookup.Country,
    get: async()=> {
        const response = await service.getLookupCountry();
        return response.data;
    } 
});

const lookupCountryAtom = atom(({
    key: keys.Lookup.Country,
    default: lookupCountrySelector
}));

export default lookupCountryAtom;
import {atom} from "recoil";
import keys from "configs/atoms";
import {MENUS} from "configs/page";

const [first] = MENUS;

const leftMenuAtom = atom(({
    key: keys.Nav.LeftMenu,
    default: first
}));

export default leftMenuAtom;
import classNames from 'classnames';
import React, {useEffect, useState, useRef} from 'react';
import validationSchema from 'utils/validationSchemas/createAccount';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import PhoneCountryCodePopup from 'components/modals/PhoneCountryCodePopup';
import { allCountries } from 'country-telephone-data';
import useAccount from "hooks/useAccount";
import useLogin from "hooks/useLogin";
import MobileVerify from 'components/modals/PhoneVerification';
// import { useRecoilValue } from 'recoil';
// import regStepAtom from 'recoil/account/regStep';
// import {JOIN_STEP} from "configs/page";
import ButtonWithSpinner from './buttons/ButtonSpinner';
import TCPopup from 'components/modals/T&CPopup';

const RegStepOne = (props) => {
    const {handlers} = props;
    const {postCreateAccountFunc} = handlers;
    // const currentStep = useRecoilValue(regStepAtom);
    const ref = useRef();
    const accountProvider = useAccount();
    const loginProvider = useLogin();

    const {profiles, phoneDetail, promoteChannels} =  accountProvider;
    const { Name, Email } = profiles;
    const { PhoneNumber, PhoneAlpha2Code} = phoneDetail;
    const {
            PromoteWebsite, PromoteInstagram,PromoteTikTok,
            PromoteYoutube, PromoteFacebook, PromoteOnlyFans, PromoteOther,
        } = promoteChannels;


    const { 
            handleSubmit, 
            formState: { errors, isSubmitted }, 
            register,
            watch,
            setValue,
            getValues
        } = useForm({ 
            mode: 'all', 
            defaultValues: {
                affiliateName: Name,
                email: Email,
                password: '',
                phoneNumber: PhoneNumber,
                phoneCountryCode: allCountries.find(itm=>itm.iso2=== (PhoneAlpha2Code ? PhoneAlpha2Code.toLowerCase() : "au")),
                website: PromoteWebsite,
                showWebsite: !!PromoteWebsite,
                instagram: PromoteInstagram,
                showInstagram: !!PromoteInstagram,
                tiktok: PromoteTikTok,
                showTiktok: !!PromoteTikTok,
                youtube: PromoteYoutube,
                showYoutube: !!PromoteYoutube,
                facebook: PromoteFacebook,
                showFacebook: !!PromoteFacebook,
                onlyfans: PromoteOnlyFans,
                showOnlyfans: !!PromoteOnlyFans,
                other: PromoteOther,
                showOther: !!PromoteOther
            },
            resolver: yupResolver(validationSchema) 
    });
    const watchShowWebsite = watch("showWebsite", !!PromoteWebsite);
    const watchShowInstagram = watch("showInstagram", !!PromoteInstagram);
    const watchShowTiktok = watch("showTiktok", !!PromoteTikTok);
    const watchShowYoutube = watch("showYoutube", !!PromoteYoutube);
    const watchShowFacebook = watch("showFacebook", !!PromoteFacebook);
    const watchShowOnlyfans = watch("showOnlyfans", !!PromoteOnlyFans);
    const watchShowOther = watch("showOther", !PromoteOther);
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [phoneVerifyOpen, setPhoneVerifyOpen] = useState(false);
    const [phoneVerificationResult, setPhoneVerificationResult] = useState(null);
    
    useEffect(()=>{
        
        if(getValues("phoneNumber")){
            setPhoneVerificationResult(null);
        }
        
    }, [getValues, setPhoneVerificationResult])

    

    const [countryCodeOpen, setCountryCodeOpen] = useState(false);
    
    const setPhoneCountryCode = (pcc)=>{
        setValue("phoneCountryCode", pcc);
        setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }

    useEffect(()=>{
       const iso2 = PhoneAlpha2Code ? PhoneAlpha2Code.toLowerCase() : "au";
       const pcc = allCountries.find(itm=>itm.iso2===iso2);
       setValue("phoneCountryCode", pcc);
       setValue("phoneCountryCodeDisplay", `+${pcc.dialCode}`)
    }, [setValue]) // eslint-disable-line react-hooks/exhaustive-deps

    // useEffect(()=>{
    //     if(currentStep === JOIN_STEP.VERIFY_PHONE_NUMBER) setPhoneVerifyOpen(true);
    // }, [currentStep])

    useEffect(() => setValue("affiliateName", Name), [Name, setValue]);
    useEffect(() => setValue("email", Email), [Email, setValue]);
    useEffect(() => setValue("phoneNumber", PhoneNumber), [PhoneNumber, setValue]);
    useEffect(() => setValue("phoneCountryCode", allCountries.find(itm=>itm.iso2=== (PhoneAlpha2Code ? PhoneAlpha2Code.toLowerCase() : "au"))), [PhoneAlpha2Code, setValue]);
    useEffect(() => {
        setValue("website", PromoteWebsite);
        setValue("showWebsite", !!PromoteWebsite);
    }, [PromoteWebsite, setValue]);
    useEffect(() => {
        setValue("instagram", PromoteInstagram);
        setValue("showInstagram", !!PromoteInstagram);
    }, [PromoteInstagram, setValue]);
    useEffect(() => {
        setValue("tiktok", PromoteTikTok);
        setValue("showTiktok", !!PromoteTikTok);
    }, [PromoteTikTok, setValue]);
    useEffect(() => {
        setValue("youtube", PromoteYoutube);
        setValue("showYoutube", !!PromoteYoutube);
    }, [PromoteYoutube, setValue]);
    useEffect(() => {
        setValue("facebook", PromoteFacebook);
        setValue("showFacebook", !!PromoteFacebook);
    }, [PromoteFacebook, setValue]);
    useEffect(() => { 
        setValue("onlyfans", PromoteOnlyFans);
        setValue("showOnlyfans", !!PromoteOnlyFans);
    }, [PromoteOnlyFans, setValue]);
    useEffect(() => {
        setValue("other", PromoteOther);
        setValue("showOther", !!PromoteOther);
    }, [PromoteOther, setValue]);

    const onPhoneRequestResendCode = async ()=>{
        const phoneNumber = getValues("phoneNumber");
        const phoneCountry = getValues("phoneCountryCode");
        const {dialCode, iso2} = phoneCountry;

        return accountProvider.requestPhoneCode({PhoneNumber:phoneNumber, PhoneCountryCode:dialCode, PhoneAlpha2Code:iso2});
    }

    const onPhoneVerificationClicked = async(code)=>{
        const phoneNumber = getValues("phoneNumber");
        const phoneCountry = getValues("phoneCountryCode");
        const {dialCode, iso2} = phoneCountry;

        const result = await accountProvider.verifyPhone({
            VerificationCode: code,
            PhoneNumber:phoneNumber, 
            PhoneCountryCode:dialCode, 
            PhoneAlpha2Code:iso2});
        
        setPhoneVerificationResult(result);
        setTimeout(()=>{
            ref.current.dispatchEvent(
                new Event("submit", { cancelable: true, bubbles: true })
            );
        }, 150) 
    }

    const postPhoneVerificationSuccessFunc = ()=>{
        //setPhoneVerifyOpen(false);
        // postPhoneVerificationFunc();
    }

    const processCreateAccount = async(frmData, phoneDetails)=>{
        if(isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            Name: frmData.affiliateName,
            Email: frmData.email,
            Password: frmData.password,
            phoneDetails: phoneDetails,
            // PhoneDetails: {
            //     PhoneNumber: frmData.phoneNumber,
            //     PhoneCountryCode: frmData.phoneCountryCode.dialCode,
            //     PhoneAlpha2Code: frmData.phoneCountryCode.iso2
            // },
            PromoteChannels: {
                PromoteWebsite: frmData.showWebsite && frmData.website ? frmData.website : '',
                PromoteInstagram: frmData.showInstagram && frmData.instagram ? frmData.instagram : '',
                PromoteTikTok: frmData.showTiktok && frmData.tiktok ? frmData.tiktok : '',
                PromoteYoutube: frmData.showYoutube && frmData.youtube ? frmData.youtube : '',
                PromoteFacebook: frmData.showFacebook && frmData.facebook ? frmData.facebook : '',
                PromoteOnlyFans: frmData.showOnlyfans && frmData.onlyfans ? frmData.onlyfans : '',
                PromoteOther: frmData.showOther && frmData.other ? frmData.other : ''
            }
        };
        accountProvider.createAccount(submittedData)
        .then(()=>{
            return loginProvider.login({UserName: frmData.email, Password: frmData.password}, true)
            .then(()=>{
                if(postCreateAccountFunc && typeof postCreateAccountFunc === "function")
                    postCreateAccountFunc();
            })
            .finally(()=>{

            });
        })
        .finally(()=>{
            setIsSubmitting(false);
        });
    }

    const onSubmitHandler = async(frmData, e)=>{
        e.preventDefault();

        if(!frmData.phoneNumber){
            processCreateAccount(frmData, {
                PhoneNumber: '',
                PhoneCountryCode: '',
                PhoneAlpha2Code: ''
            });
        }
        else {
            if(phoneVerificationResult){
                processCreateAccount(frmData, phoneVerificationResult);
            }
            else {
                onPhoneRequestResendCode().then(()=>{
                    setPhoneVerifyOpen(true);
                });
            }
           
        }
    }

    const [showTCPopup, setShowTCPopup] = useState(false);
   
    return (
        <>
        <form className="reg-form" ref={ref} onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="step one">
        <h2>Start your application</h2>
        <div className="user-details">
            <fieldset className="input">

                <p className={classNames("name", {error: errors?.affiliateName?.message})} >
                    <label htmlFor="given-name">Affiliate name</label>
                    <input id="given-name" type="text" placeholder="Affiliate Name" {...register("affiliateName")} />
                    <span className="error-msg">{errors?.affiliateName?.message}</span>
                </p>
            </fieldset>
            <fieldset className="input">  
                <p className={classNames("phone", {error: errors?.phoneNumber?.message})} >
                    <label htmlFor="phone" >Mobile number</label>
                    <span className="field">
                        <input type="hidden" id="hdnPhoneCountryCode" {...register("phoneCountryCode")} />
                        <input className="area-code" id="area-code" type="tel" placeholder="+61" {...register("phoneCountryCodeDisplay")} onClick={()=>setCountryCodeOpen(true)}  readOnly />
                        <input id="phone" className="phone" type="tel" placeholder="Mobile Number" {...register("phoneNumber")} />
                    </span>
                    <span className="error-msg">{errors?.phoneNumber?.message}</span>
                </p>
                
                <p className={classNames("email", {error: errors?.email?.message})}>
                    <label htmlFor="email" >Email</label>
                    <input id="email" autoComplete="email" type="email" placeholder="Email" {...register("email")}/>
                    <span className="error-msg">{errors?.email?.message}</span>
                </p>
            </fieldset>

            <fieldset className="input"> 
                <p className={classNames("create-pass", {error: errors?.password?.message})}>
                    <label htmlFor="create-pass" >Create your password</label>
                    <input id="create-pass" autoComplete="new-password" type="password" placeholder="Create your password"  {...register("password")}/>
                    <span className="error-msg">{errors?.password?.message}</span>
                </p>
                <p className={classNames("confirm-pass", {error: errors?.confirmPassword?.message})}>
                    <label htmlFor="confirm-pass" >Confirm password</label>
                    <input id="confirm-pass" autoComplete="new-password" type="password" placeholder="Confirm password"  {...register("confirmPassword")}/>
                    <span className="error-msg">{errors?.confirmPassword?.message}</span>
                </p>
            </fieldset>
        </div>
        
        <fieldset className="input">
            <div className={classNames("checkbox-group", {error: (isSubmitted && !watchShowWebsite && !watchShowInstagram && !watchShowTiktok && !watchShowYoutube && !watchShowFacebook && !watchShowOnlyfans && !watchShowOther  )})}>
                <p>Where will you promote RedHotPie?</p>

                <p className="website">
                    <span className="checkbox">
                        <input type="checkbox" id="website" name="website" {...register("showWebsite")}  /><label htmlFor="website">Website</label>
                    </span>
                    {
                        watchShowWebsite &&
                        <span  className={classNames("input", {error: errors?.website?.message})}>
                            <label htmlFor="web-url">Please enter your website URL</label>
                            <input id="web-url" type="text" placeholder="Please enter your website URL" {...register("website", { required: "Please enter url", url: "Invalid url"})} />
                            {errors?.website?.message && <span className="error-msg">{errors?.website?.message}</span> }
                        </span>
                    }
                    
                </p>
            
                <p className="instagram">
                    <span className="checkbox">
                        <input type="checkbox" id="instagram" name="instagram" {...register("showInstagram")} /><label htmlFor="instagram">Instagram</label>
                    </span>
                    {
                        watchShowInstagram &&
                        <span className={classNames("checkbox-input", {error: errors?.instagram?.message})}>
                            <label htmlFor="instagram-url">Please enter your Instagram URL</label>
                            <input id="instagram-url" type="text" placeholder="Please enter your Instagram URL" {...register("instagram")}  />
                            {errors?.instagram?.message && <span className="error-msg">{errors?.instagram?.message}</span>}
                        </span>
                    }
                    
                </p>
           
                <p className="tiktok">
                    <span className="checkbox">
                        <input type="checkbox" id="tiktok" name="tiktok" {...register("showTiktok")} /><label htmlFor="tiktok">TikTok</label>
                    </span>
                    {
                        watchShowTiktok &&
                        <span  className={classNames("input", {error: errors?.tiktok?.message})}>
                            <label htmlFor="tiktok-url">Please enter your Tiktok channel URL</label>
                            <input id="tiktok-ur" type="text" placeholder="Please enter your Tiktok channel URL" {...register("tiktok")} />
                            {errors?.tiktok?.message && <span className="error-msg">{errors?.tiktok?.message}</span> }
                        </span>
                    }
                    
                </p>
            
                <p className="youtube">
                    <span className="checkbox">
                        <input type="checkbox" id="youtube" name="youtube" {...register("showYoutube")} /><label htmlFor="youtube">Youtube</label>
                    </span>
                    {
                        watchShowYoutube &&
                        <span className={classNames("input", {error: errors?.youtube?.message})}>
                            <label htmlFor="youtube-url">Please enter your Youtube channel URL</label>
                            <input id="youtube-url" type="text" placeholder="Enter your Youtube channel url" {...register("youtube")}  />
                            { errors?.youtube?.message && <span className="error-msg">{ errors?.youtube?.message}</span> }
                        </span>
                    }
                    
                </p>
           
                <p className="facebook">
                    <span className="checkbox">
                        <input type="checkbox" id="facebook" name="facebook" {...register("showFacebook")}/><label htmlFor="facebook">Facebook</label>
                    </span>
                    {
                        watchShowFacebook &&
                        <span className={classNames("input", {error: errors?.facebook?.message})}>
                            <label htmlFor="facebook-url">Please enter your Facebook URL</label>
                            <input id="facebook-url" type="text" placeholder="Please enter your Facebook URL" {...register("facebook")}  />
                            {errors?.facebook?.message && <span className="error-msg">{errors?.facebook?.message}</span> }
                        </span>
                    }
                    
                </p>
            
                <p className="only-fans">
                    <span className="checkbox">
                        <input type="checkbox" id="only-fans" name="only-fans" {...register("showOnlyfans")} /><label htmlFor="only-fans">OnlyFans</label>
                    </span>
                    {
                        watchShowOnlyfans &&
                        <span className={classNames("input", {error: errors?.onlyfans?.message})}>
                            <label htmlFor="only-fans-url">Please enter your OnlyFans URL</label>
                            <input id="only-fans-url" type="text" placeholder="Please enter your OnlyFans URL" {...register("onlyfans")}  />
                            {errors?.onlyfans?.message && <span className="error-msg">{errors?.onlyfans?.message}</span> }
                        </span>
                    }
                    
                </p>
                
                <p className="other">
                    <span className="checkbox">
                        <input type="checkbox" id="other" name="other" {...register("showOther")} /><label htmlFor="other">Other</label>
                    </span>
                    {
                        watchShowOther &&
                        <span className={classNames("input", {error: errors?.other?.message})}>
                            <label htmlFor="other-url">Eg Email marketing, Google Adwords ...etc </label>
                            <input id="other-url" type="text" placeholder="Eg Email marketing, Google Adwords ...etc " {...register("other")}  />
                            {errors?.other?.message && <span className="error-msg">{errors?.other?.message}</span> }
                        </span>
                    }
                    
                </p>
            
                { isSubmitted && !watchShowWebsite && !watchShowInstagram && !watchShowTiktok && !watchShowYoutube && !watchShowFacebook && !watchShowOnlyfans && !watchShowOther && errors?.promoteChannels?.message && <p className="error-msg">{errors?.promoteChannels?.message}</p> }
            </div>

            

            <p className="note">By signing up you agree to the <a href="/" onClick={e=>{e.preventDefault();setShowTCPopup(true)}} title="Click to find out more">Terms of service &amp; Privacy policy</a></p>
            <TCPopup open={showTCPopup} close={()=>setShowTCPopup(false)} />
        </fieldset>
        </div>

        <fieldset className="submit">
            <p className="btn green link">
                <ButtonWithSpinner text={`Next`} isLoading={isSubmitting} />
            </p>
        </fieldset>

        </form>
        {
            countryCodeOpen && <PhoneCountryCodePopup  open={countryCodeOpen} close={()=>setCountryCodeOpen(false)} setCountryCode={setPhoneCountryCode} defaultValue={getValues("phoneCountryCode")} />
        }
        {
            phoneVerifyOpen &&
            <MobileVerify open={phoneVerifyOpen} close={()=>setPhoneVerifyOpen(false)} 
                postProcessFunc={postPhoneVerificationSuccessFunc} 
                onPhoneVerificationClicked={onPhoneVerificationClicked} 
                onRequestResendCode={onPhoneRequestResendCode} />
        }
        
        </>
    );

}

export default RegStepOne;
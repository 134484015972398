import lookupStatsDatesAtom from "recoil/lookup/statsDates";
import lookupLandingPagesAtom from "recoil/lookup/landingPages";
import lookupAffiliateProgramsAtom from "recoil/lookup/affiliatePrograms";
import { useRecoilValue, useRecoilCallback } from "recoil";
import service from "services/lookup"
import useToast from "./useToast";
import { useEffect, useState,useRef } from "react";

const useLookup = ()=>{
    const toast = useToast();
    const statDates = useRecoilValue(lookupStatsDatesAtom);
    const landingPages = useRecoilValue(lookupLandingPagesAtom);
    const affiliatePrograms = useRecoilValue(lookupAffiliateProgramsAtom);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isFetchingPrograms, setIsFetchingPrograms] = useState(false);
    const mountedRef = useRef(true);

    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, []);

    const getLandingPages = useRecoilCallback(({ set }) => async()=>{
        return service.getLookupLandingPages()
        .then(response=>{
            set(lookupLandingPagesAtom, response.data);
        })
        .catch((error) => {
            toast.error("Failed retrieving landing page data", error);
            throw error;
        });
    });
    
    const getDateRange = useRecoilCallback(({ set }) => async()=>{
        return service.getLookup("DateRange")
        .then(response=>{
            set(lookupStatsDatesAtom, response.data);
        })
        .catch((error) => {
            toast.error("Failed retrieving date range data", error);
            throw error;
        });
    });

    const getPrograms = useRecoilCallback(({ set }) => async()=>{
        return service.getLookup("AffiliateProgram")
        .then(response=>{
            set(lookupAffiliateProgramsAtom, response.data);
        })
        .catch((error) => {
            toast.error("Failed retrieving affiliate programs data", error);
            throw error;
        });
    });
    
    useEffect(()=>{
        if(isFetchingPrograms) return;
        if(!affiliatePrograms || affiliatePrograms.length === 0) {
            mountedRef.current && setIsFetchingPrograms(true);
            getPrograms().finally(()=> mountedRef.current && setIsFetchingPrograms(false));
        }
    }, [affiliatePrograms, getPrograms, isFetchingPrograms, setIsFetchingPrograms]); 

    useEffect(()=>{
        if(isSubmitting) return;
        if(!statDates || statDates.length === 0) {
            mountedRef.current && setIsSubmitting(true);
            getDateRange().finally(()=> mountedRef.current && setIsSubmitting(false));
        }
    }, [statDates, getDateRange, isSubmitting, setIsSubmitting]);

    return {
        statDates,
        landingPages,
        affiliatePrograms,
        getLandingPages
    }
}

export default useLookup;
import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import AffiliateHeader from "components/AffiliateHeader";
import rhpLogo from "assets/img/rhp-logo-2019.svg";
import {PAGE_NAV} from "configs/page";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons"
import classNames from 'classnames';
// import FooterLinks from 'components/FooterLinks';
import ContactSupportPopup from "components/modals/ContactSupportPopup";
import { BrowserView, MobileView } from 'react-device-detect';
import Footer from 'components/Footer';

const AffiliateQuarter = (props) => {

    const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);

    return (
        <div className={classNames("main", "affiliate-quarter", "affiliate")}>
        <div className="section banner">
            {/* <div className="top-nav">
                <p className="page-logo"><a href="affiliate-quarter.frootdigital.com/" title="affiliate-quarter.frootdigital.com" ><img src={aqLogo} alt="Affiliate quarter" /></a></p>

                <ul className="nav">
                    <li><a href="#" title="Click to go to the home page">Home</a></li>
                    <li><a href="#" title="Click to get in touch">Get in touch</a></li>
                    <li className="btn link green"><a href="#" title="Click to join now">Join now</a></li>
                </ul>
            </div> */}
            <AffiliateHeader {...props} />
            <h1>Affiliates Quarter</h1>
            <p className="sub">Best online dating affiliate marketing program</p>

  
            <ul className="main-nav">
                <li className="btn white"><Link to={PAGE_NAV.JOIN} title="Click to get started">Get started</Link></li>
                {/* <li className="btn white"><a href="/" onClick={e=>e.preventDefault()} title="Click to learn more">Learn more</a></li> */}
            </ul>

        </div>
        <div className="section offer">
            <div className="content">
                <h2>What we offer</h2>
                <ul className="offer-list">
                    <li className="reliable-partner">Reliable Partner</li>
                    <li className="strongly-growing-company">Strongly Growing Company</li>
                    <li className="global-audience">Global Audience</li>
                    <li className="stable-payouts">Stable Payouts</li>
                </ul>
                <ul className="offer-list">
                    <li className="transparent-reports-and-marketing-tools">Transparent Reports and Marketing Tools</li>
                    <li className="high-commission-rates">High Commission Rates</li>
                    <li className="many-desktop-and-mile-offers">Many Desktop and Mobile Offers</li>
                    <li className="dedicated-account-management">Dedicated Account Management</li>
                </ul>
            </div>
        </div>
        {/* <div className="section payout">
            <div className="content">
                <h2>Geneerous payouts</h2>
                <div className="banner-list">
                    <div className="banner item">
                        <a href="/" onClick={e=>e.preventDefault()} title="Click to find out more">
                            <img src="http://via.placeholder.com/410x230" alt="About the banner" />
                        </a>
                    </div>
                    <div className="banner item">
                        <a href="/" onClick={e=>e.preventDefault()} title="Click to find out more">
                            <img src="http://via.placeholder.com/410x230" alt="About the banner" />
                        </a>
                    </div>
                </div>
                <p className="btn green link"><a href="/" onClick={e=>e.preventDefault()} title="Click to start earning now">Start earning now</a></p>
            </div>
        </div> */}
        <div className="section brand">
            <div className="content">
                <h2>Our brand</h2>
                <p className="logo"><img src={rhpLogo} alt="redhotpie logo&reg;" /></p>
            </div>
        </div>

        <div className="section contact">
            <div className="content">
                <h2>Get in touch</h2>
               
                <BrowserView>
                <p className="btn green link"><a href="/" onClick={(event)=>{event.preventDefault(); setShowContactSupportPopup(true)}} title="Click to contact support">Contact support</a></p>
                </BrowserView>
                <MobileView>
                <p className="btn green link"><Link to={PAGE_NAV.CONTACTSUPPORT} title="Click to contact support">Contact support</Link></p>
                </MobileView>
                
                {/* <p className="note">Please leave your enquiry or message below and we will be in touch with you shortly.</p> */}
                {/* <form className="contact-form">

                    <fieldset className="input">
                        <p className="name" ><input type="text" placeholder="Name" /></p>
                        <p className="email"><input type="email" placeholder="Email"/></p>
                        <p className="phone"><input type="number" placeholder="Phone" /></p>
                        <p className="web"><input type="text" placeholder="Website/Social links" /></p>
                    </fieldset>
                    
                    <fieldset className="submit">
                        <p className="message"><textarea  placeholder="Please leave your message here..."></textarea></p>
                        <p className="btn green"><input type="button" value="Submit" /></p>
                    </fieldset>
                </form> */}
            </div>

            <ContactSupportPopup open={showContactSupportPopup} close={()=>setShowContactSupportPopup(false)} postProcFunc={()=>{}} loggedin={false} />
        </div>
        {/* <div className="section footer">
            <div className="copyright">
                <p> Copyright 2021 | Affiliate Quarter | All Rights Reserved | Powered by <a href="https://digitalquarter.com/">Digital Quarter</a></p>
            </div>

           <FooterLinks />

            <div className="social">
                <p><a href="/" onClick={e=>e.preventDefault()} className="facebook"><span><FontAwesomeIcon icon={faFacebookF} /></span></a></p>
                <p><a href="/" onClick={e=>e.preventDefault()} className="twitter"><span><FontAwesomeIcon icon={faTwitter} /></span></a></p>
            </div>
        </div> */}
        <Footer />
        </div>
    );
}

export default AffiliateQuarter;
import {atom} from "recoil";
import keys from "configs/atoms";

const initState = {
    BankName : "", 
    AccountName : "", 
    AccountNumber : "",  //for au
    BSBNumber : "",  //for au
    SwiftCode : "",  //for intl
    IBAN : "",  //for intl
    Currency : "AUD", 
    CountryID :1 
}

const bankDetailsAtom = atom(({
    key: keys.Account.BankDetails,
    default: initState
}));

export default bankDetailsAtom;
import {publicFetch} from "services/fetch";
import useFetchProvider from 'hooks/useFetchProvider';

const  AuthService = ()=> {
    const fetch = useFetchProvider();
    const login = async (credentials)=> await publicFetch.post(`/auth/credentials`, credentials);
    const logout = async() => await fetch.post(`/auth/logout`);
    return { login, logout };
}

export default AuthService;
import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames';
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS } from 'configs/page';
import ConfirmPopup from 'components/modals/ConfirmPopup';
import tickCircleGreen from 'assets/img/tick-circle-green.svg';
import useLookup from 'hooks/useLookup';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import useCampaigns from 'hooks/useCampaigns';
import useToast from 'hooks/useToast';
import { PAGE_NAV } from 'configs/page';

const AddCompaign = (props) => {
    const {history} = props;
    const [leftMenu, setLeftMenu] = useLeftMenu();
    const lookupProvider = useLookup();
    const {landingPages, getLandingPages} = lookupProvider;
    const mountedRef = useRef(true);
    const [fetchingLandingPages, setFetchingLandingPages] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [addConfirmPopup, setAddConfirmPopup] = useState(false);
    const campaignProvider = useCampaigns();
    const {addNewCampaign} = campaignProvider;
    const toast = useToast();
    const [newID, setNewID] = useState(-1);

    const validationSchema = Yup.object().shape({
        title: Yup.string().trim().required("Required"),
        landingPage: Yup.string().trim().required("Required"),
    });

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm({
        mode: 'all',
        defaultValues: {
            title: '',
            landingPage: ''
        },
        resolver: yupResolver(validationSchema)
    });

   
    const  onSubmitHandler = async(frmData, e)=>{
        e.preventDefault();
        if(isSubmitting) return;

        const submittedData = {
            Title: frmData.title,
            LandingPage: frmData.landingPage
        };

        if(mountedRef.current) {
            setIsSubmitting(true);
            addNewCampaign(submittedData)
            .then((resp)=>{
                if(mountedRef.current) {
                    setNewID(resp);
                    setAddConfirmPopup(true);
                }
            }, (err)=>{
                toast.error("Failed to add new campaign", err);
            })
            .finally(()=>{ mountedRef.current && setIsSubmitting(false);})
        };
    }
    
    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, []);

    useEffect(()=>{
        if(leftMenu.value !== "CAMPAIGNS" && mountedRef.current)
            setLeftMenu(MENUS.find(menu=>menu.value==="CAMPAIGNS"));
    }, [leftMenu, setLeftMenu]);

    useEffect(()=>{
        if(fetchingLandingPages) return;
        if(landingPages.length === 0){
            setFetchingLandingPages(true);
            getLandingPages().finally(()=>{ mountedRef.current && setFetchingLandingPages(false)})
        }
    }, [landingPages, fetchingLandingPages, setFetchingLandingPages, getLandingPages])

    const onSuccessPopupClose = ()=>{
        mountedRef.current && setAddConfirmPopup(false);
        history.push(`${PAGE_NAV.CAMPAIGNS}/${newID}`)
    }

    return(
        <LoggedInLayout {...props} pageTitle="Add campaign">
            <div className={classNames("add-campaign","card")}>
                <h3>Add new campaign</h3>
                <div className="card-content">
                    <form className="add-campaign-form" onSubmit={handleSubmit(onSubmitHandler)}>
                        <fieldset className="input">
                            <p className={classNames("name", {error: errors?.title?.message})}>
                                <span>
                                    <label htmlFor="campaign-name">Enter campaign name</label>
                                    <input id="campaign-name" type="text" placeholder="Enter campaign name" {...register("title")} />
                                    <span className="error-msg">{errors?.title?.message}</span>
                                </span>
                            </p>

                            {/* <p className={classNames("platform")} >
                                <label htmlFor="platform">Platform you wish to promote</label>
                                <span className="select">
                                    <select name="platform" id="platform" onChange={()=>{}} >
                                        <option disabled={true} value="-1">Please select</option>
                                        <option value="rhp">Platform (RedHotPie)</option>
                                    </select>
                                </span>
                            </p> */}

                            <p className={classNames("landing-page", {error: errors?.landingPage?.message})} >
                                <label htmlFor="landing-page">Landing page</label>
                                <span className="select">
                                    <select name="landing-page" id="landing-page" {...register("landingPage")}>
                                    <option  value="">Please select</option>
                                        {
                                            landingPages.map((page, i)=> <option key={`lp_${i}`} value={page}>{page}</option>)
                                        }
                                    </select>
                                </span>
                                <span className="error-msg">{errors?.landingPage?.message}</span>
                            </p>
                        </fieldset>
                        <fieldset className="submit">
                            <p className="btn green">
                                <ButtonWithSpinner text={`Add campaign`} isLoading={isSubmitting} />
                            </p>
                        </fieldset>
                    </form>
                </div>
                <ConfirmPopup open={addConfirmPopup} close={onSuccessPopupClose} confirm={onSuccessPopupClose} btnText="Ok, thanks">
                    <p style={{textAlign: 'center'}}><img src={tickCircleGreen} alt="Tick Icon" /></p>
                    <p style={{textAlign: 'center'}}>Your campaign has been added.</p>
                </ConfirmPopup>
            </div>
        </LoggedInLayout>
    );
}

export default AddCompaign;
// import auth from 'utils/auth';
import useService from "services/auth";
// import useAccount from "./useAccount";
import useToast from './useToast';
import useAuthProvider from './useAuthProvider';

const useLogin =()=>{
    const toast = useToast();
    // const accountProvider = useAccount();
    const authProvider = useAuthProvider();
    const service = useService();

    const handleLoginResponse = async(response, isRegister=false)=>{
        const {data} = response;
        // console.log("data", data)
        authProvider.setAuthTokens(data);
        // if(!isRegister){
        //     return accountProvider.getAccount();
        // }
    }

    const login = async(data, isRegister=false)=>{
        return service.login(data)
        .then(response=> handleLoginResponse(response, isRegister))
        .catch((error) => {
            toast.error("Login Failed", error);
            throw error;
        })
    }

    return {
        login
    };
}

export default useLogin;
import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useRecoilValueLoadable } from 'recoil';
import lookupCountryAtom from 'recoil/lookup/country';
import { useForm } from "react-hook-form";
import lookupService from 'services/lookup';
import validationSchema from 'utils/validationSchemas/billingInfo';
import { yupResolver } from '@hookform/resolvers/yup';
import useAccount from "hooks/useAccount";
import ButtonWithSpinner from './buttons/ButtonSpinner';

const RegStepThree = (props) => {
    const {handlers, display, clx} = props;
    const {showBackButton=false, saveText="Save", showHeading=true} = display;
    const {postUpdateBillingInfo, onBackButtonClicked} = handlers;
    const accountProvider = useAccount();
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const countryListLoadable = useRecoilValueLoadable(lookupCountryAtom);
    const { state: countryListState, contents: countryListContent } = countryListLoadable;
    const { billingInfo, bankDetails } = accountProvider;
    const topCountries = [1, 72, 213, 2, 34];
    const { BillingName,
        Address,
        Address2,
        City,
        StateID,
        CountryID,
        ABN, // for au
        GST
    } = billingInfo;

    const { CountryID: BankCountryID } = bankDetails;

    useEffect(() => {
        switch (countryListState) {
            case "hasValue":
                setCountryList(countryListContent);
                break;
            case "hasError":
                // toast.error("Failed to load club's category", catsContent);
                break;
            case "loading":

                break;
            default:

                break;

        }
    }, [countryListState, countryListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    const populateStateList = async (cid) => {
        if (cid < 1) setStateList([]);

        lookupService.getLookupState(parseInt(cid))
            .then(response => setStateList(response.data))
            .catch((error) => {
                throw error;
                //return toast.error("Failed create account", error);
            });
    }

    const {
        handleSubmit,
        formState: { errors },
        register,
        watch,
        setValue,
        
    } = useForm({
        defaultValues: {
            billingName: BillingName,
            address: Address,
            address2: Address2,
            city: City,
            stateID: StateID,
            countryID: CountryID > 0 ? CountryID : BankCountryID,
            abn: ABN,
            gst: GST
        },
        resolver: yupResolver(validationSchema)
    });

    const wacthCountryID = watch("countryID", CountryID > 0 ? CountryID : BankCountryID);

    const onCountryChanged = (e) => {
        setValue("stateID", -1);
        populateStateList(e.target.value);
    }

    

    useEffect(() => setValue("billingName", BillingName), [BillingName, setValue]);
    useEffect(() => setValue("address", Address), [Address, setValue]);
    useEffect(() => setValue("address2", Address2), [Address2, setValue]);
    useEffect(() => setValue("city", City), [City, setValue]);
    useEffect(() => setValue("stateID", StateID), [StateID, setValue]);
    useEffect(() => setValue("countryID", CountryID), [CountryID, setValue]);
    useEffect(() => setValue("abn", ABN), [ABN, setValue]);
    useEffect(() => setValue("gst", GST), [GST, setValue]);

    useEffect(() => {
        if (CountryID > 0) {
            populateStateList(CountryID);
        }
        else if (BankCountryID > 0) {
            populateStateList(BankCountryID);
        }
        // eslint-disable-next-line
    }, []);

    const handleBackStep = () => {
        if(onBackButtonClicked && typeof onBackButtonClicked === "function") onBackButtonClicked();
    }

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = async (frmData, e) => {
        e.preventDefault();
        
        if(isSubmitting) return;
        setIsSubmitting(true);

        const submittedData = {
            BillingName: frmData.billingName,
            Address: frmData.address,
            Address2: frmData.address2,
            City: frmData.city,
            StateID: frmData.stateID,
            CountryID: frmData.countryID,
            ABN: frmData.countryID===1 ? frmData.abn : '', // for au
            GST: frmData.gst
        }

        accountProvider.updateBillingInfo(submittedData)
        .then(()=>{
            if(postUpdateBillingInfo && typeof postUpdateBillingInfo ==="function")
                postUpdateBillingInfo();
        })
        .finally(()=>{
            setIsSubmitting(false);
        });
    }

    return (
        <form className={clx} onSubmit={handleSubmit(onSubmitHandler)}>
            <div className="step three">
                {showHeading && <h2>Billing information</h2>}

                <fieldset className="input">

                    <p className={classNames("billing-name", { error: errors?.billingName?.message })} >
                        <label htmlFor="billing-name">Billing name</label>
                        <input id="billing-name" type="text" placeholder="Billing name" {...register("billingName")} />
                        {errors?.billingName?.message && <span className="error-msg">{errors?.billingName?.message}</span>}
                    </p>

                    <p className={classNames("country-billing", { error: errors?.countryID?.message })} >
                        <label htmlFor="country-billing">Country</label>
                        <span className="select">
                            <select name="country-billing" id="country-billing"
                                {...register("countryID", { onChange: onCountryChanged })}  >
                                <option disabled={true} value="-1">Please select</option>
                                {
                                    countryList
                                    .filter(itm => topCountries.includes(itm.ItemID))
                                    .sort((a, b) => a.ItemName > b.ItemName ? 1 : -1)
                                    .map((country) => (
                                        <option key={`country_${country.ItemID}`} value={country.ItemID}>{country.ItemName}</option>
                                    ))
                                }
                                <option disabled={true} value={-2}>-------------------------------------</option>
                                {
                                    countryList
                                    .filter(itm => !topCountries.includes(itm.ItemID))
                                    .sort((a, b) => a.ItemName > b.ItemName ? 1 : -1)
                                    .map((country) => (
                                        <option key={`country_${country.ItemID}`} value={country.ItemID}>{country.ItemName}</option>
                                    ))
                                }
                            </select>
                        </span>
                        {errors?.countryID?.message && <span className="error-msg">{errors?.countryID?.message}</span>}
                    </p>


                    <p className={classNames("address", { error: errors?.address?.message })} >
                        <label htmlFor="address">Address</label>
                        <input id="address" type="text" placeholder="address 1" {...register("address")} />
                        {errors?.address?.message && <span className="error-msg">{errors?.address?.message}</span>}

                    </p>
                    <p className={classNames("address2")} >
                        <input id="address2" type="text" placeholder="address 2" {...register("address2")} />
                    </p>

                    <p className={classNames("city", { error: errors?.city?.message })} >
                        <label htmlFor="city">City</label>
                        <input id="city" type="text" placeholder="city" {...register("city")}/>
                        {errors?.city?.message && <span className="error-msg">{errors?.city?.message}</span>}
                    </p>

                    <p className={classNames("state-billing", { error: errors?.stateID?.message })} >
                        <label htmlFor="state-billing">State billing</label>
                        <span className="select">
                            <select name="state-billing" id="state-billing"
                                {...register("stateID")} >
                                <option disabled={true} value="-1">Please select</option>
                                {
                                    stateList
                                    .filter(itm => itm.ItemnName !== '')
                                    .sort((a, b) => a.ItemName > b.ItemName ? 1 : -1)
                                    .map((state) => (
                                        <option key={`state_${state.ItemID}`} value={state.ItemID}>{state.ItemName}</option>
                                    ))
                                }
                            </select>
                        </span>
                        {errors?.stateID?.message && <span className="error-msg">{errors?.stateID?.message}</span>}
                    </p>
                    {
                        wacthCountryID && parseInt(wacthCountryID)===1 &&
                        <>
                            <p className={classNames("abn", { error: errors?.abn?.message })} >
                                <label htmlFor="abn">ABN</label>
                                <input id="abn" type="text" placeholder="abn" {...register("abn")}/>
                                {errors?.abn?.message && <span className="error-msg">{errors?.abn?.message}</span>}
                            </p>

                            <p className={classNames("gst")}>
                                <span className="checkbox">
                                    <input type="checkbox" id="gst" name="gst" {...register("gst")} /><label htmlFor="gst">GST</label>
                                </span>
                            </p>
                        </>
                    }
                    

                </fieldset>
            </div>
            <fieldset className="submit">
                {
                    showBackButton &&
                    <p className="btn link"><a href="/" title="Click to go back" onClick={(e) => { e.preventDefault(); handleBackStep() }}><FontAwesomeIcon icon={faChevronLeft} /> Back</a></p>
                }
                <p className="btn green link">
                    <ButtonWithSpinner text={saveText} isLoading={isSubmitting} />
                </p>
            </fieldset>
        </form>

    );

}

export default RegStepThree;
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    paymentType: Yup.string().required("Please select one of payment options"),
    paypal: Yup.string()
                .when(['paymentType'], {
                    is: (payment) => payment==="Paypal",
                    then: Yup.string().required('Please enter paypal account'),
                    otherwise: Yup.string().notRequired().nullable()
            }),
    payID: Yup.string()
                .when(['paymentType'], {
                    is: (payment) => payment==="PayID",
                    then: Yup.string().required('Please enter your PayID'),
                    otherwise: Yup.string().notRequired().nullable()
                }),
    countryID: Yup.number()
                .test("isCountryValid", "Please select country", function(val){
                    const {paymentType} = this.parent;
                    if(paymentType !== "DirectDeposit") return true;
                    return val && parseInt(val) > 0;
                }),
    programID: Yup.number()
                    .required('Required')
                    .test('Is positive?', 'Required',(value) => value > 0),
    currency: Yup.string().test("isCurrencyValid", "Please select currency", function(val){
                    const {paymentType, countryID} = this.parent;
                    if(paymentType !== "DirectDeposit") return true;
                    if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) === 1) return true;
                    return val.trim();
                }),
    bankName: Yup.string().test("isBankNameValid", "Required", function(val){
                    const {paymentType} = this.parent;
                    if(paymentType !== "DirectDeposit") return true;
                    return val.trim();
                }),
    accountName: Yup.string().test("isAccountNameValid", "Required", function(val){
                    const {paymentType} = this.parent;
                    if(paymentType !== "DirectDeposit") return true;
                    return val.trim();
                }),
    accountNumber: Yup.string().test("isAccountNumberValid", "Required", function(val){
                    const {paymentType, countryID} = this.parent;
                    if(paymentType !== "DirectDeposit") return true;
                    if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) > 1) return true;
                    return val.trim();
                }),
    bsb: Yup.string()
            .test("isBsbExists", "Required", function(val){
                const {paymentType, countryID} = this.parent;
                if(paymentType !== "DirectDeposit") return true;
                if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) > 1) return true;
                return val.trim();
            })
            .test("isBsbValid", "Invalid BSB", function(val){
                const {paymentType, countryID} = this.parent;
                if(paymentType !== "DirectDeposit") return true;
                if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) > 1 || !val) return true;
                return val.trim().match(/^\d{3}(-?|\s?)\d{3}$/);
            }),
    iban: Yup.string().test("isIbanValid", "Required", function(val){
        const {paymentType, countryID} = this.parent;
        if(paymentType !== "DirectDeposit") return true;
        if(!countryID || parseInt(countryID) < 1 || parseInt(countryID)=== 1) return true;
        return val.trim();
    }),
    swiftCode: Yup.string().test("isSwiftValid", "Required", function(val){
        const {paymentType, countryID} = this.parent;
        if(paymentType !== "DirectDeposit") return true;
        if(!countryID || parseInt(countryID) < 1 || parseInt(countryID) === 1) return true;
        return val.trim();
    })
});

export default validationSchema;

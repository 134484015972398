import React from 'react';
// import classNames from 'classnames';
// import LoginHeader from "../components/LoginHeader";

const TC = (props) => {
    //const {history} = props;

    return (
        <>
            <div className="section tc">
                <header className="tool-bar">
                    <h1 variant="headline">Terms of use</h1>
                </header>
                <div className="content">
                
                <h2>AFFILIATE PROGRAM AGREEMENT</h2>

                <p>THIS AGREEMENT (as defined in the definitions section below) is entered into by you the person or entity named in the Application To Join and described in this agreement as the 'Affiliate',  'you' and 'your' and DIGITAL QUARTER PTY LIMITED (ACN 102 421 590) described in this Agreement as 'Digital Quarter', 'us' and 'we' a company duly incorporated in the State of Western Australia, Australia and contains the terms and conditions that apply to your participation in Digital Quarter's affiliate program for RedHotPie ('The Product') 
                We can change these terms at any time. If we change these terms an online version of these terms and conditions will be updated to reflect those changes and a notice will be displayed in the news section of your affiliate account (logged in) advising that there has been changes and prompting review.</p>

                <p>Words with initial capital letters (e.g. 'Application To Join') have the meanings set out in the Definitions Section at the end of this Agreement.</p>

                <ul>
                    <li>
                        <h3>
                            1. Application To Join the affiliate program
                        </h3>
                        <ul>
                            <li>1.1 To become a member of the affiliate program, you must complete an online Application, and that application must be accepted by us. We reserve absolute discretion to reject your Application for any reason whatsoever we deem is not in the interest of The product.</li>
                            <li>1.2 You are prohibited from lodging an Application to Join if either You or any of your Affiliate Site engages in or is associated with illegal Content or Conduct.</li>
                            <li>1.3 If you are an individual you must be at least 18 years of age or the age of majority within the area that you reside.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>
                            2. Provision of promotional content 
                        </h3>
                        <ul>
                            <li>
                                2.1 As an Affiliate you will receive or have access to the promotional content which you may use for placement on your Site so that visitors may link to The product.
                            </li>
                            <li>
                                2.2 The copyright in The product, its look and feel, the material, information, profiles, photos, audio sounds and all other content of The product except for the authorised promotional content specified in 2.1 above shall not be used on an Affiliate's Sites as a link to The product unless written consent has been given by Digital Quarter.
                            </li>
                            <li>
                                2.3 As an Affiliate you may select the promotional content you wish to display from the range of authorised content we will make available under the "Resourses" tab when you log in to your account. You may place the promotional content on the Affiliate's Site in such a way as to permit visitors to the Affiliate's Sites to click on these Links and link to The product.  You may display the promotional content as often and in as many areas of the Affiliate's site as you wish, but only for the purposes contemplated in this Agreement.
                            </li>
                        </ul>
                    </li>
                    <li>
                        <h3>3. Programs</h3>
                        <ul>
                            <li>
                                <p>3.1 As an affiliate you must choose one of our three affiliate programs.  Once you elect to participate in a particular program you may not change between programs.</p>
                            </li>
                            <li>
                                <p>3.2 The three affiliate programs we offer are:</p>
                                <ul>
                                    <li>Pay per lead (join)</li>
                                    <li>Pay per Sale (subscription)</li>
                                    <li>Revenue share (percentage of sales)</li>
                                </ul>
                            </li>
                            <li>
                                <h4>3.3 Pay per lead (Join)</h4>
                                <p>
                                Under this program we track the traffic referred by you to The product and pay you a fee for each unique lead (join) referred during the period of a calendar month.  The amount payable under this program is displayed on your account when you log in.The Affiliate's entitlement to commission under this program is subject to the condition in clause 4.2 (i), (iii) and (iv) being satisfied.
                                </p>
                            </li>
                            <li>
                                <h4>3.4 Pay per Sale (Subscription)</h4>
                                <p>Under this program we track the actual number of users referred by you who purchase a subscription and we pay you a set amount per subscription irrespective of what subscription they purchase. The Affiliate's entitlement to commission under this program is subject to the condition in clause 4.2 (i), (iii) and (iv) being satisfied.</p>
                            </li>
                            <li>
                                <h4>3.5 Revenue share (percentage of sales)</h4>
                                <p>Under this program we track the actual number of users referred by you who purchase a subscription and we pay you a set percentage for everything that member spends on The product. The Affiliate's entitlement to commission under this program is subject to the condition in clause 4.2 (i), (iii) and (iv) being satisfied.</p>
                            </li>
                        </ul>
                    </li>
                    <li>
                    <h3>4. Commission</h3>
                        <li>
                            <h4>4.1 As an Affiliate we agree to pay you a 50% commission rate for each user during a calendar month that satisfies the following conditions:</h4>
                            <ul>
                                <li><b>(i) Referral of user purchasing a Paid Membership.</b> Upon clicking to The product via the promotional content to The product placed on the Affiliate's Site purchases a Paid Membership to The product and becomes a Member (described in this Agreement as the 'Referred Member'). The Affiliate's entitlement to the commission under clause 4.1(i) is subject to each of the conditions in clause 4.2 being satisfied.</li>

                                <li><b>(ii) Renewal of Paid Membership by Referred Member.</b> Who is an existing Referred Member and renews their Paid Membership to The product. The Affiliate's entitlement to this commission will cease upon the termination of this Agreement.</li>

                                <li><b>(iii) Validity of Referred Guest.</b> If Redhotpie, at its sole discretion, feel that Referred Guest/s that join The product via a link placed on the Affiliate's Site are bogus or fraudulent then Redhotpie may terminate this agreement.</li>
                            </ul>
                        </li>
                        <li>
                            <h4>4.2 The cumulative conditions for attributing commission to the Affiliate for the Referred Member or Referred Female Guest are:</h4>
                            <ul>
                            <li>(i)  The user must have been referred to The product directly via the promotional content placed on the Affiliate's Site;</li>

                            <li>(ii) The user must before clicking away or navigating away from The product have completed the purchase of the Paid Membership or completed their registration as a Guest; and </li>

                            <li>(iii) The user's link from the Affiliate's Site to The product must be recorded and verified by our internal tracking system. </li>

                            <li>(iv) In any calendar month, 2% of all traffic referred must join The product and 2% of the referred traffic who join The product must purchase a paid membership from The product. </li>

                            <li>(v) In any calendar month, your chargeback rate must not exceed 1% of your total paid membership purchases. </li>
                            </ul>
                        </li>
                        <li>
                            <h4>4.3  Commission is not payable in the following circumstances:</h4>
                            <ul>
                                <li>(i) The Link to The product is not recorded by our internal tracking system;</li>

                                <li>(ii) The user's purchase of a Paid Membership to become a Member or registration as a Guest is rejected or not completed;</li>

                                <li>(iii) A user becomes a Member or Guest without clicking through to The product via the promotional content placed on the Affiliate's Site; or</li>

                                <li>(iv) This Agreement is terminated.</li>
                            </ul>
                        </li>
                    </li>
                    <li>
                    <h3>5. Changes to commission</h3>
                        <p>We may at our sole discretion from time to time vary the rate of commission or the criteria for a commission as recorded from time to time in the Commission Schedule. We will give you notice of any variations to the rate of commission or the criteria for a commission. In the event of any variations to the rate of commission or the criteria for a commission your only recourse is to terminate this Agreement. You will be deemed to have accepted the variations if notice of termination is not received from you within 72 hours of the notice of the variation being displayed in the news section of your affiliate account (logged in) advising that there has been changes and prompting review.</p>
                    </li>
                    <li>
                    <h3>6. Payment of commision</h3>
                        <ul>
                        <li>6.1 We will pay commission to you on a monthly basis.  The payments will be made within 30 days of the end of the month.  We reserve the discretion to pay the commission either by way of:</li>
                            <ul>
                                <li>(i) A cheque drawn in favour of the name of the Affiliate as stated in the Application To Join and forwarded by ordinary post to the address for the Affiliate as recorded on the Affiliate Registration Details; or</li>
                                <li>(ii) Electronic transfer of funds to the bank account nominated by the Affiliate in the Application To Join or as varied in the Affiliate Registration Details.</li>
                            </ul>
                        <li>6.2 Commissions will be credited to the Affiliate when payment of the membership fee is cleared. All amounts are inclusive of GST except where an Affiliate is (i) hosted outside of Australia and/or its territories and (ii) does not trade inside Australia, in which case no GST applies.</li>
                        
                        <li>6.3 Affiliates based in Australia must supply an ABN (Australian Business Number).  We will not be responsible for clearing Affiliate's commission payment at their financial institutions or any fees involved.</li>

                        <li>6.4 Digital Quarter will issue (tax) invoices (where applicable) on behalf of the Affiliate in respect of the supplies specified in this Agreement.</li>

                        <li>6.5 The Affiliate agrees not to issue any tax invoice to Digital Quarter in respect of those supplies.</li>

                        <li>6.6 The Affiliate (Australian based only) acknowledges that it is registered under ATO Part 2-5 of A New Tax System (Goods and Services Tax) Act 1999 at the time that it enters into this Agreement and that it will notify Digital Quarter immediately if it ceases to be so registered.</li>

                        <li>6.7 Digital Quarter acknowledges that it is registered under the said Part 2-5 at the time that it enters into this Agreement and that it will notify the Affiliate immediately if it ceases to be so registered.</li>
                        </ul>
                    </li>
                    <li>                
                    <h3>7. Minimum commission payment</h3>
                        <p>
                        If the total of commission payable to you for any calendar month is less than AUD$100.00 we will hold the commission until the total amount payable for commissions to you is at least AUD$100.00.
                        </p>
                    </li>
                    <li>
                    <h3>8. Disputed and cancelled Membership</h3>
                        <p>
                        If a Referred Member or Referred Female Guest disputes or cancels their membership and as a result of that dispute or cancellation we are required to refund to the user the fee paid for the membership, we will deduct the commission paid previously to the Affiliate for that membership from the Affiliate's next monthly commission payment. If there are insufficient commission payments due to the Affiliate to satisfy the creditback to us, the amount then due for the creditback may recovered by us from the Affiliate as a liquidated debt that is due and payable.
                        </p>
                    </li>
                    <li>
                    <h3>9. Membership processing</h3>
                        <p>
                        We will process all applications for membership placed by users who follow the Links from the Affiliate's Site to The product.  We reserve the right to reject applications for membership that do not comply with terms and conditions, policies or rules we may have periodically or may in the future establish.  We will be solely responsible for all aspects of membership processing and fulfilment, including, but not limited to, payment processing, shipping, cancellations, returns and related customer service.
                        </p>
                    </li>
                    <li>    
                    <h3>10. Reports</h3>
                        <p>
                        We will track the volume and amount of sales generated by the Affiliate's Site and will make unaudited reports summarising this sales activity, available to you through Affiliate Quarter website. The form, content and frequency of the reports may vary from time to time in our discretion.  To permit accurate tracking, reporting and fee accrual you must ensure that the Links between the Affiliate's Site and The product are properly formatted.
                        </p>
                    </li>
                    <li>  
                    <h3>11. Licence</h3>
                        <p>During the term of this Agreement we grant to you and subject to the terms and conditions of this  Agreement a non-exclusive, non-transferable, world-wide, payment free licence to:</p>
                        <ul>
                            <li>(i)	Place the promotional content on Affiliate's Site;</li>
                            <li>(ii) Permit Visitors to the Affiliate's Site to access The product through the promotional content, solely in accordance with the terms of this Agreement;</li>
                            <li>(iii) Use our Intellectual Property Rights in the Links for the sole purpose of linking to The product.</li>
                        </ul>
                    </li>
                    <li>
                    <h3>12. Ownership of user</h3>
                        <p>Any user who becomes either a Member or Guest through the Link from the Affiliate's Site will be deemed to be a customer of The product.</p>
                    </li>
                    <li>
                    <h3>13. Term and termination</h3>
                        
                        <p>13.1 The term of this Agreement will begin on the date we accept your Application To Join and will continue in full force and effect until terminated.</p>

                        <p>13.2 Each party shall have the right in its sole discretion for any reason whatsoever to terminate this Agreement at any time by giving the other party notice of termination.  The termination of this Agreement by us is without prejudice to any rights which have accrued to us before the date of termination.</p>

                        <p>13.3 A notice of termination of this Agreement by us shall be deemed to be effective at the time it is sent, even if the email is not read by you.</p>

                        <p>13.4 Upon termination of this Agreement the Licence to use the promotional content shall automatically terminate. Within 24 hours of termination of this Agreement you will remove all Links from the Affiliate's Site and will destroy or erase from your computer memory any items containing the Links and/or our Intellectual Property Rights.</p>

                        <p>13.5 Following termination of this Agreement we will reconcile and pay any unpaid commissions.  You will not be entitled thereafter to any further commission for any sales, resales or click through activity generated by the Affiliate's Site. If this Agreement is terminated by us on the grounds you or the Affiliate's Site engages in or is associated with Prohibited Content and Conduct then any and all commissions unpaid at the time of termination will be forfeited by the Affiliate to us without prejudice to our rights to seek damages from you.</p>
                    </li>
                    <li>
                    <h3>14. Acknowledgements and Warranties by Affiliate</h3>
                        <ul>
                        <li>You acknowledge and warrant to us:</li>

                        <li>(a) All information provided by you in the Application To Join is true, complete, current and accurate at the time of submitting the Application To Join;</li>

                        <li>(b) You have the authority to enter and to perform all your obligations under this Agreement;</li>

                        <li>(c) You will notify us of any change to the details stated in your Affiliate Registration Details and update those details within 24 hours of any change;</li>

                        <li>(d) Neither you or the Affiliate's Site do or will engage in Prohibited Content and Conduct.</li>

                        <li>(e) We may monitor the Affiliate's Site from time to time to verify you are complying with the terms and conditions of this Agreement;</li> 

                        <li>(f) We own exclusively our Intellectual Property Rights in the promotional content.</li>
                        
                        <li>(g) You are solely responsible for the development, maintenance, operation and content of the Affiliate's Site and related costs;</li>

                        <li>(h). You will not purchase or acquire rights to keywords for online search engines or domain names using the words 'RedHotPie' or any variations to that spelling or derivatives of that spelling.</li>
                        </ul>
                    </li>
                    <li>
                    <h3>15. Prohibited Content and Conduct</h3>
                        
                        <p>You must not engage in any conduct or permit to be used the Affiliate's Site or the Links in any way:</p> 
                        
                        <ul>
                        <li>(a) Breach of law. Which results in you or us breaching, or being involved in a breach of a law, order or regulation (including a foreign law, order or regulation), a mandatory code of conduct; or a voluntary code of conduct that you have agreed to comply with;</li>

                        <li>(b) Damage to property or people. Which results, or could result, in damage to property or injury to any person or to harass, menace or stalk people;</li> 

                        <li>(c) Protection of minors. Which enables a minor to access material inappropriate for a minor or to establish (or try to establish) contact with a minor not otherwise known to you;</li>

                        <li>(d) Discrimination.Which unlawfully incites discrimination, hate or violence towards one  person or group, for example because of their race, religion, gender or nationality;</li>

                        <li>(e) Obscene, defamatory, offensive, abusive. To send, display, access, make available, publish, distribute or be otherwise involved in material which is obscene, defamatory or is, or would be regarded by us, acting reasonably, as, in all the circumstances, offensive or which is, or which would be considered by a reasonable person to be, offensive or abusive;</li>

                        <li>(f) Illegal business practices and gambling. To engage in any misleading or deceptive business or marketing practice or that involves providing or promoting illegal pyramid selling schemes or unlawful gambling or gaming activities;</li>

                        <li>(g) Interfering with services and systems. Interferes with the proper operation of The product or any part of systems</li>

                        <li>(h) Framing. Which results in any part of The product being 'framed'.</li>

                        <li>(i) Passing off. Which creates the impression that the Affiliate's Site is part of The product.</li>

                        <li>(j) Endorsement. Which implies or gives the impression that we sponsor or endorse the Affiliate's Site.</li> 

                        <li>(k) Reformatting. Which results in the reformatting, altering, modifying or changing in any way the Links.</li>

                        <li>(l) Design and Functionality. Which results in the use of the Links in any manner that alters or interferes with the design and functionality of The product.</li>

                        <li>(m) Keywords and similar domain names. Which results in the purchase of or licensing of similar domain names to or search terms on online search engines using words "Red Hot Pie" or any variations to that spelling or derivatives of that spelling.</li>

                        <li>(n) SPAM. You must not use the promotional content to send, cause the sending of or otherwise be involved in the sending of, SPAM or USENET SPAM; SPAM means</li> 
                        
                        <li>(i)	unsolicited commercial electronic messages as defined in the Spam Act 2003 (Cth) sent in breach of the Spam Act; or</li>
                        
                        <li>(ii) Bulk electronic messages of any kind (including email, fax, SMS and ICQ messages). A bulk electronic message is when you send over 20 electronic messages or an electronic message to over 20 recipients during a period of 10 consecutive minutes. USENET SPAM means Excessive electronic Newsgroup messages. Excessive means posting the same or substantially the same message ("Message") to one or more Newsgroups resulting in a Breidbart Index of 20 or more. A Breidbart Index is calculated by multiplying the number of Messages by the square root of the number of Newsgroups that they are posted to over a consecutive 45 day period.</li> 
                        
                        <li>(iii) Virus, denial of service attacks. You must not use the Links or the Affiliate's Site or attempt to use the Links or the Affiliate's Site or its server in connection with any program (including a virus, Trojan horse, worm, cancelbot, time bomb), or activity (including a Denial of Service attack), that is designed to provide or allow any form of unauthorised control of, or result in an adverse effect on, a computer, a network or data (whether the computer, network or data ours or anyone else's);</li>
                        
                        <li>(iv) Open relay and port probing. You must not use the Links or the Affiliate's Site or attempt to use the Links or the Affiliate's Site or its server to send to access or use ours or anyone else's systems, networks or data (including through open relay, port probing and the use of packet sniffers) without consent, regardless of whether or not such access or use has any adverse effect on the system, network or data;</li>
                        
                        <li>(v)	Spoofing. You must not use the Links or the Affiliate's Site or attempt to use the Links or the Affiliate's Site or its server to create, send or alter in any way and by any means (including spoofing and use of third party mail servers), the contents of an electronic message for the purpose of hiding, obscuring or deleting the source of the message or making the message appear to come from someone other than you; or</li> 
                        
                        <li>(vi) Other obligations related to SPAM and USENET SPAM. (i) You must not benefit from SPAM or USENET SPAM (which includes causing or requesting any of your details to appear in SPAM or USENET SPAM and/or receiving responses to SPAM or USENET SPAM).(ii) You must not purchase, create, use, distribute, sell or otherwise be involved in software, services or lists of sites, addresses, numbers or other identifiers of any kind (including email addresses and phone numbers) that are used to promote, send, or assist with the sending of, SPAM or USENET SPAM.(iii) If you send Bulk solicited electronic messages, then: (a) you must include in each message, an email address, telephone number, facsimile number or mailing address that can be used to notify you that the recipient no longer wishes to receive such messages from you ("Opt Out Notice"); and  (b) if you are sent an Opt Out Notice, then you must immediately comply with the notice.</li>
                        </ul>
                    </li>
                    <li>
                    <h3>16. Confidentiality</h3>
                    
                        <p>You acknowledge that you, your employees or your agents may be given access to Confidential Information during term of this Agreement. You will keep the Confidential Information confidential and will not disclose it to any third party or use it otherwise than:</p>
                        <ul>
                            <li>(i)	for the purpose of this Agreement;</li>
                            <li>(ii) as authorised in writing by the other party;</li>
                            <li>(iii) as required by any law, judicial body or governmental agency;</li>
                            <li>(iv) or by way of disclosure to your professional advisors who have agreed to keep the Confidential Information confidential.</li> 
                        </ul>
                        <p>You shall not copy any document, file or image containing Confidential Information except as necessary to perform this Agreement. On termination of this Agreement you will within 24 hours of that termination return to us or destroy all documents, files or images containing information which is at the date of termination Confidential Information of Digital Quarter. You shall ensure that your employees, agents, contractors and other persons within your control comply with this clause.</p> 
                    </li>
                    <li>
                    <h3>17. Variation</h3>
                    
                        <p>We reserve the right to vary the terms and conditions for this Agreement:</p>
                        <ul>
                            <li>(i)	with or without further notice to you; and</li>
                            <li>(ii) without giving you any explanation or justification for such change.</li> 
                        </ul>
                        <p>Your only recourse in the event of a variation is to terminate this Agreement.</p>  
                    </li>
                    <li>
                        <h3>18. Relationship between Parties</h3>
                        <p>This Agreement does not create any partnership, joint venture or agency relationship between the parties.</p> 
                    </li>
                    <li>
                        <h3>19. Limit of liability</h3>
                        <p>Digital Quarter and its respective officers, employees and agents have no liability for any costs, losses or damages of any kind, which you may incur, arising whether directly or indirectly. This applies in relation to or in connection with the linking to The product and/or the placing of Links on the Affiliate's site.</p> 
                    </li>
                    <li>
                        <h3>20. Indemnity</h3>
                        <p>You will at all times indemnify and keep indemnified Digital Quarter and its respective officers, employees and agents (in this paragraph referred to as 'those indemnified') from and against any loss (including reasonable legal costs and expenses) or liability incurred by any of those indemnified arising from any claim, demand, suit, action or proceeding by any person against any of those indemnified where such loss or liability arose out of, in connection with or in respect of any breach of this Agreement by you.</p>  
                    </li>
                    <li>
                        <h3>21. Assignment</h3>
                        <p>We may assign our interest under this Agreement without your consent. You may not assign your rights or obligations under this Agreement.</p>
                    </li>
                    <li>
                        <h3>22. Inconsistencies</h3> 
                        <p>The terms of this Agreement prevail in the event of any inconsistency between this Agreement, the Application To Join and the Commission Schedule.</p> 
                    </li>
                    <li>
                        <h3>23. Severability</h3> 
                        <p>If any part of this Agreement is found to be void, unlawful, or unenforceable then that part will be deemed to be severable from the balance of this Agreement and the severed part will not attract the validity and enforceability of any remaining provisions.</p>
                    </li>
                    <li>
                        <h3>24. Governing Law</h3>
                        <p>This Agreement will be governed by and interpreted in accordance with the law of the State of Western Australia, Australia, without giving effect to any principles of conflicts of laws. You agree to the jurisdiction of the courts of Western Australia, Australia to determine any dispute arising out of this Agreement.</p>  
                    </li>
                    <li>
                        <h3>25. Notices</h3>
                        <p>A notice under this Agreement will be in legible writing and in English and must be given to the other party by email. In the case of Digital Quarter notices must be sent to admin@affiliatequarter.com and in the case of the Affiliate notices must be sent to the email address stated at the time in the Affiliate Registration Details.</p>   
                    </li>
                    <li>
                        <h3>26. Waiver</h3>
                        <p>No right under this Agreement is waived or deemed to be waived by us except by notice in writing waiving the right. A waiver by us does not prejudice our rights in respect of any subsequent breach of this Agreement by the other party.</p>  
                    </li>
                    <li>
                        <h3>27. Survival</h3> 
                        <p>The terms of this Agreement survive its termination to the extent permitted by law.</p>
                    </li>
                    <li>
                        <h3>28. Legal Advice</h3>
                        <p>The Affiliate acknowledges that in relation to this Agreement it has received legal advice or has had the opportunity of obtaining legal advice.</p>
                    </li>
                    <li>
                        <h3>29. Applicable Law</h3>
                        <p>The affiliate acknowledges that the courts of Western Australia shall have the sole jurisdiction to resolve any disputes between Digital Quarter and the affiliate.</p>
                    </li>
                    <li>
                        <h3>30. Definitions section</h3>
                        
                        <p>"Affiliate" means the person or entity identified in the field labelled 'Name' in the Application To Join "Affiliate Registration Details" means the page maintained on The product for each Affiliate containing information provided by the Affiliate in the Application To Join and any changes subsequently made by the Affiliate.</p>
                        <p>"Affiliate's Site" means the Affiliate's website or websites as listed in the Application To Join or as thereafter changed by the Affiliate and recorded in the Affiliate Registration Details.</p>  
                        <p>"Agreement" means the terms and conditions of this agreement, the Application to Join, the promotional content and the Commission Schedule.</p> 
                        <p>"Application To Join" means the online form on The product at http://affiliate.redhotpie.com.au/Affiliate/SignUp.aspx to be completed any prospective affiliate.</p>
                        <p>"Commission Schedule" means the schedule on The product at http://affiliate.redhotpie.com.au/Affiliate/packages.aspx </p>

                        <p>"Confidential Information" means, but is not limited to:</p>
                        <ul>
                        <li>(i)	the company's financial information results;</li>
                        <li>(ii)	information about the company's products and services (including future products and services);</li>
                        <li>(iii)	information about the company's pricing;</li>
                        <li>(iv)	information about the company's suppliers and customers;</li> 
                        <li>(v)	information about the company's plans, strategies and forecasts; and </li>
                        <li>(vi)	other information designated as confidential by the company. </li>
                        </ul>
  

                        <p>"Guest" means a person that is a registered user and is not a paid Member.</p>  
                        <p>"GST" means any goods and services or value added tax, or other like indirect tax by whatever name called, imposed under the A New Tax System (Goods and Services Tax) Act 1999 Cwlth, or any successor legislation.</p>  
                        <p>"Intellectual Property Rights" means any copyright, moral rights, rights in a design, know-how, confidential information and all other intellectual property rights whether registered or not.</p> 
                        <p>"Promotional content" means the authorised gif banners, html search banners and text links that are made available from time to time for downloading by affiliates from The product.</p>
                        <p>"Member" means a registered user of The product with a valid and current Paid subscription.</p>
                        <p>"The product" means the RedHotPie website &amp; Apps.</p>   
                        <p>"Paid Membership" means one of the classes or categories of membership offered to The product for which payment is required.</p> 
                        <p>"Prohibited Content and Conduct" has meaning as defined in clause 15.</p> 
                        <p>"Referred Female Guest" has meaning as defined in clause 4.1(ii)</p> 
                        <p>"Referred Member" has meaning as defined in clause 4.1(i)</p>
                        </li>
                    
                    </ul>
                </div>
            </div>
        </>
    );
}

export default TC;
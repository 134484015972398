import React from 'react';
import FooterLinks from 'components/FooterLinks';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Footer =(props) => {
    const {loggedin=false} = props;
    return (
        // <div className="footer">
        //     <FooterLinks />
        // </div>
        <div className="section footer">
            <div className="copyright">
                <p> Copyright 2021 | Affiliate Quarter | All Rights Reserved | Powered by <a href="https://digitalquarter.com/">Digital Quarter</a></p>
            </div>

           <FooterLinks loggedin={loggedin} />

            {/* <div className="social">
                <p><a href="/" onClick={e=>e.preventDefault()} className="facebook"><span><FontAwesomeIcon icon={faFacebookF} /></span></a></p>
                <p><a href="/" onClick={e=>e.preventDefault()} className="twitter"><span><FontAwesomeIcon icon={faTwitter} /></span></a></p>
            </div> */}
        </div>
    )
}

export default Footer
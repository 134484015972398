import {atom} from "recoil";
// import keys from "configs/selectors";
import akeys from "configs/atoms";
// import campaignDateRangeIDAtom from "./campaignDateRangeID";
// import StatsService from "services/stats";

// const campaignsSelector = selector(({
//     key: keys.Statistic.Campaigns,
//     get: async({get})=> {
//         const dateRangeID = get(campaignDateRangeIDAtom);
//         const response = await StatsService.getCampaign(dateRangeID);
//         return response.data;
//     },
//     cachePolicy_UNSTABLE: {    // Only store the most recent set of dependencies and their values    
//         eviction: 'most-recent',  
//     },
// }));

const campaignsAtom = atom(({
    key: akeys.Statistic.Campaigns,
    default:null,
    // get: async({get})=> {
    //     const dateRangeID = get(campaignDateRangeIDAtom);
    //     const response = await StatsService.getCampaign(dateRangeID);
    //     return response.data;
    // },
  
}));

export default campaignsAtom;
import { atom} from "recoil";
import atomKeys from "configs/atoms";
import bankAccountAtom from "./bankDetails";

const initState = {
    AffiliatePaymentTypeID : "Paypal",
    Paypal : "",
    PaymentID: "",
    BankDetails: bankAccountAtom,
    ProgramID: -1
}

const paymentInfoAtom = atom({
    key: atomKeys.Account.PaymentInfo,
    default: initState
});

export default paymentInfoAtom;
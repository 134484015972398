import React, { useEffect, useState} from 'react';
import classNames from "classnames";
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS } from 'configs/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";

import PaymentInfoForm from "components/PaymentInfoForm";
import BillingInfoForm from "components/BillingInfoForm";
import EditProfileForm from 'components/EditProfileForm';

// import UpdateMobileNumber from "components/modals/UpdateMobileNumber";

const Account = (props) => {
    const [leftMenu, setLeftMenu] = useLeftMenu();

    useEffect(()=>{
        if(leftMenu.value !== "ACCOUNT")
            setLeftMenu(MENUS.find(menu=>menu.value==="ACCOUNT"));
    }, [leftMenu, setLeftMenu]);

    const [accOneOpen, setAccOneOpen] = useState(true);
    const [accTwoOpen, setAccTwoOpen] = useState(false);
    const [accThreeOpen, setAccThreeOpen] = useState(false);

    // const [mobiNoPop, setMobiNoPop] = useState(false);


    // /console.log("contentOne: ",contentOne.current.scrollHeight);

    return (
        <LoggedInLayout {...props}>

                <div className={classNames("acc","card", "accordion", {open: accOneOpen})}>
                    <div className="accordion-header" onClick={()=>setAccOneOpen(!accOneOpen)}>
                        <h2>Account details</h2>
                        {accOneOpen 
                            ? <FontAwesomeIcon icon={faMinus} />
                            : <FontAwesomeIcon icon={faPlus} />
                        }
                    </div>
                    <div className={classNames("accordion-content")}>
                        <EditProfileForm />
                    {/* <form className="acc-form" onSubmit={()=> {}}>
                        <div className="user-details">
                            <fieldset className="input">
                                <p className={classNames("name", {error: true})}>
                                    <span>
                                        <label htmlFor="affiliate-name">Affiliate name</label>
                                        <input id="affiliate-name" type="text" placeholder="Affiliate name" />
                                        <span className="error-msg">Please enter a Affiliate name</span>
                                    </span>
                                </p>

                                <p className={classNames("number", {error: true})} >
                                    <span>
                                        <label htmlFor="mobile-number">Mobile number</label>
                                        <input id="mobile-number" type="text" placeholder="Mobile number" />
                                        <span className="error-msg">Please enter a mobile-number</span>
                                    </span>
                                    <span className="update"><a href="/" onClick={(e)=>{e.preventDefault(); setMobiNoPop(true);}} title="Update mobile number">Update mobile number</a></span>
                                </p>

                                <p className={classNames("email", {error: true})}>
                                    <span>
                                        <label htmlFor="email" >Email</label>
                                        <input id="email" type="email" placeholder="Email"/>
                                        <span className="error-msg">Please enter your email</span>
                                    </span>
                                </p>

                                <p className={classNames("pass", {error: true})}>
                                    <span>
                                        <label htmlFor="pass" >Password</label>
                                        <input id="pass" type="password" placeholder="Password"/>
                                        <span className="error-msg">Please enter new password</span>
                                    </span>
                                    <span className="update"><a href="/" title="Update password">Update password</a></span>
                                </p>

                            </fieldset>
                        </div>
                        <fieldset className="input">
                            <div className={classNames("checkbox-group", {error: true})}>
                                <p>Where will you promote RedHotPie?</p>

                                <p className="website">
                                    <span className="checkbox">
                                        <input type="checkbox" id="website" name="website" /><label htmlFor="website">Website</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="web-url">Please enter your website URL</label>
                                        <input id="web-url" type="text" placeholder="Please enter your website URL" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>

                                <p className="instagram">
                                    <span className="checkbox">
                                        <input type="checkbox" id="instagram" name="instagram" /><label htmlFor="instagram">Instagram</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="instagram-url">Please enter your Instagram URL</label>
                                        <input id="instagram-url" type="text" placeholder="Please enter your Instagram URL" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>

                                <p className="tiktok">
                                    <span className="checkbox">
                                        <input type="checkbox" id="tiktok" name="tiktok" /><label htmlFor="tiktok">TikTok</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="tiktok-url">Please enter your Tiktok channel URL</label>
                                        <input id="tiktok-ur" type="text" placeholder="Please enter your Tiktok channel URL" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>
                                <p className="youtube">
                                    <span className="checkbox">
                                        <input type="checkbox" id="youtube" name="youtube" /><label htmlFor="youtube">Youtube</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="youtube-url">Please enter your Youtube channel URL</label>
                                        <input id="youtube-url" type="text" placeholder="Enter your Youtube channel url" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>
                                <p className="facebook">
                                    <span className="checkbox">
                                        <input type="checkbox" id="facebook" name="facebook" /><label htmlFor="facebook">Facebook</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="facebook-url">Please enter your Facebook URL</label>
                                        <input id="facebook-url" type="text" placeholder="Please enter your Facebook URL" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>

                                <p className="only-fans">
                                    <span className="checkbox">
                                        <input type="checkbox" id="only-fans" name="only-fans" /><label htmlFor="only-fans">Only fans</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="only-fans-url">Please enter your Instagram URL</label>
                                        <input id="only-fans-url" type="text" placeholder="Please enter your Instagram URL" />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>

                                <p className="other">
                                    <span className="checkbox">
                                        <input type="checkbox" id="other" name="other" /><label htmlFor="other">Other</label>
                                    </span>
                                    <span className="input">
                                        <label htmlFor="other-url">Eg Email marketing, Google Adwords ...etc </label>
                                        <input id="other-url" type="text" placeholder="Eg Email marketing, Google Adwords ...etc " />
                                        <span className="error-msg">Please enter a vaild url</span>
                                    </span>
                                </p>
                                <p className="error-msg">Please select option</p>
                            </div>
                        </fieldset>
                        <fieldset className="submit">
                            <p className="btn green link">
                                <ButtonWithSpinner text={`Update`} isLoading={false} />
                            </p>
                        </fieldset>
                        </form> */}
                    </div>
                    {/* <UpdateMobileNumber open={mobiNoPop} close={()=>setMobiNoPop(false)} closeOnE /> */}
                </div>
            
                <div className={classNames("acc","card", "accordion", {open: accTwoOpen})}>
                    <div className="accordion-header" onClick={()=>setAccTwoOpen(!accTwoOpen)}>
                        <h2>How would you like to get paid?</h2>
                        {accTwoOpen 
                            ? <FontAwesomeIcon icon={faMinus} />
                            : <FontAwesomeIcon icon={faPlus} />
                        }
                    </div>
                    <div className={classNames("accordion-content")} >
                        <PaymentInfoForm clx="acc-form" handlers={{postUpdatePaymentInfo: ()=>{}}} display={{saveText:"Update", showHeading:false}} />
                    </div>
                </div>

                <div className={classNames("acc","card", "accordion", {open: accThreeOpen})}>
                    <div className="accordion-header" onClick={()=>setAccThreeOpen(!accThreeOpen)}>
                        <h2>Billing information?</h2>
                        {accThreeOpen 
                            ? <FontAwesomeIcon icon={faMinus} />
                            : <FontAwesomeIcon icon={faPlus} />
                        }
                    </div>
                    <div className={classNames("accordion-content")} >
                        <BillingInfoForm clx="acc-form" handlers={{postUpdateBillingInfo:()=>{}, onBackButtonClicked:()=>{}}} display={{saveText:"Update", showHeading:false}} />
                    </div>
                </div>

        </LoggedInLayout>
    );
}

export default Account;
import React, {useState} from 'react';
import TCPopup from 'components/modals/T&CPopup'
import { Link } from 'react-router-dom';
import {PAGE_NAV} from "configs/page";
import { BrowserView, MobileView } from 'react-device-detect';
import ContactSupportPopup from "components/modals/ContactSupportPopup";

const FooterLinks = (props) => {

    const {loggedin=false} = props;

    const [showTCPopup, setShowTCPopup] = useState(false);
    const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);

    return (
        <>
            <ul className="links">
                {/* <li><a href="/" title="Click to find out more">About</a></li> */}
                <li><a href="/" onClick={(e)=>{e.preventDefault(); setShowTCPopup(true)}} title="Click to find out more">Terms &amp; conditions</a></li>
                <li>
                    {/* <a href="/" title="Click to find out more">Contact</a> */}
                    <BrowserView>
                    <a href="/" onClick={(event)=>{event.preventDefault(); setShowContactSupportPopup(true)}} title="Click to contact">Contact</a>
                    <ContactSupportPopup open={showContactSupportPopup} close={()=>setShowContactSupportPopup(false)} postProcFunc={()=>{}} loggedin={loggedin} />
                    </BrowserView>
                    <MobileView>
                    <Link to={PAGE_NAV.CONTACTSUPPORT} title="Click to contact">Contact</Link>
                    </MobileView>
                    </li>
            </ul>
            <TCPopup open={showTCPopup} close={()=>setShowTCPopup(false)} />
        </>
    )
}

export default FooterLinks;            
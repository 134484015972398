import React, {useEffect} from "react";
import TicketDetailDisplay from "components/TicketDetail";
import LoggedInLayout from 'layouts/LoggedInPlainLayout';
import { useParams } from 'react-router';
import {PAGE_NAV} from "configs/page";

const TicketDetail = (props) => {
    const {history} = props;
    const goBackToList = ()=> history.replace(PAGE_NAV.TICKETLIST);
    let { ticketRef } = useParams();

    useEffect(()=>{
        if(!ticketRef) history.replace(PAGE_NAV.TICKETLIST);
    }, [ticketRef, history])

    return (
        <LoggedInLayout {...props}>
            <TicketDetailDisplay isOpenInPopup={false} onBackClicked={goBackToList} ticketRef={ticketRef} {...props}/>
        </LoggedInLayout>
    );
        
}

export default TicketDetail;


import React, {useState} from 'react';
import classNames from 'classnames';
import LoginHeader from "../components/LoginHeader";
import { Link } from 'react-router-dom';
import {PAGE_NAV} from "configs/page";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import valUtils from "utils/validations";
import useLogin from 'hooks/useLogin';
import ButtonWithSpinner from 'components/buttons/ButtonSpinner';
import FooterLinks from 'components/FooterLinks';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter } from "@fortawesome/free-brands-svg-icons";

const Login = (props) => {
    const {history} = props;
    // form validation rules 
    const validationSchema = Yup.object().shape({
        password: valUtils.password(),
        email: valUtils.notUniqueEmail(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState: { errors } } = useForm(formOptions);
    const {login} = useLogin();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmitHandler = async(frmData) => {
        if(isSubmitting) return; 

        setIsSubmitting(true);
        
        const {email, password} = frmData;
       
        login({UserName: email, Password: password})
        .then(()=>{
            //redir manually
            setIsSubmitting(false)
            history.push(PAGE_NAV.DASHBOARD);
        })
        .catch(() => {
            setIsSubmitting(false)
        });
        
    }

    return (
        <>
            <div className="section login">
                <LoginHeader />
                <div className="content">
                    {/* <p className="apple-login btn link"><a href="/" onClick={e=>e.preventDefault()} title="Click to sign in with Apple">Sign in with Apple</a></p>
                    <p className="facebook-login btn link"><a href="/" onClick={e=>e.preventDefault()} title="Click to sign in with Facebook">Sign in with Facebook</a></p>
                    <p className="or">Or</p> */}
                    <form className="login-form" onSubmit={handleSubmit(onSubmitHandler)}>
                        <fieldset className="input">
                            <p  className={classNames("email", { error: errors?.email?.message })}>
                                <input type="email" autoComplete="email"  placeholder="Email" {...register("email")}/>
                                {errors?.email?.message && <span className="error-msg">{errors?.email?.message}</span>}
                            </p>
                            <p  className={classNames("name", { error: errors?.password?.message })}>
                                <input type="password" autoComplete="current-password" placeholder="Password" {...register("password")}/>
                                {errors?.password?.message && <span className="error-msg">{errors?.password?.message}</span>}
                            </p>
                        </fieldset>
                        <fieldset className="submit">
                            <p className="btn green">
                                <ButtonWithSpinner text="Login" isLoading={isSubmitting} />
                            </p>
                        </fieldset>
                        <p><Link to={PAGE_NAV.FORGOT_PWD} title="Click here to reset password">Forgot password?</Link></p>
                        <p>Not a member? <Link to={PAGE_NAV.JOIN} title="Click here to join">Join now</Link></p>
                    </form>
                </div>
                {/* <div className="footer">
                    <div className="content">
                        <p className="powered-by">Powered by Digital Quarter</p>
                        <FooterLinks />
                    </div>
                </div> */}
                <div className="section footer">
                    <div className="copyright">
                        <p> Copyright 2021 | Affiliate Quarter | All Rights Reserved | Powered by <a href="https://digitalquarter.com/">Digital Quarter</a></p>
                    </div>

                    <FooterLinks />

                    <div className="social">
                        <p><a href="/" onClick={e=>e.preventDefault()} className="facebook"><span><FontAwesomeIcon icon={faFacebookF} /></span></a></p>
                        <p><a href="/" onClick={e=>e.preventDefault()} className="twitter"><span><FontAwesomeIcon icon={faTwitter} /></span></a></p>
                    </div>
                </div>
            </div>
        
        </>
    );
}

export default Login;
import useFetchProvider from 'hooks/useFetchProvider';
import { publicFetch, customFetch } from './fetch';

const AccountService = ()=> {
    const fetch = useFetchProvider();

    const getAccount = async() => await fetch.get(`/Account/`);
    const createAccount = async(data) => await publicFetch.post(`/Account`, data); 
    const updateProfileDetails= async(data) => await fetch.patch(`/Account/ProfileDetails`, data);
    const updatePhone = async(data)=> await fetch.patch(`/Account/Phone`, data);
    const validatePhone = async(data) => await fetch.post(`/Account/Phone`, data);
    const updatePaymentInfoDetails = async(data) => await fetch.post(`/Account/PaymentInfoDetails`, data);
    const updateBillingInfoDetails = async (data) => fetch.post(`/Account/BillingInfoDetails`,data);
    const updatePassword = async(data) => await fetch.patch(`/Account/Password`, data);
    const requestEmailPasswordReset= async(data) => await customFetch.post(`/User/Account/PasswordReset/Email`, data);
    const submitPasswordReset = async(data)=> await customFetch.patch(`/User/Account/PasswordReset`, data);
    const validatePasswordResetToken = async(data)=> await customFetch.post(`/User/Account/PasswordReset/Token`, data);
    const requestTextPasswordReset = async(data)=> await customFetch.post(`/User/Account/PasswordReset/SendTxtMsgCode`, data);
    const validateTextPasswordResetCode = async(data)=> await customFetch.post(`/User/Account/PasswordReset/ValidateTxtMsgCode`, data);
    const requestValidationCode = async(data)=> await customFetch.post(`/Account/Phone/Send`, data);
    const verfifyPhoneValidationCode = async(data)=> await customFetch.post(`/Account/Phone/Verify`, data);

    return {
        getAccount,
        createAccount,
        updateProfileDetails,
        updatePhone,
        validatePhone,
        updatePaymentInfoDetails,
        updateBillingInfoDetails,
        updatePassword,
        requestEmailPasswordReset,
        submitPasswordReset,
        validatePasswordResetToken,
        requestTextPasswordReset,
        validateTextPasswordResetCode,
        requestValidationCode,
        verfifyPhoneValidationCode
    };
}

export default AccountService;
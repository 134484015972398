import * as authSettings from "configs/api";

class AuthenticationUtil {

  static storeTokens(tokens){
    
    try {
      localStorage.setItem(authSettings.ACCESSTOKEN_KEY, tokens.BearerToken);
      localStorage.setItem(authSettings.REFRESHTOKEN_KEY, tokens.RefreshToken);
    }
    catch (err){
      sessionStorage.setItem(authSettings.ACCESSTOKEN_KEY, tokens.BearerToken);
      sessionStorage.setItem(authSettings.REFRESHTOKEN_KEY, tokens.RefreshToken);
    }
  }

  static storeBearerToken(token){
    try {
      localStorage.setItem(authSettings.ACCESSTOKEN_KEY, token);
      
    }
    catch(err){
      sessionStorage.setItem(authSettings.ACCESSTOKEN_KEY, token);
    }
  }

  static storeRefreshToken(token){
    try {
      localStorage.setItem(authSettings.REFRESHTOKEN_KEY, token);
    }
    catch (err){
      sessionStorage.setItem(authSettings.REFRESHTOKEN_KEY, token);
    }
  }

  static isLoggedIn() {
    return (!!(localStorage[authSettings.ACCESSTOKEN_KEY] !== 'undefined') 
    //&&  !!(localStorage[authSettings.REFRESHTOKEN_KEY] !== 'undefined')
    &&  !!localStorage[authSettings.ACCESSTOKEN_KEY] 
    //&&  !!localStorage[authSettings.REFRESHTOKEN_KEY]
    ) 
  || 
       (!!(sessionStorage[authSettings.ACCESSTOKEN_KEY] !== 'undefined') 
    //&&  !!(sessionStorage[authSettings.REFRESHTOKEN_KEY] !== 'undefined')
    &&  !!sessionStorage[authSettings.ACCESSTOKEN_KEY] 
    //&&  !!sessionStorage[authSettings.REFRESHTOKEN_KEY]
    );
  }

  static clearTokens() {
    sessionStorage.removeItem(authSettings.ACCESSTOKEN_KEY);
    sessionStorage.removeItem(authSettings.REFRESHTOKEN_KEY);
    localStorage.removeItem(authSettings.ACCESSTOKEN_KEY);
    localStorage.removeItem(authSettings.REFRESHTOKEN_KEY);
  }

  static getRefreshToken(){
    return sessionStorage[authSettings.REFRESHTOKEN_KEY]
    ? sessionStorage[authSettings.REFRESHTOKEN_KEY]
    : (localStorage[authSettings.REFRESHTOKEN_KEY]
      ?localStorage[authSettings.REFRESHTOKEN_KEY]
      :'');
  }

  static getAccessToken(){
    return sessionStorage[authSettings.ACCESSTOKEN_KEY]
            ? sessionStorage[authSettings.ACCESSTOKEN_KEY]
            : (localStorage[authSettings.ACCESSTOKEN_KEY]
              ? localStorage[authSettings.ACCESSTOKEN_KEY]
              : '');
  }

  static getRefreshTokenUrl(){
      return `${authSettings.API_HOST}/access-token`
  }

  static getRefreshTokenBody(){
      return {
          RefreshToken : this.getRefreshToken()
      };
  }

  static getCustomToken(){
     return "f73e1aed-8fd1-4c53-9bb0-ea1535d56f42";
  }
}

export default AuthenticationUtil;
import React from 'react';
import { Modal } from 'react-responsive-modal';

import TicketList from 'components/TicketList';

const TicketListPopup = (props) => {
    const {open, close } = props;

    return (
        <Modal classNames={{ overlay: 'ticket-list-overlay', modal: 'ticket-list-modal',}} 
            open={open} 
            onClose={close}
            showCloseIcon={false}
            center>
            <TicketList isOpenInPopup={true} onClose={close} />
        </Modal>
    );
}

export default TicketListPopup;
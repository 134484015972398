import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';
import Home from "pages/AffiliateQuarter";
import AffiliateReg from 'pages/AffiliateReg';
import Login from "pages/Login";
import Logout from "pages/Logout";
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';

import Account from 'pages/Account';
import Dashboard from 'pages/Dashboard';
import Campaigns from 'pages/Campaigns';
// import CampaignDetail from 'pages/CampaignDetail';
import AddCompaign from 'pages/AddCompaign';
import CampaignDetails from "pages/CampaignDetails";
import Resources from 'pages/Resources';
import Support from 'pages/Support';
import ContactSupportMobileView from 'pages/mobileView/ContactSupport';
import TicketList from 'pages/mobileView/TicketList';
import TicketDetail from 'pages/mobileView/TicketDetail';
import TicketMessage from 'pages/mobileView/TicketReply';
import TotalEarning from 'pages/TotalEarning';
// import auth from 'utils/auth';
import {PAGE_NAV} from "configs/page";
import TC from 'pages/T&C';
import useAuthProvider from 'hooks/useAuthProvider';
// import useLogout from 'hooks/useLogout';

// import authAtom from "recoil/account/auth";
// import { useRecoilState, useResetRecoilState } from "recoil";

const PrivateRoute = ({ component: Component, ...rest }) => {
  const {isAuthenticated} = useAuthProvider();
  return isAuthenticated 
            ? <Route {...rest} render={props => <Component {...props} />} />
            : <Route {...rest} render={() => <Redirect to={PAGE_NAV.LOGIN} />} />
          
}

const LogoutOnlyRoute = ({ component: Component, ...rest }) =>{
 
  const {isAuthenticated} = useAuthProvider();
    return isAuthenticated
          ? <Route {...rest} render={() => <Redirect to={PAGE_NAV.DASHBOARD} />} />
          : <Route {...rest} render={props => <Component {...props} />} />
}



const PageNav = ()=>{
  const {isAuthenticated} = useAuthProvider();
  // const loc = useLocation();
  
  // console.log("pathname", loc.pathname)
    return(
        
            <Switch>
              <LogoutOnlyRoute path={PAGE_NAV.HOME} exact component={Home} />
                
                <Route path={PAGE_NAV.JOIN}  component={AffiliateReg} />
                <Route path={PAGE_NAV.LOGOUT}  component={Logout} />
                <LogoutOnlyRoute path={PAGE_NAV.LOGIN}  component={Login} />
                <LogoutOnlyRoute path={`${PAGE_NAV.RESET_PWD}/:token`}  component={ResetPassword} />
                <LogoutOnlyRoute exact path={PAGE_NAV.FORGOT_PWD}  component={ForgotPassword} />
                <PrivateRoute path={PAGE_NAV.ACCOUNT}  component={Account} />
                <PrivateRoute path={PAGE_NAV.DASHBOARD}  component={Dashboard} />
                <PrivateRoute exact path={PAGE_NAV.CAMPAIGN_ADD}  component={AddCompaign} />
                <PrivateRoute path={`${PAGE_NAV.CAMPAIGNS}/:campaignID`}  component={CampaignDetails} />
                <PrivateRoute exact path={PAGE_NAV.CAMPAIGNS}  component={Campaigns} />
                {/* <PrivateRoute path="/campaigndetail"  component={CampaignDetail} /> */}
                
                <PrivateRoute path={PAGE_NAV.RESOURCES}  component={Resources} />
                {
                  isAuthenticated
                  ? <PrivateRoute exact path={PAGE_NAV.CONTACTSUPPORT}  component={ContactSupportMobileView} />
                  : <LogoutOnlyRoute path={PAGE_NAV.CONTACTSUPPORT}  component={ContactSupportMobileView} />
                }
                
                <PrivateRoute exact path={PAGE_NAV.TICKETLIST}  component={TicketList} />
                <PrivateRoute path={`${PAGE_NAV.TICKETLIST}/:ticketRef/reply`} component={TicketMessage} />
                
                <PrivateRoute path={`${PAGE_NAV.TICKETLIST}/:ticketRef`} component={TicketDetail} />
                {/* <PrivateRoute path={PAGE_NAV.TICKETDETAIL} component={TicketDetail} />
                <PrivateRoute path={PAGE_NAV.TICKETMESSAGE} component={TicketMessage} /> */}
                <PrivateRoute path={PAGE_NAV.SUPPORT}  component={Support} />
                
                <Route path={PAGE_NAV.TC}  component={TC} />
                
                <PrivateRoute path={PAGE_NAV.TOTALEARNING}  component={TotalEarning} />

                <Route path={PAGE_NAV.TC}  component={TC} />
                
                <Redirect to={PAGE_NAV.HOME} /> 
            </Switch>
        
    );
}

export default PageNav;
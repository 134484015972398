import React from 'react';
import { Modal } from 'react-responsive-modal';
import TC from 'pages/T&C'

const TCPopup = (props) => {
    const { open, close } = props;

    return (
        <Modal classNames={{ overlay: 'tc-overlay', modal: 'tc-modal', }} open={open} onClose={close} center>
            <TC />
        </Modal>
    );
}

export default TCPopup;
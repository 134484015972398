import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
//recoil/atom related
import {useRecoilValueLoadable, useRecoilCallback} from "recoil"
import earningSummaryAtom from 'recoil/stats/earningSummary';
//configs
import { PAGE_NAV } from 'configs/page';
import { MENUS } from 'configs/page';
//layout
import LoggedInLayout from 'layouts/LoggedInLayout';
//hooks
import useLeftMenu from 'hooks/useLeftMenu';
import useAccount from 'hooks/useAccount';
import useStatsService from 'services/stats';
import useToast from 'hooks/useToast';
import useCampaigns from 'hooks/useCampaigns';
//components
import CampaignList from 'components/CampaignList';
//utils
import { currencyFormatterNoDigit as formatter } from 'utils/formatter';
import moment from 'moment';
//icons
import EmptyStateGraphic from 'assets/img/dashboard-empty.svg'
import arrowIco from "assets/img/next-arrow-circle-ico-green.svg";;


const AffiliateDashboard = (props) => {
    const statsService = useStatsService();
    const earningsSummaryLoadable = useRecoilValueLoadable(earningSummaryAtom);
    const { state: earningsSummaryState, contents: earningsSummaryContent } = earningsSummaryLoadable;

    const [leftMenu, setLeftMenu] = useLeftMenu();
    const accountProvider = useAccount();
    const toast = useToast();
    const { profiles } = accountProvider;
    const { Name } = profiles;
    const mountedRef = useRef(true);
    const campaignProvider = useCampaigns();
    const {campaignsSummaryLoadable } = campaignProvider;
    const { state: campaignListState, contents: campaignListContent } = campaignsSummaryLoadable;
    const [campaignList, setCampaignList] = useState(null);
    useEffect(() => {
        switch (campaignListState) {
            case "hasValue":
                if(campaignListContent)
                    setCampaignList(campaignListContent);
                break;
            default:
                break;
        }
    }, [campaignListState, campaignListContent]) // eslint-disable-line react-hooks/exhaustive-deps

    
    useEffect(() => {
        return () => { 
          mountedRef.current = false
        }
      }, [])

    const fetchEarningSummary = useRecoilCallback(({ set }) => async () => {
        return statsService.getTotalEarnings()
        .then(response=>set(earningSummaryAtom, response.data))
        .catch(err=> {
            toast.error(err);
        });
    });
    const [isFetchingEarnings, setIsFetchingEarnings] = useState(false);

    useEffect(()=>{
        if(isFetchingEarnings) return;
        if(earningsSummaryState==="hasValue" && !earningsSummaryContent){
            setIsFetchingEarnings(true)
            fetchEarningSummary()
            .finally(()=> {if(mountedRef.current)setIsFetchingEarnings(false)});
        }
    },[earningsSummaryState, earningsSummaryContent, fetchEarningSummary, isFetchingEarnings])

    useEffect(() => {
        if (leftMenu.value !== "DASHBOARD" &&  mountedRef.current )
            setLeftMenu(MENUS.find(menu => menu.value === "DASHBOARD"));

    }, [leftMenu, setLeftMenu]);

    return (

        <LoggedInLayout {...props}>

            {
                campaignListState === "hasValue" 
                && campaignList 
                && campaignList.StatsSummaries
                && campaignList.StatsSummaries.length === 0 
                &&
                <div className="empty-state">
                    <p className="empty-ghc"><img src={EmptyStateGraphic} alt="Empty state graphic" /></p>
                    <p className="empty-msg">LET’S GET THE BALL ROLLING</p>
                    <p className="sub">Creating and launching a campaign is pretty easy and we’ll guide you through the process.</p>
                    <p className="green btn link"><Link to={PAGE_NAV.CAMPAIGN_ADD} title="Add campaign">CREATE MY FIRST CAMPAIGN</Link></p>
                </div>
            }
            {
                campaignListState === "hasValue" 
                && campaignList 
                && campaignList.StatsSummaries
                && campaignList.StatsSummaries.length > 0 
                &&
                <>
                <div className="intro">
                <h2>Welcome {Name},</h2>
                <p>Here whats happening in your Affiliate account</p>
            </div>

            <div className={classNames("section", "summary", "card-group")}>
                    
                {
                    //skeleton goes here
                    earningsSummaryState === "loading" && <div></div>
                }
                {
                    earningsSummaryState === "hasError" 
                    && <>
                        <div className="card">
                            <div className="card-header">
                                <h3>Next Payment</h3>
                            </div>
                            <div className="card-content">
                                <p className="value">$0</p>
                                <p className="go-to-link"><span>Expected payment date</span><span className="date">XX XXXX XXXX</span></p>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3>Total earnings</h3>
                            </div>
                            <div className="card-content">
                                <p className="value">$0</p>
                                <p className="go-to-link"><Link to={PAGE_NAV.TOTALEARNING} title="Click to see more"><span>See more</span> <img className="ico" src={arrowIco} alt=">" /></Link></p>
                            </div>
                        </div>
                        </>
                }
                {
                    earningsSummaryState === "hasValue"  && earningsSummaryContent
                    && <>
                        <div className="card">
                            <div className="card-header">
                                <h3>Next Payment</h3>
                            </div>
                            <div className="card-content">
                                <p className="value">{formatter.format(parseInt(earningsSummaryContent.OutstandingBalance))}.<small>{parseInt((earningsSummaryContent.OutstandingBalance % 1).toFixed(2).substring(2))}</small></p>
                                <p className="go-to-link"><span>Expected payment date</span><span className="date">{(moment().date() < 16 ? moment() : moment().startOf('month').add(1, 'M')).format("15 MMMM YYYY")}</span></p>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-header">
                                <h3>Total earnings</h3>
                            </div>
                            <div className="card-content">
                                <p className="value">{formatter.format(parseInt(earningsSummaryContent.TotalEarned))}.<small>{parseInt((earningsSummaryContent.TotalEarned % 1).toFixed(2).substring(2))}</small></p>
                                <p className="go-to-link"><Link to={PAGE_NAV.TOTALEARNING} title="Click to see more"><span>See more</span> <img className="ico" src={arrowIco} alt=">" /></Link></p>
                            </div>
                        </div>
                    </>
                }
                
            </div>
            <CampaignList />
            </>
            }  

            
            
        </LoggedInLayout>
    );
}

export default AffiliateDashboard;
import React, {useEffect} from "react";
import ReplyForm from "components/TicketReplyForm";
import useToast from "hooks/useToast";
import LoggedInLayout from 'layouts/LoggedInPlainLayout';
import { useParams } from 'react-router';
import {PAGE_NAV} from "configs/page";

const TicketReply = (props) => {
    const toast = useToast()
    const {history} = props;

    const goBackToView = ()=> history.replace(`${PAGE_NAV.TICKETLIST}/${ticketRef}`);
    let { ticketRef } = useParams();

    useEffect(()=>{
        if(!ticketRef) history.replace(PAGE_NAV.TICKETLIST);
    }, [ticketRef, history])

    const postCreateNewReply = ()=>{
        toast.success("Succesfully create reply");
        history.goBack();
        
    }

    return (
        <LoggedInLayout {...props}>
            <ReplyForm showBackBtn={true} onBackClicked={goBackToView} postProcFunc={postCreateNewReply} ticketRef={ticketRef} {...props} />
        </LoggedInLayout>
    );
        
}

export default TicketReply;


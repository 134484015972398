import {atom} from "recoil";
import keys from "configs/atoms";

const initState = {
    BillingName: "",
    Address: "",
    Address2: "",
    City: "",
    StateID: -1,
    CountryID: -1,
    ABN: "", // for au
    GST: false,// for au
}

const billingInfoAtom = atom(({
    key: keys.Account.BillingInfo,
    default: initState
}));

export default billingInfoAtom;
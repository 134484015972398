import React from 'react';
import { Modal } from 'react-responsive-modal';

const ConfirmPopup = (props) => {
    const { open, close, confirm, heading, sub, children, btnText="Ok" } = props;

    return (
        <Modal classNames={{ overlay: 'confirm-message-overlay', modal: 'confirm-message-modal', }} open={open} onClose={close} center>
            {
                heading && <h2 className="heading">{heading}</h2>
            }
            {
                sub && <p className="sub-heading">{sub}</p>
            }
            {
                children && 
                <div className="msg">
                    {children}
                </div>
            }
            <div className="action">
               <p className="btn green"><a href="/" onClick={(e)=>{e.preventDefault(); confirm();}} title="Click to confirm">{btnText}</a></p>
            </div>
        </Modal>
    );
}

export default ConfirmPopup;
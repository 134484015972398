import React, { useEffect, useState} from 'react';
import classNames from "classnames";
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import { MENUS } from 'configs/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

// import banner640x100 from "assets/img/banners/640x100.jpg";
// import banner728x90 from "assets/img/banners/728x90.jpg";
// import banner1456x180 from "assets/img/banners/1456x180.jpg";
// import banner320x1200 from "assets/img/banners/320x1200.jpg";
// import banner300x600 from "assets/img/banners/300x600.jpg";

import { dating, swingers } from 'assets/banners';

const Resources = (props) => {
    const [leftMenu, setLeftMenu] = useLeftMenu();

    const [tabIndex, setTabIndex] = useState(1);
    
    useEffect(()=>{
        if(leftMenu.value !== "RESOURCES")
            setLeftMenu(MENUS.find(menu=>menu.value==="RESOURCES"));
    }, [leftMenu, setLeftMenu]);

    return (
        <LoggedInLayout {...props}>
            <div className="resource">
            <div className="tab">
                <div className="tab-header">
                    <ul>
                        <li className={classNames({on: tabIndex === 1})} onClick={()=>setTabIndex(1)}>Dating</li>
                        <li className={classNames({on: tabIndex === 2})} onClick={()=>setTabIndex(2)}>Hookups</li>
                        <li className={classNames({on: tabIndex === 3})} onClick={()=>setTabIndex(3)}>Swingers</li>
                        <li className={classNames({on: tabIndex === 4})} onClick={()=>setTabIndex(4)}>Fetish</li>
                        <li className={classNames({on: tabIndex === 5})} onClick={()=>setTabIndex(5)}>Logo</li>
                    </ul>
                </div>
                <div className="tab-content">
                    <div className={classNames("content", {on: tabIndex === 1})}>
                        {/* <p className="banner"><img src={banner640x100} alt="banner 640px x 100px" /></p>
                        <p className="banner"><img src={banner728x90} alt="banner 728px x 90px" /></p>
                        <p className="banner"><img src={banner1456x180} alt="banner 1456 x 180" /></p>
                        <p className="banner"><img src={banner320x1200} alt="banner 320 x 1200" /></p>
                        <p className="banner"><img src={banner300x600} alt="banner 300 x 600" /></p> */}
                        {
                            dating.map((banner, i)=>(
                                <p className="banner" key={i}><a href={banner.url} target={"_blank"} rel="noreferrer" title={banner.name}><img src={banner.url} alt={banner.name} /></a></p>
                            ))
                        }

                    </div>
                    <div className={classNames("content", {on: tabIndex === 2})}>
                        <p className='empty folder'><FontAwesomeIcon icon={faFolderOpen} /></p>
                    </div>
                    <div className={classNames("content", {on: tabIndex === 3})}>
                    {
                            swingers.map((banner, i)=>(
                                <p className="banner" key={i}><a href={banner.url} target={"_blank"} rel="noreferrer" title={banner.name}><img src={banner.url} alt={banner.name} /></a></p>
                            ))
                        }
                    </div>
                    <div className={classNames("content", {on: tabIndex === 4})}>
                        <p className='empty folder'><FontAwesomeIcon icon={faFolderOpen} /></p>
                    </div>
                    <div className={classNames("content", {on: tabIndex === 5})}>
                        <p className='empty folder'><FontAwesomeIcon icon={faFolderOpen} /></p>
                    </div>
                </div>
            </div>


            </div>
        </LoggedInLayout>
    );
}

export default Resources;
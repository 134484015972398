import privateFetch from "services/privateFetch";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import useAuthProvider from "./useAuthProvider";

const useFetchProvider = (headers={}) => {
    const authProvider = useAuthProvider();

    privateFetch.headers = { ...privateFetch.headers, ...headers};
    privateFetch.interceptors.request.use(
        config => {
            config.headers.Authorization = `Bearer ${authProvider.getBearerToken()}`;
            return config;
        },
        // error => {
        //     console.log("session expired, please login again:", error);
        // }
    );

    createAuthRefreshInterceptor(
        privateFetch,
        authProvider.getNewAccessToken,
    );

    return (
        privateFetch
    );
};

export default useFetchProvider;
// import logo from './logo.svg';
// import './App.css';
import MainNav from "navigations/mainNav"
// import AffiliateQuarter from './pages/AffiliateQuarter';
// import Login from './pages/Login';
// import ForgotPassword from './pages/ForgotPassword';
// import AffiliateReg from './pages/AffiliateReg';
// import Dashboard from 'pages/AffiliateDashboard';
import 'react-responsive-modal/styles.css';
import './assets/scss/master.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import ErrPopup from 'components/modals/ConfirmPopup';
import {  useRecoilValue, useResetRecoilState } from "recoil";
import errMsgAtom from "recoil/common/errMsg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from "@fortawesome/free-solid-svg-icons";
// import {
//   BrowserRouter as Router,
//   Switch,
//   Route,
//   //Link
// } from "react-router-dom";


function App() {
  const errMsg = useRecoilValue(errMsgAtom);
  const resetErrMsg = useResetRecoilState(errMsgAtom)
  const {msg, isShow} = errMsg;

  const closeErrMsg = ()=>{
    resetErrMsg();
  } 
  
  return (
    <div className="App">
      <MainNav></MainNav>
      {
        isShow && <ErrPopup 
          open={isShow} 
          close={closeErrMsg}
          confirm={closeErrMsg}
          btnText="OK">
          <p style={{display:"block", textAlign: "center"}}><FontAwesomeIcon style={{fontSize: "80px",color:"#E5183F"}} icon={faTimes} /></p>
          <p style={{textAlign: "center"}}>{msg}</p>
      </ErrPopup>
      }
      
      {/* <Router>
      <Switch>
        <Route path="/affiliate">
          <AffiliateQuarter />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/forgetpassword">
          <ForgotPassword />
        </Route>
        <Route path="/reg">
          <AffiliateReg />
        </Route>
        
      </Switch>
      </Router> */}
      
    </div>
  );
}

export default App;

import React, { useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import LoggedInLayout from 'layouts/LoggedInLayout';
import useLeftMenu from 'hooks/useLeftMenu';
import {PAGE_NAV} from "configs/page";
import { MENUS } from 'configs/page';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight  } from "@fortawesome/free-solid-svg-icons";
import ContactSupportPopup from "components/modals/ContactSupportPopup";
import TicketListPopup from 'components/modals/TicketListPopup';
import { BrowserView, MobileView } from 'react-device-detect';
import useToast from "hooks/useToast";

const Support = (props) => {
    const [leftMenu, setLeftMenu] = useLeftMenu();
    const toast = useToast();

    useEffect(()=>{
        if(leftMenu.value !== "SUPPORT")
            setLeftMenu(MENUS.find(menu=>menu.value==="SUPPORT"));
    }, [leftMenu, setLeftMenu]);

    const [showContactSupportPopup, setShowContactSupportPopup] = useState(false);
    const [showTicketListPopup, setShowTicketListPopup] = useState(false);

    const postCreateNewTicketPopup = ()=>{
        setShowContactSupportPopup(false);
        toast.success("Succesfully create new ticket");
    }

    return (
        <LoggedInLayout {...props}>
            <div className="card op">
                <BrowserView>
                <ul className="op-list">
                    <li><a href="/" onClick={(event)=>{event.preventDefault(); setShowContactSupportPopup(true)}} title="Click to contact support"><span>Contact support</span> <FontAwesomeIcon icon={faChevronRight} /></a></li>
                    <li><a href="/" onClick={(event)=>{event.preventDefault(); setShowTicketListPopup(true)}} title="Click to open up ticket list"><span>Ticket list</span> <FontAwesomeIcon icon={faChevronRight} /></a></li>
                    <li><a href="https://help.redhotpie.com/" target={"_blank"} rel="noreferrer" title="Click to go to RHP FAQ"><span>FAQ</span> <FontAwesomeIcon icon={faChevronRight} /></a></li>
                </ul>
                </BrowserView>
                <MobileView>
                <ul className="op-list">
                    <li><Link to={PAGE_NAV.CONTACTSUPPORT} title="Click to contact support"><span>Contact support</span> <FontAwesomeIcon icon={faChevronRight} /></Link></li>
                    <li><Link to={PAGE_NAV.TICKETLIST} title="Click to go to ticket list"><span>Ticket list</span> <FontAwesomeIcon icon={faChevronRight} /></Link></li>
                    <li><a href="https://help.redhotpie.com/" target={"_blank"} rel="noreferrer" title="Click to go to RHP FAQ"><span>FAQ</span> <FontAwesomeIcon icon={faChevronRight} /></a></li>
                </ul>
                </MobileView>
            </div>
            <ContactSupportPopup open={showContactSupportPopup} close={()=>setShowContactSupportPopup(false)} postProcFunc={postCreateNewTicketPopup} />
            <TicketListPopup open={showTicketListPopup} close={()=>setShowTicketListPopup(false)} />
        </LoggedInLayout>
    );
}

export default Support;